export default {
  catalogueItems: null,
  catalogues: [],
  catalogueDisplayView: "grid",
  catalogueItemsListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 15,
      totalPages: "",
      totalRecords: "",
      search: "",
      catalogues: "",
    },
    advancedFilters: {
      customFields: [],
    },
  },
  adminCatalogueItems: null,
  adminCatalogueItemsListFilters: {
    paginationParams: {
      page: 1,
      pageSize: 15,
      totalPages: "",
      totalRecords: "",
      all: true,
      search: "",
      catalogues: "",
    },
    advancedFilters: {
      customFields: [],
    },
  },
};
