<template>
  <Condition :condition-text="approvalWorkflowTitle">
    <div class="!my-4 flex justify-between items-start">
      <p class="text-gray-400 pr-8 description">
        {{ $t("approvalWorkflow.globalPolicies.responsibleUser.description") }}
      </p>

      <SwitchCheckbox
        v-model="isActive"
        :label="$t('approvalWorkflow.globalPolicies.active')"
        class="checkbox"
        :read-only="isUserOnlyAuditor"
      />
    </div>
    <div class="flex justify-end">
      <div class="flex justify-end mt-4">
        <Button
          :on-click-handler="onSave"
          :loading="loading"
          :disabled="isUserOnlyAuditor"
        >
          {{ $t("approvalWorkflow.globalPolicies.saveBtn") }}
        </Button>
      </div>
    </div>
  </Condition>
</template>

<script>
import { globalPolicyMixin } from "@/components/mixins/index.js";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";

export default {
  name: "ResponsibleUser",
  components: {
    SwitchCheckbox,
  },
  mixins: [globalPolicyMixin],
  data() {
    return {
      loading: false,
      isActive: this.approvalWorkflow.isActive,
    };
  },
  methods: {
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        this.updateGlobalPolicy({
          conditionId: this.approvalWorkflow.id,
          payload: { isActive: this.isActive },
        })
          .then(() =>
            this.showNotification(
              this.$t("approvalWorkflow.globalPolicies.updateSuccessText")
            )
          )
          .catch((error) => this.showErrorMessage(error))
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style scoped>
.description {
  white-space: pre-line;
}
</style>
