import { isObjectEmpty } from "@/utils/utility_methods";
import { deleteObject, get, patch, post } from "@/utils/services/api";
import { urlWithParams } from "@/utils/helpers/apis/requiredParams";
import {
  CATALOGUE_ITEMS_URL,
  CATALOGUES_API_URL,
} from "@/utils/services/apiUrls";
import { updatePaginationParams } from "@/utils/helpers/paginationHelper";

import * as CatalogueTypes from "@/store/Catalogue/types";
import store from "@/store";
import { CATALOGUE_MODULE } from "@/store/Catalogue/types";

export const getCatalogueItems = (
  commit,
  resolve,
  reject,
  catalogueId,
  paginationParams = {}
) => {
  let queryParams = {};
  let areFiltersUpdated = true;

  if (!isObjectEmpty(paginationParams)) {
    queryParams = paginationParams.queryParams;
    areFiltersUpdated = paginationParams.areFiltersUpdated;
  }

  get(urlWithParams(CATALOGUE_ITEMS_URL, null, queryParams))
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE_ITEMS, {
        payload: response.data?.results || response.data,
        areFiltersUpdated,
      });
      updatePaginationParams("catalogueItemsListFilters", response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getAdminCatalogueItems = (
  commit,
  resolve,
  reject,
  paginationParams = {}
) => {
  let queryParams = {};
  let areFiltersUpdated = true;

  if (!isObjectEmpty(paginationParams)) {
    queryParams = paginationParams.queryParams;
    areFiltersUpdated = paginationParams.areFiltersUpdated;
  }

  get(urlWithParams(CATALOGUE_ITEMS_URL, null, queryParams))
    .then((response) => {
      commit(CatalogueTypes.SET_ADMIN_CATALOGUE_ITEMS, {
        payload: response.data.results,
        areFiltersUpdated,
      });
      updatePaginationParams("adminCatalogueItemsListFilters", response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const getCatalogueItem = (
  commit,
  resolve,
  reject,
  itemId,
  triggerApi
) => {
  const companySupplierGetter =
    store.getters[`${CATALOGUE_MODULE}/getCatalogueItem`];
  const response = companySupplierGetter(itemId);

  if (response && !triggerApi) {
    return resolve(response);
  }

  get(`${CATALOGUE_ITEMS_URL}${itemId}/`)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE_ITEM, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

const addCatalogueItem = (commit, resolve, reject, payload) => {
  post(CATALOGUE_ITEMS_URL, payload)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE_ITEM, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

const updateCatalogueItem = (
  commit,
  resolve,
  reject,
  catalogueItemId,
  payload
) => {
  if (!catalogueItemId) return;

  patch(`${CATALOGUE_ITEMS_URL}${catalogueItemId}/`, payload)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE_ITEM, response.data);
      resolve(response.data);
    })
    .catch((err) => reject(err));
};

export const saveCatalogueItem = (commit, resolve, reject, payload) => {
  if (payload.id)
    updateCatalogueItem(commit, resolve, reject, payload.id, payload);
  else addCatalogueItem(commit, resolve, reject, payload);
};

export const deletedCatalogueItem = (
  commit,
  resolve,
  reject,
  catalogueItemId
) => {
  if (!catalogueItemId) return;

  deleteObject(`${CATALOGUE_ITEMS_URL}${catalogueItemId}/`)
    .then(() => {
      commit(CatalogueTypes.REMOVE_CATALOGUE_ITEM, catalogueItemId);
      resolve();
    })
    .catch((err) => reject(err));
};

export const getCatalogues = (commit, resolve, reject) => {
  get(CATALOGUES_API_URL)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUES, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const createCatalogue = (commit, payload, resolve, reject) => {
  post(CATALOGUES_API_URL, payload)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const updateCatalogue = (
  commit,
  resolve,
  reject,
  catalogueId,
  payload
) => {
  if (!catalogueId) return;

  patch(`${CATALOGUES_API_URL}${catalogueId}/`, payload)
    .then((response) => {
      commit(CatalogueTypes.SET_CATALOGUE, response.data);
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
};

export const deleteCatalogue = (commit, resolve, reject, catalogueId) => {
  if (!catalogueId) return;

  deleteObject(`${CATALOGUES_API_URL}${catalogueId}/`)
    .then(() => {
      commit(CatalogueTypes.REMOVE_CATALOGUE, catalogueId);
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
};
