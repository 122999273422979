<template>
  <div v-if="isEdit">
    <div class="flex items-center my-2">
      <label class="text-sm font-medium text-gray-500 mr-2">{{
        labelText
      }}</label>
      <HivebuyTooltip v-if="addDefault" :open-delay="50">
        <QuestionMarkCircleIcon class="w-4 h-4 text-primary" />
        <template #content>
          <div class="p-2">
            <i18n-t
              keypath="legalTendency.legalTendencyModal.tooltipDefault"
              tag="span"
              class="text-base"
            >
              <template #icon>
                <span class="inline-block">
                  <DefaultIcon class="w-4 h-4 text-primary" />
                </span>
              </template>
              <template #object>
                {{ labelText }}
              </template>
            </i18n-t>
          </div>
        </template>
      </HivebuyTooltip>
    </div>
    <Multiselect
      v-model="selectorValue"
      class="w-full bg-white"
      :mode="mode"
      :track-by="['name', 'street', 'city']"
      :value-prop="'id'"
      :object="objectType"
      :options="optionsList"
      :placeholder="$t('global.dropdown.pleaseSelect')"
      :can-clear="canClear"
      :searchable="true"
      :can-deselect="canDeselect"
    >
      <template #tag="{ option, handleTagRemove, disabled }">
        <div
          :class="[
            'multiselect-tag tag-section',
            { 'is-disabled': disabled },
            option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
          ]"
        >
          <span v-if="addDefault" class="multiselect-tag-remove">
            <UndefaultIcon
              v-if="option.isDefault"
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
            <DefaultIcon
              v-else
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
          </span>
          {{ option.name || company.name }}
          <span
            v-if="!disabled"
            class="multiselect-tag-remove"
            @mousedown.prevent="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon" />
          </span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="gap-x-4 w-4/5 grid grid-cols-2">
          <div class="text-base font-medium">
            {{ option.name || company.name }}
            <p v-if="option.isDefault" class="text-xs font-medium">(Default)</p>
          </div>
          <div class="text-xs">
            <p>{{ option.street }} {{ option.houseNumber }}</p>
            <p>{{ option.addressSupplement }}</p>
            <p>{{ option.city }}</p>
            <p>{{ option.country }}</p>
          </div>
        </div>
      </template>
      <template #singlelabel="{ value }">
        <div class="multiselect-single-label font-normal text-sm truncate">
          {{ value.street }} - {{ value.city }}
        </div>
      </template>
    </Multiselect>
    <FieldError v-if="isInvalid" :text="errorMessage" />
  </div>
  <div v-else-if="isAddress" class="section">
    <dt v-if="showTitle" class="section-title">
      {{ labelText }}
    </dt>
    <dd v-if="list?.length > 1" class="flex ml-1">
      <p class="mr-1">
        {{
          isInvoiceAddress
            ? $t("tooltip.squashedText.invoiceAddress", { count: list.length })
            : $t("tooltip.squashedText.deliveryAddress", { count: list.length })
        }}
      </p>
      <Tip help theme="tooltip">
        <template #header>
          <div class="flex items-center truncate">
            {{ labelText }}
          </div>
        </template>
        <template #content>
          <div class="grid grid-cols-2 divide-x-2 gap-x-4">
            <p
              v-for="(address, index) in addressDetails"
              :key="index"
              class="tooltip-subtext px-4 py-2"
            >
              <span class="block text-primary font-semibold">
                {{ address.name }}
              </span>
              <span class="block">
                {{ address.street }}
                {{ address.houseNumber }}
              </span>
              <span class="block">
                {{ address.postalCode }}
                {{ address.city }}
              </span>
              <span class="block">
                {{ address.country }}
              </span>
            </p>
          </div>
        </template>
      </Tip>
    </dd>
    <dd
      v-if="addressDetails"
      class="text-gray-500"
      :class="small ? 'text-xs' : 'text-sm'"
    >
      <span class="block">
        {{ addressDetails.name }}
      </span>
      <span class="block">
        {{ addressDetails.street }}
        {{ addressDetails.houseNumber }}
      </span>
      <span class="block">
        {{ addressDetails.postalCode }}
        {{ addressDetails.city }}
      </span>
      <span class="block">
        {{ addressDetails.country }}
      </span>
    </dd>
    <dd v-else>-</dd>
  </div>
</template>

<script>
import {
  COMPANY_MANAGEMENT_MODULE,
  FETCH_COMPANY_ADDRESS,
} from "@/store/CompanyManagement/types";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import FieldError from "@/components/shared/FieldError.vue";
import {
  BookmarkIcon as UndefaultIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
import { BookmarkIcon as DefaultIcon } from "@heroicons/vue/outline";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import { deepClone } from "@/utils/utility_methods";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";

export default {
  components: {
    Multiselect,
    FieldError,
    DefaultIcon,
    UndefaultIcon,
    HivebuyTooltip,
    QuestionMarkCircleIcon,
  },
  props: {
    modelValue: {
      type: [Object, String],
      default: "",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    addressId: {
      type: String,
      default: "",
    },
    objectType: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: true,
    },
    isInvoiceAddress: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "",
    },
    requestedForId: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    addDefault: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company", "companyAddresses"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "getCompanyAddressObject",
      "addressList",
    ]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),
    selectorValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    optionsList() {
      let list = [];
      const defaultValuesList = this.isInvoiceAddress
        ? this.requestedForValues[this.requestedForId]?.defaultValues
            ?.invoiceAddresses
        : this.requestedForValues[this.requestedForId]?.defaultValues
            ?.deliveryAddresses;

      if (defaultValuesList?.length) {
        list = this.addressList.filter((address) =>
          defaultValuesList.includes(address.id)
        );
      } else {
        list = this.addDefault
          ? this.addressList.map((item) => {
              return { ...item, isDefault: false };
            })
          : this.addressList;
      }

      return deepClone(list);
    },
    isAddress() {
      return this.addressList?.length;
    },
    addressDetails() {
      if (this.list.length) {
        const idKey = this.isInvoiceAddress
          ? "invoiceAddress"
          : "deliveryAddress";
        const lisDetails = this.list
          .map((address) => this.getCompanyAddressObject(address[idKey]))
          .filter((list) => list);
        if (lisDetails.length < 1) return lisDetails[0] || {};

        return lisDetails;
      }

      const address = this.getCompanyAddressObject(this.addressId);
      if (this.addressId && !address) this.fetchAddress(this.addressId);
      return address;
    },
    errorMessage() {
      if (this.errorText) return this.errorText;
      return this.$t(
        "catalogue.myCart.purchaseRequestInfoModal.deliveryAddressError"
      );
    },
    labelText() {
      if (this.label) return this.label;
      if (this.isInvoiceAddress) {
        return this.$tc("global.invoiceAddress", this.list?.length);
      }
      return this.$tc("global.deliveryAddress", this.list?.length);
    },
  },
  watch: {
    requestedForValues: {
      deep: true,
      handler(newVal) {
        if (newVal[this.requestedForId]) {
          this.setAddress(newVal[this.requestedForId].defaultValues);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchAddress: FETCH_COMPANY_ADDRESS,
    }),
    setAddress(data) {
      const key = this.isInvoiceAddress ? "invoiceAddress" : "deliveryAddress";
      this.selectorValue = this.objectType
        ? this.getCompanyAddressObject(data[key])
        : data[key];
    },
    onMarkAsDefault(option) {
      this.selectorValue.forEach((item) => {
        if (item.id === option.id) {
          item.isDefault = !item.isDefault;
          const message = item.isDefault
            ? `${item.name} ${this.$t("home.markAsDefault")}`
            : `${item.name} ${this.$t("home.markAsUnDefault")}`;

          this.showNotification(message);
        } else {
          item.isDefault = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.section-title {
  @apply text-base mb-1;
}

.custom-card {
  @apply p-0;
}

.section {
  @apply px-3 flex-1 last:border-r-0;
}

.multiselect-tag.tag-section i:before {
  @apply text-white rounded-full;
}
</style>
