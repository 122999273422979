<template>
  <div>
    <HTable
      v-model="selectedOrder"
      :columns="visibleColumns"
      :data-function="fetchOrderList"
      :show-actions="false"
      :model-data="tableModelData"
      :loading="loading"
    >
      <template #status="slotProps">
        <StatusTag
          :status="slotProps.data.status"
          type="supplierOrders"
          size="small"
        />
      </template>
      <template #sentAt="slotProps">
        <TimeDisplay :time="slotProps.data.sentAt" :show-time-ago="false" />
      </template>
      <template #type="slotProps">
        <OrderTypeBadge :type="translateType(slotProps.data.sentTo)" />
      </template>
      <template #supplier="slotProps">
        <div
          class="relative group flex cursor-pointer hover:text-primary"
          @click="setQuickView(slotProps.data)"
        >
          {{ slotProps.data.companySupplier.name }}
          <div class="absolute right-0 hidden group-hover:flex">
            <ListButton
              :on-click-handler="() => setQuickView(slotProps.data)"
              size="6"
            >
              <template #icon>
                <EyeIcon class="h-4 w-4 group-hover:text-primary" />
              </template>
            </ListButton>
          </div>
        </div>
      </template>
      <template #purchaseOrderNumber="slotProps">
        <span v-if="slotProps.data.purchaseOrder.clientExternalId">
          {{ slotProps.data.purchaseOrder.clientExternalId }} /
        </span>
        {{ slotProps.data.purchaseOrder.externalId }}
      </template>
      <template #actions="slotProps">
        <div class="flex items-center">
          <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton
                class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600"
              >
                <span class="sr-only">Open options</span>
                <DotsHorizontalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="pb-1">
                  <div
                    class="flex items-center justify-center px-2 pt-2 pb-2 text-sm font-bold bg-primary text-white rounded-t"
                  >
                    {{ slotProps.data.purchaseOrder.externalId }}
                  </div>
                  <MenuItem v-slot="{ active }">
                    <a
                      class="cursor-pointer flex"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                        'block px-4 py-2 text-sm',
                      ]"
                      @click="() => setQuickView(slotProps.data)"
                    >
                      <EyeIcon class="h-5 w-5 mr-2" aria-hidden="true" />
                      {{ $t("global.details") }}
                    </a>
                  </MenuItem>
                  <MenuItem
                    v-if="checkEmail(slotProps.data.sentTo)"
                    v-slot="{ active }"
                  >
                    <a
                      class="cursor-pointer flex"
                      :class="[
                        active ? 'bg-gray-100 text-gray-700' : 'text-gray-500',
                        'block px-4 py-2 text-sm',
                      ]"
                      @click="() => resendOrder(slotProps.data)"
                    >
                      <PhoneOutgoingIcon
                        class="h-5 w-5 mr-2"
                        aria-hidden="true"
                      />
                      {{ $t("supplierOrderList.resend") }}
                    </a>
                  </MenuItem>
                  <MenuItem v-if="false" v-slot="{ active }">
                    <a
                      class="cursor-pointer flex"
                      :class="[
                        active
                          ? 'bg-gray-100 text-hivebuy-red'
                          : 'text-gray-500',
                        'block px-4 py-2 text-sm',
                      ]"
                      @click="() => cancelOrder(slotProps.data)"
                    >
                      <XCircleIcon class="h-5 w-5 mr-2" aria-hidden="true" />
                      {{
                        $t("purchaseRequest.summary.dangerZone.headingCancel")
                      }}
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </template>
    </HTable>
  </div>
  <QuickView
    :show-modal="showQuickView"
    :close-modal="closeQuickView"
    :order="quickViewOrder"
  />
  <ConfirmDialog
    :text="$t('supplierOrderList.resendConfirmText')"
    :show-modal="showConfirmModal"
    :close-modal="onConfirmModalClose"
    :item-to-confirm="confirmOrderTitle"
    :title="$t('supplierOrderList.resendConfirmTitle')"
    :confirm-modal="onConfirm"
    :info="confirmType == 'resend'"
  />
</template>
<script>
import { HTable } from "design-buy";
import { ConfirmDialog } from "@/components/shared/index";
import { mapActions, mapState, mapMutations } from "vuex";
import TableFilterMixin from "@/components/mixins/tableFilterMixin.js";
import StatusTag from "@/components/shared/StatusTag";
import QuickView from "@/components/OrderList/QuickView";
import OrderTypeBadge from "@/components/OrderList/OrderTypeBadge";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ListButton } from "@/components/shared/index";
import { checkEmail } from "@/utils/utility_methods";
import {
  GET_SUPPLIER_ORDERS,
  PURCHASE_ORDER_MODULE,
  SET_SUPPLIER_LIST_PARAMS,
  RESEND_SUPPLIER_ORDER,
} from "@/store/PurchaseOrder/types";
import { TimeDisplay } from "@/components/shared/index";
import {
  EyeIcon,
  DotsHorizontalIcon,
  XCircleIcon,
  PhoneOutgoingIcon,
} from "@heroicons/vue/solid";
export default {
  name: "List",
  components: {
    HTable,
    TimeDisplay,
    StatusTag,
    QuickView,
    OrderTypeBadge,
    ListButton,
    EyeIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsHorizontalIcon,
    XCircleIcon,
    PhoneOutgoingIcon,
    ConfirmDialog,
  },
  mixins: [TableFilterMixin],
  data() {
    return {
      selectedOrder: null,
      quickViewOrder: null,
      showQuickView: false,
      loading: false,
      showConfirmModal: false,
      confirmOrder: null,
      confirmType: null,
      refreshData: false,
    };
  },

  computed: {
    ...mapState(PURCHASE_ORDER_MODULE, [
      "supplierOrders",
      "supplierListParams",
    ]),
    confirmOrderTitle() {
      if (this.confirmOrder?.purchaseOrder) {
        return this.confirmOrder.purchaseOrder.externalId;
      }
      return null;
    },
    visibleColumns() {
      const cols = [
        {
          field: "supplier",
          header: this.$t("global.supplier"),
          slot: "supplier",
        },
        {
          field: "purchaseOrderNumber",
          header: this.$t("global.purchaseOrder"),
          slot: "purchaseOrderNumber",
        },
        {
          field: "status",
          header: this.$t("global.status"),
          slot: "status",
        },
        {
          field: "sentAt",
          header: this.$t("global.dates.sentAt"),
          slot: "sentAt",
        },
        {
          field: "type",
          header: this.$t("global.type"),
          slot: "type",
        },
      ];

      if (!this.isUserOnlyAuditor)
        cols.push({
          field: "actions",
          slot: "actions",
          visible: true,
        });
      return cols;
    },
    tableModelData() {
      return {
        data: this.supplierOrders,
        lazyParams: this.supplierListParams,
        mutationCallback: this.setSupplierListParams,
      };
    },
  },
  methods: {
    ...mapActions(PURCHASE_ORDER_MODULE, {
      getSupplierOrders: GET_SUPPLIER_ORDERS,
      resendSupplierOrder: RESEND_SUPPLIER_ORDER,
    }),
    ...mapMutations(PURCHASE_ORDER_MODULE, {
      setSupplierListParams: SET_SUPPLIER_LIST_PARAMS,
    }),
    checkEmail,

    fetchOrderList(params) {
      return this.getSupplierOrders(this.requestQueryParams(params, "so"));
    },
    translateType(order) {
      if (this.checkEmail(order)) {
        return "email";
      }
      return "integrated";
    },
    setQuickView(order) {
      this.quickViewOrder = order;
      this.showQuickView = true;
    },
    closeQuickView() {
      this.showQuickView = false;
    },
    resendOrder(order) {
      this.confirmOrder = order;
      this.confirmType = "resend";
      this.showConfirmModal = true;
    },
    cancelOrder(order) {
      this.confirmOrder = order;
      this.confirmType = "cancel";
      this.showConfirmModal = true;
    },
    onConfirm() {
      if (this.confirmType === "resend") {
        this.loading = true;
        this.resendSupplierOrder(this.confirmOrder.id)
          .then(() => {
            this.showNotification(
              this.$t("supplierOrderList.resendSuccessful", {
                order: this.confirmOrder.purchaseOrder.externalId,
              }),
              "success"
            );
          })
          .finally(() => {
            this.confirmOrder = null;
            this.loading = false;
          });
      }
      this.onConfirmModalClose();
    },
    onConfirmModalClose() {
      this.showConfirmModal = false;
      setTimeout(() => {
        this.confirmOrder = null;
        this.confirmType = null;
        this.refreshData = false;
      }, 500);
    },
  },
};
</script>
<style scoped>
.action-buttons {
  @apply group-hover:flex right-0;
}

:deep(.p-datatable-wrapper) {
  overflow: visible !important;
}

:deep(.p-datatable .p-datatable-tbody > tr:focus) {
  @apply outline-none;
}

:deep(.p-datatable.p-datatable-md .p-datatable-tbody > tr > td) {
  @apply px-4;
}

:deep(.p-datatable.p-datatable-md .p-datatable-thead > tr > th) {
  @apply px-4;
}
</style>
