<template>
  <div class="custom-card no-shadow">
    <div class="custom-card-header">
      <div class="card-title">
        {{ $t("contracts.contractForm.generalDetails") }}
      </div>
    </div>

    <div class="card-content">
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.contractType") }}
            </label>
          </div>
          <div class="ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>{{
                $t("contracts.contractForm.contractType")
              }}</template>
              <template #content>
                <div class="grid grid-cols-5 items-center !pt-1 p-4 gap-y-2">
                  <p
                    class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                  >
                    {{ $t("contracts.contractForm.tooltip.unit") }}
                  </p>
                  <p
                    class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                  >
                    {{ $t("contracts.contractForm.tooltip.unitText") }}
                  </p>
                  <p
                    class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                  >
                    {{ $t("contracts.contractForm.tooltip.supplier") }}
                  </p>
                  <p
                    class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                  >
                    {{ $t("contracts.contractForm.tooltip.supplierText") }}
                  </p>
                  <p
                    class="col-span-1 inline-flex text-sm font-medium text-gray-900"
                  >
                    {{ $t("contracts.contractForm.tooltip.other") }}
                  </p>
                  <p
                    class="col-span-4 inline-flex text-sm text-gray-500 whitespace-normal"
                  >
                    {{ $t("contracts.contractForm.tooltip.otherText") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <Multiselect
          v-model="generalDetails.contractType"
          :mode="'single'"
          :searchable="true"
          :options="contractTypeOptions"
          :can-clear="false"
          :class="{
            'border-red-400': v.contract.generalDetails.contractType.$error,
          }"
          :placeholder="$t('contracts.contractForm.contractTypePlaceholder')"
        />
        <FieldError
          v-if="v.contract.generalDetails.contractType.$error"
          :text="$t('contracts.contractForm.contractTypeError')"
        />
      </div>

      <div class="cell">
        <label class="label">
          {{ $t("contracts.contractForm.status") }}
        </label>

        <Multiselect
          v-model="generalDetails.status"
          :searchable="true"
          :options="statusOptions"
          :can-clear="false"
          :class="{ 'border-red-400': v.contract.generalDetails.status.$error }"
          :placeholder="$t('contracts.contractForm.statusPlaceholder')"
        />
      </div>
      <div class="cell">
        <Input
          :id="'name'"
          :value="generalDetails.name"
          :error-text="errorMessage(v.contract.generalDetails.name)"
          :name="'partner'"
          :on-value-change="
            ($event) => (generalDetails.name = $event.target.value)
          "
          :placeholder="$t('contracts.contractForm.name')"
          :label="$t('contracts.contractForm.name')"
        />
      </div>
      <div class="cell">
        <label class="label">
          {{ $t("contracts.contractForm.owner") }}
        </label>
        <MemberSelector
          :value="generalDetails.owner"
          :on-value-change="(value) => (generalDetails.owner = value)"
          :members-list="usersList()"
          :placeholder="$t('contracts.contractForm.ownerPlaceholder')"
          :error="v.contract.generalDetails.owner.$error"
        />
        <FieldError
          v-if="v.contract.generalDetails.owner.$error"
          :text="$t('contracts.contractForm.ownerError')"
        />
      </div>
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.partner") }}
            </label>
          </div>

          <div class="left-content ml-2 flex items-center">
            <Tip help theme="tooltip">
              <template #header>{{
                $t("contracts.contractForm.partner")
              }}</template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.partner") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <Input
          :id="'partner'"
          :value="generalDetails.partner"
          :error-text="errorMessage(v.contract.generalDetails?.partner)"
          :name="'partner'"
          :on-value-change="
            ($event) => (generalDetails.partner = $event.target.value)
          "
          :placeholder="$t('contracts.contractForm.partner')"
        />
      </div>
      <div class="cell">
        <Input
          :id="'partnerEmail'"
          :value="generalDetails.partnerEmail"
          :error-text="errorMessage(v.contract.generalDetails.partnerEmail)"
          :name="'partnerEmail'"
          :on-value-change="
            ($event) => (generalDetails.partnerEmail = $event.target.value)
          "
          :type="'email'"
          :placeholder="$t('contracts.contractForm.partnerEmail')"
          :label="$t('contracts.contractForm.partnerEmail')"
        />
      </div>
      <div class="cell">
        <Input
          :id="'contractNumber'"
          :value="generalDetails.contractNumber"
          :name="'contractNumber'"
          :on-value-change="
            ($event) => (generalDetails.contractNumber = $event.target.value)
          "
          :placeholder="$t('contracts.contractForm.contractNumberPlaceholder')"
          :label="$t('contracts.contractForm.contractNumber')"
        />
      </div>
      <div class="cell">
        <label class="label">
          {{ $t("contracts.contractForm.category") }}
        </label>
        <CategorySelect v-model="generalDetails.category" />
      </div>
      <div class="cell">
        <div class="flex items-center">
          <div>
            <label class="label">
              {{ $t("contracts.contractForm.companySupplier") }}
            </label>
          </div>
          <div class="flex items-center left-content ml-2">
            <Tip help theme="tooltip">
              <template #header>{{
                $t("contracts.contractForm.companySupplier")
              }}</template>
              <template #content>
                <div class="max-w-md">
                  <p class="tooltip-subtext">
                    {{ $t("contracts.contractForm.tooltip.companySupplier") }}
                  </p>
                </div>
              </template>
            </Tip>
          </div>
        </div>
        <SupplierSelect
          v-model="generalDetails.companySupplier"
          :can-clear="false"
          :label="'name'"
          :placeholder="$t('contracts.contractForm.companySupplierPlaceholder')"
          :disabled="isSupplierFieldDisabled"
          :category-to-filter="generalDetails.category"
        />
      </div>
      <div class="cell">
        <label class="label">
          {{ $t("contracts.contractForm.file") }}
        </label>
        <FileInput
          :files="generalDetails.files"
          small
          @list-update="onFileUpload"
        />
      </div>
      <div class="cell col-span-2">
        <TextArea
          :name="'description'"
          :placeholder="
            $t('catalogue.catalogueModal.fields.descriptionPlaceholder')
          "
          :value="generalDetails.description"
          :rows="3"
          :on-value-change="onAttributeChange"
          :label="$t('catalogue.catalogueModal.fields.description')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  FieldError,
  Input,
  TextArea,
  MemberSelector,
  UploadArea as FileInput,
  CategorySelect,
  SupplierSelect,
} from "@/components/shared";
import Multiselect from "@vueform/multiselect";
import { CONTRACT_STATUS_TYPES, CONTRACT_TYPES } from "@/utils/constants";
import { removeKeysFromObject, toBase64 } from "@/utils/utility_methods";
import { contractMixin, errorMixin } from "@/components/mixins";

export default {
  components: {
    SupplierSelect,
    MemberSelector,
    Input,
    TextArea,
    Multiselect,
    FieldError,
    FileInput,
    CategorySelect,
  },
  mixins: [errorMixin, contractMixin],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["departmentsList", "usersList"]),
    contractTypeOptions() {
      return Object.values(CONTRACT_TYPES).map((contractType) => {
        return { label: this.contractTypes(contractType), value: contractType };
      });
    },
    generalDetails: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    statusOptions() {
      const statuses = removeKeysFromObject(CONTRACT_STATUS_TYPES, [
        "approved",
        "rejected",
      ]);
      return Object.values(statuses).map((status) => {
        return { value: status, label: this.contractStatusTypes(status) };
      });
    },
    isSupplierFieldDisabled() {
      return this.generalDetails.contractType === CONTRACT_TYPES.Supplier;
    },
  },
  methods: {
    onAttributeChange(event) {
      const { name, value } = event.target;
      this.generalDetails[name] = value;
    },

    async onFileUpload(filesList) {
      this.generalDetails.files = await Promise.all(
        filesList.map(async (uploadedFile) => {
          const file = await toBase64(uploadedFile);
          return { title: uploadedFile.name, file: file };
        })
      );
    },
    errorMessage(validationField) {
      return validationField ? this.error(validationField) : "";
    },
  },
};
</script>

<style scoped>
.page-content .left-content .card-content {
  @apply w-full grid gap-4 grid-cols-2;
}
</style>
