<template>
  <div id="invoices" class="invoices">
    <HTable
      v-model="selectedInvoices"
      :columns="visibleColumns"
      :data-function="fetchInvoices"
      :select-actions="selectActions"
      :show-actions="false"
      :model-data="tableModelData"
    >
      <template
        v-if="isFeatureAllowed('legalEntity')"
        #tableFilter="{ filterFunction }"
      >
        <LegalTendencySelect
          v-model="legalEntityFilter"
          :with-label="false"
          :classes="'w-60'"
          @update:model-value="(val) => filterFunction('legalEntity', val)"
        />
      </template>
      <template #tableActions>
        <Actions
          v-if="!isUserOnlyAuditor"
          model="invoice"
          :models="['csvOrders', 'accrualReport', 'sepa', 'invoices']"
          :custom-actions="selectActions"
        />
      </template>
      <template #invoiceNumber="slotProps">
        <div
          class="relative py-1 flex group cursor-pointer font-medium"
          @click="() => setInvoiceQuickView(slotProps.data)"
        >
          <div class="text-primary flex items-center gap-x-1">
            {{ slotProps.data.invoiceNumber }}
            <CashIcon
              v-if="slotProps.data.invoicePaid"
              class="h-5 w-5 text-hivebuy-green ml-1"
            />
            <Tip v-if="slotProps.data.datevJob" theme="time" :text="'DATEV'">
              <template #placeholder>
                <div
                  class="rounded text-xs px-1 py-0.5 border border-gray-100"
                  :class="[
                    datevStatus(slotProps.data.datevJob, $t).color,
                    datevStatus(slotProps.data.datevJob, $t).textColor,
                  ]"
                >
                  <span>DATEV</span>
                </div>
              </template>
              <template #content>
                {{ datevStatus(slotProps.data.datevJob, $t).text }}
              </template>
            </Tip>
          </div>
          <div class="right-0 top-0 hidden group-hover:flex absolute">
            <ListButton>
              <template #icon>
                <EyeIcon class="h-5 w-5 group-hover:text-primary" />
              </template>
            </ListButton>
          </div>
        </div>
      </template>
      <template #status="slotProps">
        <StatusTag
          type="invoice"
          :status="slotProps.data.status"
          :size="'small'"
        />
      </template>
      <template #totalNetAmount="slotProps">
        <div class="font-medium text-base">
          <CurrencyDisplay
            :value="slotProps.data.totalNetAmount"
            :locale="currencyToLocale(slotProps.data.totalNetAmountCurrency)"
          />
        </div>
      </template>
      <template #totalGrossAmount="slotProps">
        <div class="font-medium text-base">
          <CurrencyDisplay
            :value="slotProps.data.totalGrossAmount"
            :locale="currencyToLocale(slotProps.data.totalGrossAmountCurrency)"
          />
        </div>
      </template>
      <template #dueDate="slotProps">
        <div class="text-base">
          <TimeDisplay
            :time="slotProps.data.dueDate"
            :show-time-ago="false"
            :hide-tooltip="true"
          />
        </div>
      </template>
      <template #paidAt="slotProps">
        <div class="text-base">
          <TimeDisplay
            :time="slotProps.data.invoicePaidAt"
            :show-time-ago="false"
            :hide-tooltip="true"
          />
        </div>
      </template>
      <template #datevCompletedAt="slotProps">
        <div class="text-base">
          <BooleanDisplay
            :value="Boolean(slotProps.data.datevJob?.completedAt)"
          />
        </div>
      </template>
      <template #createdAt="slotProps">
        <div class="text-base">
          <TimeDisplay
            :time="slotProps.data.createdAt"
            :show-time-ago="false"
          />
        </div>
      </template>
      <template #invoiceDate="slotProps">
        <div class="text-base">
          <TimeDisplay
            :time="slotProps.data.invoiceDate"
            :show-time-ago="false"
            :hide-tooltip="true"
          />
        </div>
      </template>
      <template #serviceDate="slotProps">
        <div class="text-base">
          <TimeDisplay
            :time="slotProps.data.serviceDate"
            :show-time-ago="false"
            :hide-tooltip="true"
          />
        </div>
      </template>
      <template #supplier="slotProps">
        <div class="text-base truncate max-w-[230px] xl:max-w-[350px]">
          {{ slotProps.data.vendorName }}
        </div>
      </template>
      <template #legalEntity="slotProps">
        <LegalTendency :legal-tendency="slotProps.data.legalEntity" />
      </template>
    </HTable>
  </div>
  <SepaModal
    v-if="isFeatureAllowed('sepa') && selectedInvoices?.length"
    :show-modal="showSepaModal"
    :close-modal="toggleSepaModal"
    :objects="selectedDataList"
    model="invoice"
  />

  <ManageTableModal
    :show-modal="showManageTableModal"
    :toggle-modal="toggleTableModal"
    :columns="columnsDetails"
    :table-name="'invoiceAccountingView'"
  />
  <DatevModal
    v-if="isFeatureAllowed('datev')"
    :show-modal="showDatevModal"
    :close-modal="onDatevModalClose"
    :objects="datevInitialList"
    :source="'invoice'"
  />
  <InvoiceQuickView
    v-if="selectedInvoice"
    :show-modal="showInvoiceQuickView"
    :close-modal="onInvoiceQuickViewModalClose"
    :invoice="selectedInvoice"
  />
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import { HTable } from "design-buy";
import { BooleanDisplay } from "@/components/PurchaseOrder/PurchaseOrdersList/TableComponents/index.js";
import { datevStatus } from "@/utils/helpers/datevHelper.js";

import {
  DASHBOARD_MODULE,
  SET_SELECTED_SIDEBAR,
} from "@/store/Dashboard/types";
import { currencyToLocale, formattedDate } from "@/utils/utility_methods.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {
  INVOICE_MODULE,
  GET_INVOICES,
  SET_INVOICE_LIST_PARAMS,
} from "@/store/Invoice/types.js";

import {
  TimeDisplay,
  ListButton,
  LegalTendencySelect,
} from "@/components/shared/index";
import StatusTag from "@/components/shared/StatusTag";
import ManageTableModal from "@/components/PurchaseOrder/PurchaseOrdersList/ManageTableModal";
import {
  companySuppliers,
  supplierHelperMixin,
} from "@/components/mixins/index.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import DatevModal from "@/components/PurchaseOrder/PurchaseOrdersList/DatevModal.vue";
import CurrencyDisplay from "@/components/shared/CurrencyDisplay.vue";
import InvoiceQuickView from "@/components/PurchaseRequest/InvoiceQuickView.vue";
import { EyeIcon } from "@heroicons/vue/solid";
import { Actions } from "@/components/PurchaseOrder/PurchaseOrdersList/TableComponents/index.js";
import {
  AdjustmentsIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/vue/solid";
import { CashIcon } from "@heroicons/vue/outline";
import POListingMixin from "@/components/mixins/POListingMixin.js";
import SepaModal from "@/components/PurchaseOrder/PurchaseOrdersList/SepaModal.vue";
import "@/assets/styles/table.css";
import LegalTendency from "@/components/shared/LegalTendency.vue";

export default {
  name: "InvoiceList",
  components: {
    LegalTendencySelect,
    Actions,
    EyeIcon,
    ListButton,
    InvoiceQuickView,
    TimeDisplay,
    ManageTableModal,
    DatevModal,
    StatusTag,
    CurrencyDisplay,
    HTable,
    SepaModal,
    CashIcon,
    BooleanDisplay,
    LegalTendency,
  },
  mixins: [companySuppliers, supplierHelperMixin, POListingMixin],
  props: {
    updateInvoice: {
      type: Function,
      default: () => {},
    },
    setVisibleState: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showManageTableModal: false,
      showInvoiceQuickView: false,
      updatedInvoices: [],
      areInvoicesLoading: false,
      showCheckboxes: false,
      selectedInvoices: [],
      showDatevModal: false,
      checked: false,
      selectedInvoice: null,
      showSepaModal: false,
      legalEntityFilter: "",
    };
  },
  computed: {
    ...mapState(INVOICE_MODULE, {
      initialColumns: (state) => state.columns,
      invoiceListParams: (state) => state.invoiceListParams,
      invoicesTableList: (state) => state.invoicesTableList,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["integrations"]),
    selectedDataList() {
      return this.selectedInvoices.map((invoice) => {
        return Object.assign({}, invoice, { title: invoice.invoiceNumber });
      });
    },
    datevInitialList() {
      if (this.selectedInvoices.length) {
        const newList = this.selectedInvoices.map((invoice) => {
          return {
            id: invoice.id,
            title: invoice.invoiceNumber,
            datevJob: invoice?.datevJob,
            collective: invoice?.collective,
            status: invoice.status,
          };
        });
        return newList;
      } else return null;
    },
    selectActions() {
      const actions = [
        {
          label: this.$t("purchaseOrder.manageTable"),
          action: this.toggleTableModal,
          icon: AdjustmentsIcon,
          disabled: false,
        },
      ];
      if (this.isFeatureAllowed("sepa"))
        actions.push({
          label: this.$t("purchaseOrder.sepaModal.title"),
          labelStyle: "uppercase",
          icon: ChevronDoubleDownIcon,
          action: this.toggleSepaModal,
          disabled: !this.selectedInvoices.length,
        });
      if (this.isFeatureAllowed("datev"))
        actions.push({
          label: this.$t("purchaseOrder.datevModal.title"),
          icon: ChevronDoubleRightIcon,
          action: this.toggleDatevModal,
          disabled: !this.selectedInvoices.length,
        });

      return actions;
    },
  },
  created() {
    this.setSelectedSidebar(this.$route.name);
  },

  methods: {
    currencyToLocale,
    ...mapActions(INVOICE_MODULE, {
      getInvoices: GET_INVOICES,
    }),
    ...mapMutations(INVOICE_MODULE, {
      setInvoiceListParams: SET_INVOICE_LIST_PARAMS,
    }),
    ...mapMutations(DASHBOARD_MODULE, {
      setSelectedSidebar: SET_SELECTED_SIDEBAR,
    }),
    setInvoiceQuickView(invoice) {
      this.selectedInvoice = invoice;
      this.showInvoiceQuickView = true;
    },
    toggleSepaModal() {
      this.showSepaModal = !this.showSepaModal;
    },

    toggleTableModal() {
      this.showManageTableModal = !this.showManageTableModal;
    },

    toggleDatevModal() {
      this.showDatevModal = !this.showDatevModal;
    },

    onDatevModalClose() {
      this.showDatevModal = false;
    },

    onInvoiceQuickViewModalClose() {
      this.showInvoiceQuickView = false;
      this.selectedInvoice = null;
    },
    formattedDate,
    datevStatus,
    fetchInvoices(params) {
      return this.getInvoices({
        queryParams: {
          tableParams: this.requestQueryParams(params, "in"),
        },
      });
    },
  },
};
</script>
