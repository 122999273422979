<template>
  <div class="text-sm font-medium grid grid-cols-12 gap-2 col-span-full">
    <span class="icon-column" :class="rowIcon.class">
      <component :is="rowIcon.icon" class="h-5 w-5" />
    </span>
    <div class="text-column">
      <div class="text-gray-400 ml-4" :class="['rowText.class']">
        <div class="flex items-center">
          {{ rowText.leading }}
          <span class="text-gray-900 font-semibold ml-1" :class="rowText.class">
            <UserWithAvatar
              v-if="
                !isAutoProcessing && result?.type === 'user' && rowText.buyer
              "
              :user="rowText.buyer"
              :show-name-only="true"
            />
            <span v-else-if="!isAutoProcessing && result?.role">{{
              rowText.buyer
            }}</span>
            <div v-else><SupplierName :supplier="supplier" /></div>
          </span>
        </div>
        <div
          v-if="isAutoProcessing"
          class="text-sm md:text-xs text-gray-400 my-2 pr-6"
        >
          {{ $t("purchaseRequest.summary.timeline.autoOrder") }}
        </div>
      </div>
    </div>
    <div class="avatar-column">
      <div v-if="isAutoProcessing">
        <div class="h-8 w-8">
          <img src="@/assets/images/hivebuy_logo.svg" alt="Hivebuy" />
        </div>
      </div>
      <div v-else>
        <div
          v-for="(resultUser, index) in result?.users"
          :key="index"
          class="inline-flex"
          :class="index > 0 ? '-ml-2' : ''"
        >
          <UserWithAvatar
            :user="resultUser"
            :avatar-dimension="9"
            show-tooltip
            class="cursor-help"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ShoppingCartIcon,
  XCircleIcon,
  LightningBoltIcon,
} from "@heroicons/vue/solid";
import { UserWithAvatar, SupplierName } from "@/components/shared/index";
import { requestTypeMixin } from "@/components/mixins/index.js";

export default {
  components: {
    ShoppingCartIcon,
    UserWithAvatar,
    XCircleIcon,
    LightningBoltIcon,
    SupplierName,
  },
  mixins: [requestTypeMixin],
  props: {
    supplier: {
      type: String,
      default: "",
    },
    result: {
      type: Object,
      required: false,
      default: null,
    },
    overallStatus: {
      type: String,
      required: true,
    },
    isAutoProcessing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rowIcon() {
      if (this.overallStatus === "R") {
        return {
          icon: XCircleIcon,
          class: "bg-gray-100 text-gray-400",
        };
      }
      //   default return
      return {
        icon: ShoppingCartIcon,
        class: "bg-gray-100 text-blue-400",
      };
    },

    rowText() {
      return {
        leading: this.$t("purchaseRequest.summary.timeline.orderedBy"),
        buyer: this.typeForResult(),
        class: this.overallStatus === "R" ? "line-through" : "",
      };
    },
  },
  methods: {
    typeForResult() {
      if (this.result?.type === "role") {
        return this.roleName(this.result.role);
      } else if (this.result?.type === "user") {
        if (this.result.users[0]) {
          return this.getUser()(this.result.users[0]);
        }
      }
    },
    roleName(role) {
      return this.$t(`global.roles.${role}`);
    },
  },
};
</script>

<style>
.icon-column {
  @apply inline-flex items-center justify-center h-9 w-9 rounded-full text-sm sm:col-span-1 md:col-span-1 col-span-12 lg:col-span-1 mx-auto my-4 sm:my-0 sm:mx-0;
}
.text-column {
  @apply col-span-12 md:col-span-7 lg:col-span-7 sm:col-span-5 2xl:col-span-5 mt-2;
}
.avatar-column {
  @apply col-span-12 md:col-span-4 lg:col-span-4 sm:col-span-5 2xl:col-span-5 col-end-13;
}
</style>
