// START NEW BY STEFAN
import { WORKFLOW_ROLES_MAPPING } from "@/utils/constants";
import { isValuePresent } from "@/utils/utility_methods";
import store from "@/store";

export const relationSymbol = (relation) => {
  return {
    GT: ">",
    GTE: ">=",
    EQ: "=",
    LT: "<",
    LTE: "<=",
  }[relation];
};

export const conditionAmountToText = (condition, trans) => {
  const { relation } = condition;
  const conditionAmount = {};

  conditionAmount.condition =
    trans("global.pricing.amount") + " " + trans("global.pricing.net");
  conditionAmount.relation = relationSymbol(relation);

  return conditionAmount;
};

export const approvalTypeTransform = (approval, trans) => {
  let type = "";
  let name = "";
  let value = "";
  if (approval.role) {
    type = "Role";
    value = approval.role;
    name = trans("global.role");
  } else if (approval.user) {
    type = "User";
    if (typeof approval.user === "object") {
      value = approval.user.firstName + " " + approval.user.lastName;
    } else {
      const user = store.getters["COMPANY_MANAGEMENT_MODULE/getUser"](
        approval.user
      );
      value = user.firstName + " " + user.lastName;
    }
    name = trans("global.user");
  } else if (approval.approverOfDepartment) {
    type = "Department";
    if (typeof approval.approverOfDepartment === "object") {
      value = approval.approverOfDepartment.name;
    } else {
      const department = store.getters[
        "COMPANY_MANAGEMENT_MODULE/departmentDetails"
      ](approval.approverOfDepartment);
      value = department;
    }
    name = trans("global.department");
  } else if (approval.budgetOwnerOfDepartment) {
    type = "Department";
    if (typeof approval.budgetOwnerOfDepartment === "object") {
      value = approval.budgetOwnerOfDepartment.name;
    } else {
      const department = store.getters[
        "COMPANY_MANAGEMENT_MODULE/departmentDetails"
      ](approval.budgetOwnerOfDepartment);
      value = department;
    }
    name = trans("workflow.editor.budgetOwnerOfDepartment");
  } else if (approval.departmentApprover) {
    type = "Department";
    value = trans("workflow.editor.value.approverOfDepartment");
    name = trans("global.department");
  } else if (approval.budgetOwnerDepartmentApprover) {
    type = "Department";
    value = trans("workflow.editor.value.budgetOwnerOfrelevantDepartment");
    name = trans("workflow.editor.budgetOwnerOfDepartment");
  } else if (approval.originalRequester) {
    type = "Original Requester";
    value = trans("workflow.editor.value.originalRequester");
    name = trans("global.purchaseOrder");
  } else if (approval.requestedFor) {
    type = "Requested For";
    value = trans("workflow.editor.value.requestedFor");
    name = trans("global.purchaseOrder");
  } else if (approval.approverOfLegalEntity) {
    type = "Legal Tendency";
    if (typeof approval.approverOfLegalEntity === "object") {
      value = approval.approverOfLegalEntity.name;
    } else {
      const legalEntity = store.getters[
        "COMPANY_MANAGEMENT_MODULE/legalTendencyDetails"
      ](approval.approverOfLegalEntity);
      value = legalEntity;
    }
    name = trans("global.legalEntity");
  } else if (approval.responsibleUser) {
    type = "ResponsibleUser";
    value = trans("workflow.editor.responsibleUser");
    name = trans("workflow.editor.responsibleUser");
  }

  const returnValue = {
    type,
    value,
    name,
  };

  return returnValue;
};

// END NEW BY STEFAN
export const workflowListingText = (workflow, trans) => {
  const { conditions, requiredApprovalTypes, result } = workflow;

  return [
    conditionText(conditions, trans),
    approvalText(requiredApprovalTypes, trans),
    resultText(result, trans),
  ];
};

const typeMapping = (object) => {
  if (object.role) return object.role;
  else if (object.user) return object.user;
  else if (object.approverOfDepartment) return object.approverOfDepartment;
  else return null;
};
export const resultText = (result, trans) => {
  const params = {
    resource: typeMapping(result) || result.key,
  };
  return trans("workflow.result", params);
};

export const approvalText = (approval, trans) => {
  const params = {
    approver: listToSentence(
      approval.map((element) => {
        return typeMapping(element) || element.id;
      }),
      "and"
    ),
  };
  return trans("workflow.approval", params);
};

export const conditionText = (condition, trans) => {
  const { amount, department, category, user } = condition;
  const text = [];
  let params = {};
  if (amount) {
    const { relation, value } = amount;
    params = {
      conditionName: "Amount",
      value: `${relationText(relation)} ${value}`,
    };
    text.push(trans("workflow.condition", params));
  }
  if (department && department.length) {
    params = {
      conditionName: "Department",
      value: listToSentence(department),
    };
    text.push(trans("workflow.condition", params));
  }
  if (category && category.length) {
    params = {
      conditionName: "Category",
      value: listToSentence(category),
    };
    text.push(trans("workflow.condition", params));
  }
  if (user && user.length) {
    params = {
      conditionName: "Users",
      value: listToSentence(user),
    };
    text.push(trans("workflow.condition", params));
  }
  return text.join(" and ");
};

export const listToSentence = (source, operator = "or") => {
  if (typeof source === "string") {
    return source;
  }
  if (source.length == 1) {
    return source[0].name;
  }
  const list = source.map((obj) => obj.name);
  return (
    list.slice(0, source.length - 1).join(", ") +
    ` ${operator} ` +
    list.slice(-1)
  );
};

export const relationText = (relation) => {
  return {
    GT: "greater than",
    GTE: "greater than or equal",
    E: "equal",
    LT: "less than",
    LTE: "less than or equal",
  }[relation];
};

export const saveWorkflowState = (approvalWorkflow, workflow, requestType) => {
  const { type, relation, id } = workflow;

  switch (requestType) {
    case "result":
      approvalWorkflow[requestType] = {
        key: workflow.type,
        value: workflow.id,
      };
      break;
    case "requiredApprovalTypes":
      approvalWorkflow[requestType].push(workflow);
      break;
    default:
      if (type === "amount") {
        approvalWorkflow[requestType][workflow.type] = {
          value: id,
          relation: relation,
        };
      } else {
        approvalWorkflow[requestType][workflow.type] = id;
      }
  }
};
export const eventLists = (approvalWorkflow, trans) => {
  const { conditions, requiredApprovalTypes, result } = approvalWorkflow;
  const lists = {
    conditions: conditionEventsList(conditions, trans),
    approvals: approvalEventsList(requiredApprovalTypes, trans),
  };
  if (result.key !== undefined && result.key !== null) {
    lists["result"] = [
      {
        text: resultText(result, trans),
        key: result.key,
        value: result.id ? result.id[0] : null,
      },
    ];
  } else {
    lists["result"] = [];
  }
  return lists;
};

const conditionEventsList = (condition, trans) => {
  const { amount, department, category, user } = condition;
  const text = [];
  let params = {};
  if (amount && amount.value) {
    const { relation, value } = amount;
    params = {
      conditionName: "Amount",
      value: `${relationText(relation)} ${value}`,
    };
    text.push({
      text: trans("workflow.condition", params),
      value: value,
      relation: relation,
      key: "amount",
    });
  }
  if (department && department.length) {
    params = {
      conditionName: "Department",
      value: listToSentence(department),
    };
    text.push({
      text: trans("workflow.condition", params),
      value: department,
      key: "department",
    });
  }
  if (category && category.length) {
    params = {
      conditionName: "Category",
      value: listToSentence(category),
    };
    text.push({
      text: trans("workflow.condition", params),
      value: category,
      key: "category",
    });
  }
  if (user && user.length) {
    params = {
      conditionName: "Users",
      value: listToSentence(user),
    };
    text.push({
      text: trans("workflow.condition", params),
      value: user,
      key: "user",
    });
  }
  return text;
};

const approvalEventsList = (approval, trans) => {
  const approvals = [];
  approval.forEach((element) => {
    if (element.id) {
      approvals.push({
        text: trans("workflow.approval", {
          approver: element.id.name || element.id,
        }),
        key: element.type,
        value: element.id,
      });
    }
  });
  return approvals;
};
const getIdsOfObjects = (objects) => {
  return objects.map((object) => object.id);
};

export const conditionData = (conditions) => {
  const {
    amount,
    department,
    user,
    category,
    deviation,
    amountDeviation,
    legalEntity,
    supplier,
    unlistedSupplier,
    project,
    isProject,
  } = conditions;
  return {
    amount,
    department: department ? getIdsOfObjects(department) : null,
    user: user ? getIdsOfObjects(user) : null,
    category: category ? getIdsOfObjects(category) : null,
    deviation,
    amountDeviation,
    legalEntity: legalEntity ? getIdsOfObjects(legalEntity) : null,
    unlistedSupplier: isValuePresent(unlistedSupplier)
      ? unlistedSupplier
      : null,
    isProject: isValuePresent(isProject) ? isProject : null,
    supplier: supplier ? getIdsOfObjects(supplier) : null,
    project: project ? getIdsOfObjects(project) : null,
  };
};

export const approvalData = (approvals) => {
  return approvals.map((approval, index) => {
    const hash = { order: index };
    const keys = Object.keys(approval).filter(
      (x) => !["id", "order"].includes(x)
    );
    const type = keys[keys.length - 1];
    hash[type] = approval[type].id ? approval[type].id : approval[type];
    return hash;
  });
};

export const resultData = (result) => {
  if (result.responsibleUser) return result;

  if (result.role == "user") {
    return { user: result.user.id };
  } else {
    return { role: result.role };
  }
};

export const resultToKeyMapping = (result) => {
  const mapping = {
    RQ: "requester",
    BU: "buyer",
  };
  const key = Object.keys(result)[0];
  const value =
    key == "role" ? mapping[Object.values(result)] : Object.values(result);
  const hashResponse = {
    key: key,
    id: value,
  };
  return hashResponse;
};

export const approvalsToKeyMapping = (approvals) => {
  const aprovalData = approvals.map((approval) => {
    const resHash = {};
    if (Object.keys(approval).includes("user")) {
      resHash.type = "user";
    }
    if (Object.keys(approval).includes("role")) {
      resHash.type = "role";
    }
    if (Object.keys(approval).includes("approverOfDepartment")) {
      resHash.type = "approverOfDepartment";
    }
    resHash.id = approval[resHash.type];
    return resHash;
  });
  return aprovalData;
};
export const roleName = (role) => {
  return WORKFLOW_ROLES_MAPPING[role];
};
