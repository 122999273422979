<template>
  <div
    id="donut-chart"
    class="bg-white rounded border border-gray-200 py-4 sm:px-6 sm:py-6"
  >
    <div id="header-with-tools">
      <h2 class="truncate text-base font-medium text-gray-500">
        {{ $t("charts.requestBarChart.heading") }}
      </h2>
    </div>

    <ChartLoader v-if="loading" />
    <div v-else class="card-content">
      <BarChart
        v-if="filteredData?.series[0].data?.length"
        :loading="chartLoading"
        :series="filteredData.series"
        :categories="filteredData.categories"
        :colors="colors()"
        :on-request-data-point-selection="onRequestDataPointSelection"
        :custom-tooltip="customTooltip()"
        :show-currency-symbol="showCurrencySymbol"
      />

      <EmptyState
        v-else
        :heading-text="$t('charts.emptyState.notFound.heading')"
        :description-text="$t('charts.emptyState.notFound.desc')"
      />
      <div class="grid grid-cols-4 gap-6">
        <div class="rounded-md">
          <div class="label !text-primary">
            {{ $t("charts.requestBarChart.sortBy") }}
          </div>
          <Multiselect
            v-model="sortBy"
            :searchable="true"
            :options="sortByOptions"
            :can-clear="false"
            :open-direction="'top'"
            @change="() => onResetDataPointSelection()"
          />
        </div>
        <div class="rounded-md">
          <div class="label !text-primary">
            {{ $t("charts.requestBarChart.filterBy") }}
          </div>
          <Multiselect
            v-model="filterBy"
            :searchable="true"
            :options="filterByOptions"
            :can-clear="false"
            :open-direction="'top'"
            @change="() => onResetDataPointSelection()"
          />
        </div>
        <div class="rounded-md">
          <div class="label !text-primary">
            {{ $t("charts.requestBarChart.filterRequestedBy") }}
          </div>
          <MemberSelector
            id="requested-by-selector"
            :value="filterRequestedBy"
            :on-value-change="onRequestedByChange"
            :members-list="usersList()"
            :open-direction="'top'"
            :placeholder="
              $t('charts.requestBarChart.filterRequestedByPlaceholder')
            "
          />
        </div>
        <div class="rounded-md flex flex-col justify-between justify-self-end">
          <SwitchCheckbox
            v-model="showRequestsWithPurchaseOrders"
            class="inline-flex mb-2 mr-0"
            :label="$t('charts.requestBarChart.showRequestsWithPurchaseOrders')"
          />
          <SwitchCheckbox
            v-model="hideRevokedRequests"
            class="inline-flex"
            :label="$t('charts.requestBarChart.hideRevokedRequests')"
            :info-text="
              $t('charts.requestBarChart.hideRevokedRequestsTooltipText')
            "
            show-info
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BarChart, Description } from "@/components/Analytics/charts";
import { currencyLocaleMixin } from "@/components/mixins";
import Multiselect from "@vueform/multiselect";
import {
  ChartLoader,
  EmptyState,
  MemberSelector,
  SwitchCheckbox,
} from "@/components/shared";
import { PURCHASE_REQUEST_STATUS_MAPPING } from "@/utils/constants";
import { titleize } from "@/utils/utility_methods";
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    BarChart,
    Multiselect,
    // eslint-disable-next-line vue/no-unused-components
    Description,
    ChartLoader,
    EmptyState,
    SwitchCheckbox,
    MemberSelector,
  },

  mixins: [currencyLocaleMixin],
  props: {
    loading: { type: Boolean, default: false },
    purchaseRequests: { type: Array, required: true },
    onRequestDataPointSelection: {
      type: Function,
      required: true,
    },
    onResetDataPointSelection: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      chartLoading: this.loading,
      filterBy: "category",
      sortBy: "amount",
      showRequestsWithPurchaseOrders: false,
      hideRevokedRequests: false,
      filterRequestedBy: "",
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),

    showCurrencySymbol() {
      return this.sortBy !== "count";
    },
    filteredPurchaseRequests() {
      const filteredPurchaseRequests = this.purchaseRequests.reduce(
        (filteredRequests, purchaseRequest) => {
          const hasPurchaseOrder = !!purchaseRequest?.purchaseOrder;
          const isRevoked =
            this.hideRevokedRequests &&
            ["CA", "R"].includes(purchaseRequest?.status);
          const isRequestedByFilter =
            this.filterRequestedBy &&
            purchaseRequest.requestedBy?.requestedById ===
              this.filterRequestedBy;

          const shouldIncludePurchaseRequest =
            !isRevoked &&
            (!this.showRequestsWithPurchaseOrders || hasPurchaseOrder) &&
            (!this.filterRequestedBy || isRequestedByFilter);

          if (shouldIncludePurchaseRequest) {
            return [...filteredRequests, purchaseRequest];
          } else {
            return filteredRequests;
          }
        },
        []
      );

      return filteredPurchaseRequests;
    },
    filteredData() {
      const data = {};

      this.filteredPurchaseRequests.forEach((purchaseRequest) => {
        const object = this.isFilteredKeyOnItemLevel
          ? purchaseRequest.items[0]
          : purchaseRequest;

        const value = this.filterValue(object);

        if (!value) return;

        const formattedValue = parseFloat(purchaseRequest.totalNetAmount);

        if (data[value]) {
          data[value].totalAmounts.push(formattedValue);
          data[value].requestIds.push(purchaseRequest.id);
        } else {
          data[value] = {
            x: value,
            y: 0,
            totalAmounts: [formattedValue],
            requestIds: [purchaseRequest.id],
          };
        }
      });

      return {
        series: [{ name: this.selectedLabel, data: this.seriesData(data) }],
        categories: this.categoriesData(data),
      };
    },
    isFilteredKeyOnItemLevel() {
      return this.filterByOptions.find(
        (option) => option.value === this.filterBy
      )?.itemLevel;
    },
    selectedLabel() {
      return this.filterByOptions.find(
        (option) => option.value === this.filterBy
      )?.label;
    },
    sortByOptions() {
      return [
        {
          label: this.$t("global.pricing.amount"),
          value: "amount",
        },
        {
          label: this.$t("global.pricing.count"),
          value: "count",
        },
      ];
    },
    filterByOptions() {
      return [
        {
          label: this.$t("global.category"),
          value: "category",
          itemLevel: true,
        },
        {
          label: this.$t("global.supplier"),
          value: "companySupplier",
          itemLevel: true,
        },
        {
          label: this.$t("global.unlistedSupplier"),
          value: "unlistedSupplier",
          itemLevel: true,
        },
        {
          label: this.$t("global.department"),
          value: "department",
          itemLevel: true,
        },
        {
          label: this.$t("global.requestedBy"),
          value: "requestedBy",
          itemLevel: false,
        },
        {
          label: this.$t("global.project"),
          value: "project",
          itemLevel: true,
        },
        {
          label: this.$t("global.contract"),
          value: "contract",
          itemLevel: true,
        },
        {
          label: "Status",
          value: "status",
          itemLevel: false,
        },
        {
          label: this.$t("global.requestType"),
          value: "requestType",
          itemLevel: false,
        },
      ];
    },
  },
  watch: {
    loading: {
      handler(val) {
        this.chartLoading = val;
      },
    },
  },
  methods: {
    colors() {
      return ["#9D4770"];
    },
    filterValue(object) {
      const filterByValue = object[this.filterBy];

      switch (this.filterBy) {
        case "status": {
          const status = PURCHASE_REQUEST_STATUS_MAPPING[filterByValue];
          return status ? titleize(status) : null;
        }
        case "companySupplier":
        case "category": {
          const supplierName = filterByValue?.name;
          return !supplierName && !object["unlistedSupplier"]
            ? "No Supplier"
            : supplierName;
        }
        case "requestedBy":
          return `${filterByValue.firstName} ${filterByValue.lastName}`;
        case "contract":
          return filterByValue?.name;
        default:
          return filterByValue;
      }
    },
    seriesData(data) {
      let result;
      return Object.values(data).map((value) => {
        if (this.sortBy === "amount") {
          const sum = value.totalAmounts.reduce((acc, num) => acc + num, 0);
          result = Number(sum.toFixed(0));
        } else {
          result = value.requestIds.length;
        }
        return Object.assign({}, value, { y: result });
      });
    },
    categoriesData(data) {
      return [...new Set(Object.keys(data))];
    },
    customTooltip() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const totalAmount = data.totalAmounts.reduce(
            (acc, num) => acc + num,
            0
          );

          return `
          <div class="p-4">
            <ul>
              <li>
                <b class="text-primary">${self.$t("global.pricing.total")}: </b>
                ${self.valueInCurrency(totalAmount)}
               </li>
               <li>
                <b class="text-primary"> ${
                  w.globals.initialSeries[seriesIndex].name
                }: </b>
                ${data.x}
               </li>
               <li>
                <b class="text-primary">${self.$t(
                  "charts.requestBarChart.tooltip.requests"
                )}: </b>
                ${data.requestIds.length}
               </li>
            </ul>
          </div>
          `;
        },
      };
    },
    onRequestedByChange(value) {
      this.filterRequestedBy = value;
      this.onResetDataPointSelection();
    },
  },
};
</script>
