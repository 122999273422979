export default {
  exchangeRates: {},
  apiKeys: {},
  paymentMethods: [],
  companyAddresses: [],
  departments: [],
  departmentPaginationParams: {
    page: 1,
    pageSize: 100,
    totalPages: "",
    totalRecords: "",
    search: "",
  },
  company: {},
  costSaving: {
    costAvoidanceTm: 0,
    costAvoidanceAll: 0,
    costSavingTm: 0,
    costSavingAll: 0,
  },
  costSavingGoals: {},
  mainAddress: {},
  categories: {},
  purWorkflows: [],
  invoiceWorkflows: [],
  users: {},
  companySuppliers: {},
  companySuppliersListFilters: {
    page: 1,
    pageSize: 50,
    totalPages: "",
    totalRecords: "",
    search: "",
    expand: ["categories", "responsible_user"],
  },
  companyIntegratedSuppliers: {},
  integratedSuppliers: {},
  pendingUsers: [],
  budgets: {},
  globalPolicies: [],
  quickLinks: null,
  legalTendencies: [],
  integrations: [],
  integrationsSlugs: [],
  customFields: [],
};
