<template>
  <div id="departments">
    <div class="flex justify-between items-center">
      <span class="flex">
        <h3 class="page-title">
          {{ $t("companyManagement.departments.heading") }}
        </h3>
        <InformationPopover class="ml-2 hidden">
          <template #header>
            <div class="text-teal-600">
              {{ $t("companyManagement.departments.headingShort") }}
            </div>
          </template>
          <template #body>
            <div class="">
              {{ $t("companyManagement.departments.tooltip") }}
            </div>
          </template>
        </InformationPopover>
      </span>
      <div class="flex text-primary mb-4">
        <HivebuyTooltip
          v-if="!isUserOnlyAuditor"
          :open-delay="50"
          :disabled="!isDepartmentLimitReached"
        >
          <button
            type="button"
            class="flex items-center font-semibold"
            :class="
              isDepartmentLimitReached
                ? 'text-gray-400'
                : 'text-primary hover:text-primarydark'
            "
            @click="onAddDepartment"
          >
            <PlusCircleIcon class="mr-2 h-5 w-5" aria-hidden="true" />
            {{ $t("companyManagement.departments.addDepartment") }}
          </button>
          <template #content
            ><div class="p-2 text-sm font-medium whitespace-pre-line max-w-xs">
              <p class="mb-2 font-medium">
                You have reached your department limit.
              </p>
              Contact our
              <a class="text-primary font-normal" href="mailto:sales@hivebuy.de"
                >Sales Team</a
              >
              to enable more users.
            </div></template
          >
        </HivebuyTooltip>
      </div>
    </div>
    <div class="w-full">
      <DepartmentsList :on-add-department="onAddDepartment" />
    </div>
    <AddDepartmentModal
      :show-modal="showDepartmentModal"
      :close-modal="onModalClose"
    />
  </div>
</template>

<script>
import AddDepartmentModal from "@/components/CompanyManagement/Departments/AddDepartmentModal";
import DepartmentsList from "@/components/CompanyManagement/Departments/DepartmentsList";
import { PlusCircleIcon } from "@heroicons/vue/solid/esm";
import { InformationPopover, HivebuyTooltip } from "@/components/shared/index";
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    DepartmentsList,
    AddDepartmentModal,
    PlusCircleIcon,
    InformationPopover,
    HivebuyTooltip,
  },
  data() {
    return {
      showDepartmentModal: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getAmountDepartments"]),
    isDepartmentLimitReached() {
      return (
        this.getAmountDepartments() >
        this.getfeatFlagAmount("numberOfDepartments")
      );
    },
  },

  methods: {
    onModalClose() {
      this.showDepartmentModal = false;
    },
    onAddDepartment() {
      if (!this.isDepartmentLimitReached) {
        this.showDepartmentModal = true;
      }
    },
  },
};
</script>
