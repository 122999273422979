<template>
  <Condition :condition-text="approvalWorkflowTitle">
    <div class="!my-4 flex justify-between items-start">
      <p class="text-gray-400 pr-8">
        {{ $t("approvalWorkflow.globalPolicies.noInvoice.description") }}
      </p>

      <SwitchCheckbox
        v-model="isActive"
        :label="$t('approvalWorkflow.globalPolicies.active')"
        class="checkbox"
        :on-click-handler="onSave"
        :read-only="isUserOnlyAuditor"
      />
    </div>
    <div class="flex justify-between">
      <div class="flex">
        <div class="global-policy-budget-exceeded-wrapper w-400">
          <span class="isolate inline-flex rounded-md shadow-sm">
            <Button
              v-for="option in intervals"
              :key="option"
              :on-click-handler="() => (monthsDuration = option.value)"
              :loading="loading"
              :color="
                monthsDuration == option.value ? 'primary' : 'transparent'
              "
            >
              {{
                $t(
                  `approvalWorkflow.globalPolicies.noInvoice.intervals.${option.label}`
                )
              }}
            </Button>
          </span>
        </div>
        <div class="w-400">
          <Multiselect
            v-model="role"
            :disabled="loading || isUserOnlyAuditor"
            :searchable="true"
            :options="supplierSelectorOptions"
            :placeholder="
              $t('approvalWorkflow.globalPolicies.noSupplier.roleFieldError')
            "
            :label="'label'"
            :can-deselect="false"
          />
          <FieldError
            v-if="v$.role.$error"
            :text="
              $t('approvalWorkflow.globalPolicies.noSupplier.roleFieldError')
            "
          />
        </div>
      </div>
      <div class="place-self-end">
        <div class="flex justify-end mt-4">
          <Button
            :on-click-handler="onSave"
            :loading="loading"
            :disabled="isUserOnlyAuditor"
          >
            {{ $t("approvalWorkflow.globalPolicies.saveBtn") }}
          </Button>
        </div>
      </div>
    </div>
  </Condition>
</template>

<script>
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import globalPolicyMixin from "@/components/mixins/globalPolicyMixin";
import { required } from "@vuelidate/validators";
import { WORKFLOW_ROLES_MAPPING } from "@/utils/constants";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    SwitchCheckbox,
    Multiselect,
  },
  mixins: [globalPolicyMixin],
  data() {
    return {
      loading: false,
      monthsDuration: this.approvalWorkflow.monthsDuration,
      role: this.approvalWorkflow.roleForResult,
      isActive: this.approvalWorkflow.isActive,
    };
  },
  computed: {
    supplierSelectorOptions() {
      return Object.entries(WORKFLOW_ROLES_MAPPING).map(([key, value]) => {
        return { label: value, value: key };
      });
    },
    intervals() {
      return {
        every: { label: "every", value: 0 },
        sixMonths: { label: "sixMonths", value: 6 },
        twelveMonths: { label: "twelveMonths", value: 12 },
      };
    },
  },
  methods: {
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = "true";
        this.updateGlobalPolicy({
          conditionId: this.approvalWorkflow.id,
          payload: {
            roleForResult: this.role,
            monthsDuration: this.monthsDuration,
            isActive: this.isActive,
          },
        })
          .then(() => {
            this.showNotification(
              this.$t("approvalWorkflow.globalPolicies.updateSuccessText")
            );
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  validations() {
    return { role: { required } };
  },
};
</script>

<style scoped>
.global-policy-budget-exceeded-wrapper .user-row {
  @apply mb-0;
}

.global-policy-budget-exceeded-wrapper .user-row > div {
  @apply mb-0;
}

.global-policy-budget-exceeded-wrapper .user-row span {
  @apply font-normal text-gray-500 text-sm;
}

.global-policy-budget-exceeded-wrapper .user-row .grow-0 {
  @apply -translate-y-1;
}

.global-policy-budget-exceeded-wrapper .user-row .workflowItem {
  @apply rounded;
}

.w-400 {
  width: 400px;
}
</style>
