<template>
  <div v-if="!isApiLoading()" class="profile-content">
    <!-- Contact section -->
    <section class="relative" aria-labelledby="contactHeading">
      <div>
        <div class="relative bg-white shadow">
          <h2 id="contactHeading" class="sr-only">
            {{ $t("profile.editProfile") }}
          </h2>
          <div class="grid grid-cols-1 lg:grid-cols-3">
            <div
              class="relative overflow-hidden py-6 px-6 sm:px-10 xl:p-12 flex justify-center"
            >
              <div class="space-y-4">
                <div class="aspect-w-3 aspect-h-2 relative">
                  <img
                    v-if="editUser.avatar"
                    class="object-cover shadow-lg rounded-full"
                    :src="editUser.avatar"
                    alt="profile-pic"
                  />
                  <p
                    v-else
                    class="text-xs font-light h-28 w-full bg-gray-50 rounded-md flex object-center object-cover text-center align-middle justify-center items-center px-2"
                  >
                    {{ $t("profile.noPicture") }}
                  </p>
                </div>
                <div
                  class="text-lg leading-6 font-medium space-y-1 flex justify-center"
                >
                  <ImageUploader
                    :button-text="$t('profile.avatar')"
                    :save-image-data="saveAvatar"
                    full
                  />
                </div>
              </div>
            </div>

            <div class="py-6 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <h3 class="card-title">
                {{ $t("profile.editProfile") }}
              </h3>
              <div class="">
                <Input
                  :name="'email'"
                  :on-value-change="onAttributeChange"
                  :type="'email'"
                  :placeholder="$t('profile.emailPlaceholder')"
                  :value="editUser.email"
                  :disabled="true"
                  :label="$t('profile.emailPlaceholder')"
                />
              </div>
              <div
                class="mt-6 sm:grid sm:grid-cols-2 sm:gap-y-6 sm:gap-x-8 space-y-6 sm:space-y-0"
              >
                <Input
                  :name="'firstName'"
                  :on-value-change="onAttributeChange"
                  :type="'text'"
                  :placeholder="$t('profile.firstNamePlaceholder')"
                  :label="$t('profile.firstName')"
                  :value="editUser.firstName"
                />
                <Input
                  :name="'lastName'"
                  :on-value-change="onAttributeChange"
                  :type="'text'"
                  :placeholder="$t('profile.lastNamePlaceholder')"
                  :label="$t('profile.lastName')"
                  :value="editUser.lastName"
                />
                <div class="col-span-2">
                  <label class="block text-sm font-medium text-gray-500">{{
                    $t("profile.username")
                  }}</label>
                  <i18n-t
                    keypath="profile.usernameSubtext"
                    tag="p"
                    class="text-sm font-thin mb-4"
                  >
                    <template #username>
                      <span class="mx-1 text-primary text-base font-normal"
                        >@{{ editUser.username }}</span
                      >
                    </template>
                  </i18n-t>
                  <Input
                    :name="'username'"
                    :on-key-up="onAttributeChange"
                    :type="'text'"
                    :placeholder="$t('profile.username')"
                    :value="editUser.username"
                  />
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700 my-2">{{
                    $t("profile.language")
                  }}</label>
                  <DropDown
                    :value="localValue(editUser.language)"
                    :on-change="changeLanguage"
                    :list-options="localeOptions()"
                    :show-label="false"
                  />
                </div>
                <div class="lg:col-span-2 mb-6 py-4">
                  <label class="block text-sm font-medium text-gray-500">{{
                    $t("profile.setAway")
                  }}</label>
                  <p class="text-sm font-thin mb-4">
                    {{ $t("profile.awayHelperText") }}
                  </p>
                  <Substitute
                    v-if="showSubForm"
                    :user="user"
                    :substitute-user-details="substituteUserDetails"
                    :update-user="saveDetails"
                    :cancel="toggleSubForm"
                  />
                  <div v-else>
                    <div v-if="user.substitute">
                      <div
                        class="p-3 border rounded-md border-gray-300 grid grid-cols-2"
                      >
                        <div class="flex items-center text-sm">
                          <div>
                            <p class="text-base font-medium">
                              You are set as away
                            </p>
                            <p class="font-light">
                              You are being substituted until<span
                                class="ml-1 font-bold"
                                >{{ formattedDate(user.substituteUntil) }}</span
                              >
                            </p>
                          </div>
                        </div>
                        <UserWithAvatar
                          :user="user.substitute"
                          :show-email="true"
                          :show-name="true"
                          :show-tooltip="true"
                        />

                        <div class="mt-4 flex gap-x-4">
                          <span
                            class="py-2 px-3 rounded-md text-sm font-normal text-hivebuy_plum border border-hivebuy_plum hover:bg-primary hover:text-white cursor-pointer"
                            @click="toggleSubForm"
                            >Change</span
                          >
                          <span
                            class="py-2 px-3 rounded-md text-sm font-normal border border-hivebuy_plum bg-primary text-white cursor-pointer hover:bg-hivebuy_darkplum"
                            @click="resetSubstitute"
                            >I am back!</span
                          >
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span
                        class="py-2 px-3 rounded-md text-sm font-normal text-hivebuy_plum border border-hivebuy_plum hover:bg-primary hover:text-white cursor-pointer"
                        @click="toggleSubForm"
                      >
                        {{ $t("profile.substiteButton") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="md:col-span-2">
                  <div class="flex items-center">
                    <label class="block text-sm font-medium text-gray-500">
                      {{ $t("companyManagement.users.table.headerRoles") }}
                    </label>
                    <UserRoles :user-roles="user.roles" :info-tooltip="true" />
                  </div>
                  <div class="flex items-center text-white w-max mt-2">
                    <UserRoles :user-roles="user.roles" />
                  </div>
                </div>

                <DisplayList
                  :label="$t('profile.departments')"
                  :list="user.departments || []"
                  classes="md:col-span-1"
                />

                <DisplayList
                  :label="$t('profile.legalTendency')"
                  :list="showLegalEntity"
                  classes="xl:col-span-2"
                />

                <div class="xl:col-span-2 md:col-span-1 mb-6 py-3">
                  <label class="block text-sm font-medium text-gray-500">{{
                    $t("profile.avatarBackground")
                  }}</label>
                  <p class="text-sm font-thin mb-4">
                    {{ $t("profile.uploadPicture") }}
                  </p>
                  <div class="flex items-center">
                    <div
                      v-for="(bgColor, index) in USER_AVATAR_STYLE"
                      :key="bgColor"
                      class="cursor-pointer mx-2"
                      :class="[
                        index == editUser.colorAvatar
                          ? 'rounded-full ring-2 ring-teal-500 ring-offset-2'
                          : '',
                      ]"
                    >
                      <UserImage
                        :user="editUser"
                        :background-colour="USER_AVATAR_STYLE[index]"
                        :show-image="false"
                        @click="() => changeAvatarColor(index)"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="py-6 col-span-2 grid grid-cols-3 align-middle items-center border-t border-gray-200"
                >
                  <div class="align-middle flex items-center">
                    <img
                      src="@/assets/images/Slack_RGB.svg"
                      alt="Slack"
                      class="h-8/12 w-8/12 inline"
                    />
                    <TooltipPopover :tooltip-class="'text-black'">
                      <template #header>{{
                        $t("profile.slackGuide")
                      }}</template>
                      <template #body>
                        <li>{{ $t("profile.slackGuide1") }}</li>
                        <li>{{ $t("profile.slackGuide2") }}</li>
                        <li>{{ $t("profile.slackGuide3") }}</li>
                        <li>{{ $t("profile.slackGuide4") }}</li>
                      </template>
                    </TooltipPopover>
                  </div>

                  <div class="col-span-2">
                    <Input
                      :name="'slackId'"
                      :on-value-change="onAttributeChange"
                      :type="'text'"
                      :placeholder="'Slack ID'"
                      :value="editUser.slackId"
                    />
                  </div>
                </div>
                <div class="sm:col-span-2 sm:flex sm:justify-center mt-8">
                  <Button
                    type="button"
                    :full="true"
                    :on-click-handler="saveForm"
                    :loading="loading"
                  >
                    {{ $t("profile.save") }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { AUTH_MODULE, SET_USER, UPDATE_ME } from "@/store/Auth/types";
import { localeMixin } from "@/components/mixins/index.js";
import { USER_AVATAR_STYLE } from "@/utils/constants.js";
import Substitute from "@/components/Profile/Substitute.vue";
import { removeKeysFromObject } from "@/utils/utility_methods";
import {
  UserWithAvatar,
  Button,
  Input,
  UserImage,
  ImageUploader,
  TooltipPopover,
  UserRoles,
  DisplayList,
} from "@/components/shared/index";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";

export default {
  components: {
    UserRoles,
    Input,
    Button,
    ImageUploader,
    UserImage,
    TooltipPopover,
    Substitute,
    UserWithAvatar,
    DisplayList,
  },
  mixins: [localeMixin],
  data() {
    return {
      loading: false,
      showSubForm: false,
      substituteUserDetails: {},
      editUser: {
        firstName: "",
        lastName: "",
        email: "",
        gender: "male",
        colorAvatar: 0,
        avatar: "",
      },
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, [
      "paymentMethods",
      "companyAddresses",
      "company",
      "costSaving",
    ]),
    showLegalEntity() {
      return this.user.legalEntity
        ? [{ name: this.user.legalEntity.name }]
        : [];
    },
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(val) {
        this.editUser = val;
      },
    },
  },
  created() {
    this.USER_AVATAR_STYLE = USER_AVATAR_STYLE;
  },

  methods: {
    ...mapMutations(AUTH_MODULE, {
      setUser: SET_USER,
    }),
    ...mapActions(AUTH_MODULE, {
      updateMe: UPDATE_ME,
    }),
    toggleSubForm() {
      this.showSubForm = !this.showSubForm;
    },
    changeAvatarColor(index) {
      this.editUser.colorAvatar = index;
    },
    changeLanguage(val) {
      const locale = this.localeFromValue(val);
      this.editUser.language = locale.value;
    },
    onAttributeChange(event) {
      let { value } = event.target;
      const { name } = event.target;
      if (name == "username") {
        value = value.split(" ").join("");
        value = value.replace(/[^a-zA-Z0-9]/g, "");
      }
      this.editUser[name] = value;
    },
    saveAvatar(data) {
      this.editUser.avatar = data;
    },
    resetSubstitute() {
      const payload = {
        substitute: null,
        substitute_until: null,
      };
      this.saveDetails(payload);
    },
    saveForm() {
      const payload = removeKeysFromObject(this.editUser, [
        "budget",
        "featureFlags",
        "roles",
        "isActive",
        "departments",
        "requiredSetupSteps",
        "name",
        "email",
        "legalEntity",
        "id",
      ]);
      this.saveDetails(payload);
    },
    saveDetails(payload) {
      this.loading = true;
      this.updateMe({ payload })
        .then((response) => {
          this.$i18n.locale = this.editUser.language.toLowerCase();
          this.showNotification(this.$t("toasts.genericSuccess"), "success");
          this.setUser(response.data);
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
          this.showSubForm = false;
        });
    },
  },
};
</script>

<style scoped>
.card-title {
  @apply text-sm text-primary font-semibold mb-4;
}
</style>
