<template>
  <div v-if="list.length" class="recent-item-holder">
    <div class="line"></div>
    <h3 class="heading">
      {{ $t("home.recentlyViewed") }}
    </h3>
    <ul class="recent-list">
      <li
        v-for="(index, recent) in list"
        :key="index"
        @click="goToPuR(recent?.id)"
      >
        {{ recent.name }}
        <span>{{ recent.title }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
export default {
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, ["recentlyViewed"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    list() {
      return this.requestDetails(this.recentlyViewed);
    },
  },
  methods: {
    goToPuR(id) {
      this.$router.push({
        name: "RequestDetail",
        params: { id: id },
      });
    },
  },
};
</script>

<style scoped>
.recent-item-holder {
  @apply px-4 mt-4;
}

.line {
  @apply w-full;
  height: 1px;
  background: white;
  opacity: 0.2;
}
.heading {
  @apply text-white text-xs font-semibold mt-4 mb-2 truncate shrink-0;
}

.recent-list {
}

.recent-list li {
  @apply text-sm flex cursor-pointer text-white opacity-70 hover:opacity-100;
}

.recent-list li span {
  @apply line-clamp-1;
}
</style>
