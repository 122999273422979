import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { mapMutations, mapGetters } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  SET_NEW_PURCHASE_REQUEST,
  SET_CURRENT_REQUEST_CATALOG_DATA,
} from "@/store/PurchaseRequest/types";
import { supplierInfo } from "@/utils/helpers/modelHelpers/purchaseRequest/requestDataMapper.js";
import { deepClone } from "@/utils/utility_methods";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants.js";
import { AUTH_MODULE } from "@/store/Auth/types";
export default {
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["companySetting"]),
    ...mapGetters(AUTH_MODULE, ["modelId"]),
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setNewPurchaseRequest: SET_NEW_PURCHASE_REQUEST,
    }),
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCatalogueRequest: SET_CURRENT_REQUEST_CATALOG_DATA,
    }),
    addSourceInfo(item) {
      if (!item.source) return;
      const itemSource = Object.keys(item.source).includes("object")
        ? item.source.object
        : item.source;
      item.itemId = itemSource.id;
      item.sourceType = this.modelId(item.source.type);
      item.sourceId = itemSource.id;
      item.isCatalogueItem =
        item.sourceType === this.modelId(CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM);
    },
    addRequestedForInfo(item) {
      item.requestedForId = item.requestedFor.id;
      item.requestedForType = item.requestedFor.type;
      item.department = item.requestedFor.department;
      item.project = item.requestedFor.project;
    },
    duplicatePurchaseRequestPayload(edit = false) {
      const purchaseRequestPayload = deepClone(this.purchaseRequest);
      delete purchaseRequestPayload.id;
      purchaseRequestPayload.items.forEach((item) => {
        item.catalogueItemVariant =
          item.catalogueItemVariant?.id || item.catalogueItemVariant;

        this.addSourceInfo(item);
        this.addRequestedForInfo(item);
        this.deleteExtraKeys(item, edit);
      });
      purchaseRequestPayload.category = {
        id: purchaseRequestPayload.items[0].category,
      };
      purchaseRequestPayload.supplier = supplierInfo(
        purchaseRequestPayload.items[0]
      );
      return purchaseRequestPayload;
    },
    deleteExtraKeys(item, edit) {
      if (!edit) delete item.id;
      delete item.requestedFor;
      delete item.source;
      delete item.catalogueItemVariant;

      if (item.customFields?.length) {
        item.customFields.forEach((customField) => delete customField.id);
      }
    },
  },
};
