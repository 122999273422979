export const mappedSource = (source) => {
  return {
    freeForm: "FREEFORM",
    catalogue: "CATALOGUE",
    catalogueUserInput: "CATALOGUE_USER_INPUT",
    integrated: "INTEGRATED_SUPPLIER",
  }[source];
};

export const allowedField = (field, type = "freeform") => {
  const source =
    type === "catalogue" ? ["catalogue", "catalogueUserInput"] : [type];

  const sourceType = source.map((s) => mappedSource(s));
  if (!field.inputType.length) return true;
  return field.inputType.some((type) => sourceType.includes(type));
};
