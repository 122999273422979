<template>
  <RequestAddress
    v-model="item.companyAddress"
    is-edit
    :requested-for-id="modelValue.requestedForId"
    :is-invalid="!!errorMessage('companyAddress').length"
    :error-message="errorMessage('companyAddress')"
    :can-clear="false"
    :can-deselect="false"
  />
  <RequestAddress
    v-model="item.invoiceAddress"
    is-edit
    :label="$t('purchaseRequest.detail.invoiceAddress')"
    is-invoice-address
    :requested-for-id="modelValue.requestedForId"
    :is-invalid="!!errorMessage('invoiceAddress').length"
    :error-message="errorMessage('invoiceAddress')"
    :can-clear="false"
    :can-deselect="false"
  />
</template>
<script>
import RequestAddress from "@/components/shared/RequestAddress.vue";
import newRequestErrorMixin from "@/components/mixins/newRequestErrorMixin.js";
export default {
  components: {
    RequestAddress,
  },
  mixins: [newRequestErrorMixin],
  props: {
    defaultProduct: { type: Object, default: null },
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    item: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
