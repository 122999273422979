<template>
  <Modal :show-modal="open" :close-modal="closeFunction">
    <template #icon>
      <SaveIcon class="h-5 w-5 text-white" aria-hidden="true" />
    </template>
    <template #header>
      <h3 class="text-xl font-medium text-gray-500">
        {{ $t(`purchaseRequest.newRequest.${requestType}.title`) }}
      </h3>
    </template>
    <template #body>
      <div class="my-6">
        <p class="text-md text-gray-500 mb-4">
          {{ $t(`purchaseRequest.newRequest.${requestType}.text`) }}
        </p>
        <Input
          :value="suggestion"
          :name="'name'"
          :on-value-change="onAttributeChange"
          :type="'text'"
          :on-enter="saveTitle"
          :label="$t(`purchaseRequest.newRequest.${requestType}.label`)"
        />
      </div>
    </template>
    <template #footer>
      <Button :on-click-handler="closeFunction" color="cancel-gray">
        {{ $t("buttons.cancel") }}
      </Button>
      <Button :on-click-handler="saveTitle" color="success" full>
        <template #icon>
          <ShoppingCartIcon />
        </template>
        {{
          isDraft ? $t("buttons.markAsDraft") : $t("buttons.checkout")
        }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, Input } from "@/components/shared/index";
import { SaveIcon, XIcon, ShoppingCartIcon } from "@heroicons/vue/outline";
export default {
  components: {
    Modal,
    Input,
    Button,
    ShoppingCartIcon,
    // eslint-disable-next-line vue/no-unused-components
    SaveIcon,
    // eslint-disable-next-line vue/no-unused-components
    XIcon,
  },
  props: {
    suggestion: {
      type: String,
      required: false,
      default: "",
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    saveTitleAndStay: {
      type: Function,
      default: () => {},
    },
    saveMethod: {
      type: Function,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    closeFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      title: "",
    };
  },
  computed: {
    requestType() {
      return this.isDraft ? "draft" : "saveRequest";
    },
  },
  watch: {
    suggestion: {
      handler(val) {
        this.title = val;
      },
      immediate: true,
    },
  },
  methods: {
    onAttributeChange(event) {
      this.title = event.target.value;
    },
    saveTitleNameAndStay() {
      this.saveTitleAndStay(this.title);
    },
    saveTitle() {
      this.saveMethod(this.title);
    },
  },
};
</script>
