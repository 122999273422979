<template>
  <div>
    <div
      v-if="showImage && user && userImageLoaded"
      class="flex-shrink-0"
      :class="`h-${imageDimension} w-${imageDimension}`"
    >
      <img
        class="rounded-full border-white border bg-white"
        :class="`h-${imageDimension} w-${imageDimension}`"
        :src="userImage"
        alt=""
        @error="imageLoadError"
      />
    </div>
    <div
      v-else
      class="flex-shrink-0 rounded-full inline-flex items-center justify-center border-white border cursor-default"
      :class="[initialsBg, `h-${imageDimension} w-${imageDimension}`]"
    >
      <span v-if="user" class="text-sm font-medium leading-none text-white">
        {{ nameInitials(user) }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import userMixin from "@/components/mixins/userMixin";
import { USER_AVATAR_STYLE } from "@/utils/constants.js";
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_USERS,
  NULLIFY_MEMBER_EXPIRED_AVATAR,
} from "@/store/CompanyManagement/types";
import { isEmptyString } from "@/utils/utility_methods";

export default {
  mixins: [userMixin],
  props: {
    showImage: { type: Boolean, default: true },
    backgroundColour: { type: String, required: false, default: null },
    user: { type: Object, required: true, default: null },
    imageDimension: {
      type: Number,
      optional: true,
      default: 8,
    },
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["users"]),
    initialsBg() {
      if (this.backgroundColour) {
        return this.backgroundColour;
      }
      if (this.user && this.user.colorAvatar) {
        return USER_AVATAR_STYLE[this.user.colorAvatar];
      } else {
        return "bg-primary";
      }
    },
    userImage() {
      const member = this.users[this.userId];
      return member?.avatar;
    },
    userId() {
      return this.user.id || this.user;
    },
    userImageLoaded() {
      return !this.isEmptyString(this.userImage);
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUsers: GET_USERS,
    }),
    ...mapMutations(COMPANY_MANAGEMENT_MODULE, {
      nullifyExpiredAvatar: NULLIFY_MEMBER_EXPIRED_AVATAR,
    }),

    imageLoadError() {
      const { id } = this.user;
      this.nullifyExpiredAvatar({ id });
      this.getUsers({ user_id: id });
    },
    isEmptyString,
  },
};
</script>
