<template>
  <div class="select-category">
    <section class="header-section">
      <p class="text-sm text-gray-500">
        {{ $t("purchaseRequest.selectCategorySubtext") }}
      </p>
    </section>

    <section id="search" class="search">
      <div class="flex space-x-4">
        <div class="w-1/2">
          <Input
            id="search"
            :value="search"
            name="search"
            :placeholder="$t('general.search')"
            show-icon
            type="search"
            :on-key-up="onAttributeChange"
          />
        </div>
        <div class="w-1/2">
          <CategorySelect
            v-model="categoryTreeSelect"
            :placeholder="$t('purchaseRequest.newRequest.categoryTreeView')"
            :default-expand-level="2"
          />
        </div>
      </div>
    </section>

    <div v-if="filteredList?.length" class="list-holder">
      <section class="category-list">
        <div
          v-for="category in filteredList"
          :id="category.id"
          :key="category.id"
          :class="[
            'category-card relative ',
            toKebabCase(category.name),
            { selected: selectedCategory === category.id },
          ]"
          class="cursor-pointer"
          @click="
            () => {
              setCategoryValue(category.id);
            }
          "
        >
          <div class="emoji-holder">
            <template v-if="category.emoji">
              <div class="emoji">
                {{ category.emoji }}
              </div>
            </template>
            <div v-else class="emoji">
              <TagIcon class="text-gray-400 w-4 h-4" />
            </div>
          </div>

          <div class="title-holder">
            <p
              v-tippy="{
                content: category.name,
                theme: 'time',
                delay: [300, 50],
              }"
              class="card-title"
            >
              {{ category.name }}
            </p>
          </div>
          <div
            v-if="category.notes != '' || category.glAccount"
            class="notes-holder"
          >
            <div v-if="category.glAccount" class="notes-icon">
              <BookOpenIcon class="text-gray-400 h-5 w-5 mr-1" />
            </div>
            <div v-if="category.glAccount" class="notes">
              <p class="text-xs">
                {{ $t("companyManagement.category.glAccount") }}
              </p>
              <p class="text-sm font-medium">
                {{ category.glAccount }}
              </p>
            </div>
          </div>
          <div v-if="category.notes">
            <ListButton
              v-tippy="{
                content: category.notes,
                delay: [50, 50],
                theme: 'time',
              }"
              @click.stop
            >
              <template #icon>
                <DocumentTextIcon class="w-5 h-5" />
              </template>
            </ListButton>
          </div>
        </div>
      </section>
    </div>
    <div v-else>
      <div class="bg-white shadow-sm sm:rounded-md border p-10 text-center">
        <BanIcon class="h-8 w-8 text-red-500 mx-auto mb-4" />
        <p class="font-medium text-sm mb-1">No Categories</p>
        <p class="text-gray-400 text-sm mb-5">
          {{ $t("companyManagement.category.noCategories") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_DATA,
} from "@/store/PurchaseRequest/types.js";
import { toKebabCase } from "@/utils/utility_methods.js";

import { BanIcon } from "@heroicons/vue/solid";
import {
  DocumentTextIcon,
  TagIcon,
  BookOpenIcon,
} from "@heroicons/vue/outline";
import { CategorySelect, Input, ListButton } from "@/components/shared/index";
import { AUTH_MODULE } from "@/store/Auth/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    DocumentTextIcon,
    BanIcon,
    Input,
    ListButton,
    TagIcon,
    BookOpenIcon,
    CategorySelect,
  },
  props: {
    selectedCategory: { type: String, default: null },
    saveCategory: {
      type: Function,
      default: () => {},
    },
    saveToStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: "",
      categoryValue: null,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoriesList",
      "companySetting",
    ]),
    categoryTreeSelect: {
      get() {
        return this.selectedCategory;
      },
      set(value) {
        setTimeout(() => this.setCategoryValue(value), 0);
      },
    },
    filteredList() {
      const isParentCategoryDisabled = this.companySetting(
        "PARENT_CATEGORY_DISABLED"
      )?.active;
      const list = this.categoriesList;
      if (!list?.length) return [];
      const search = this.search.toLowerCase();
      return list.filter(
        (category) =>
          (!isParentCategoryDisabled || !category.children.length) &&
          [category.name, category.glAccount].some((item) =>
            item?.toLowerCase().includes(search)
          )
      );
    },
  },

  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCurrentRequestData: SET_CURRENT_REQUEST_DATA,
    }),
    setCategoryValue(id) {
      if (this.saveToStore) {
        this.setCurrentRequestData({
          field: "category",
          value: id,
          redirectPage: "supplier",
        });
      } else this.saveCategory(id);
    },
    toKebabCase,
    onAttributeChange(event) {
      const { value } = event.target;
      this.search = value;
    },
  },
};
</script>

<style scoped>
.list-holder {
  @apply flex-1;
}
.category-list {
  @apply grid grid-cols-1 gap-x-4 gap-y-2 lg:grid-cols-2;
}

.category-card {
  @apply relative border border-gray-200 text-left px-3 py-4 flex items-center rounded-md shadow hover:border-gray-400 transition hover:bg-gray-50;
}

.category-card.selected {
  @apply bg-primary border-white;
}

.card-title {
  @apply font-semibold text-sm text-primary;
}
.category-card.selected .card-title {
  @apply text-white bg-primary border-white;
}

.information-popover {
  @apply absolute top-2 right-2 flex-shrink-0 h-6 w-6;
}

.emoji-holder {
  @apply mr-2 w-[32px] h-[32px] rounded-full border bg-white  flex justify-center items-center flex-shrink-0;
}

.emoji-holder .emoji {
  @apply h-9 w-9 text-base flex justify-center items-center;
}

.search {
  @apply mb-4;
}

.select-category {
  @apply h-full w-full flex flex-col;
}

.header-section {
  @apply mb-2;
}

.icon-star {
  @apply h-6 w-6;
}

.icon-star-outline {
  @apply h-6 w-6;
  fill: none;
  stroke: #e6e7eb;
}

.icon-star-outline linearGradient {
  display: none;
}

.notes-holder,
.title-holder {
  @apply flex flex-1 mr-2 items-center;
}

.title-holder .card-title {
  @apply line-clamp-1;
}
.category-card .notes-holder p {
  @apply line-clamp-1 text-gray-500;
}
.category-card.selected .notes-holder p {
  @apply text-white;
}

.category-card .notes-holder .notes-icon {
  @apply mr-1;
}
.category-card.selected .notes-holder .notes-icon svg {
  @apply text-white;
}
</style>
