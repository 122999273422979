<template>
  <div class="flex items-center w-full">
    <div
      v-if="isApiLoading('categories')"
      class="flex items-center justify-center w-full py-2"
    >
      <HivebuyLoader class="h-5 w-5" />
    </div>
    <div v-else class="w-full">
      <HivebuyInlineSelect
        v-model="dropdownValue"
        :label="$t('global.category')"
        :placeholder="
          placeholder ||
          $t('companyManagement.companySupplier.addModal.placeholderCategories')
        "
        :options="dropdownOptions"
        :get-object-details="categoryDetails"
        :error="error"
        show-icon
        :narrow="true"
        :disabled="disabled"
      >
        <template #icon><TagIcon class="h-5 w-5 text-gray-400" /></template>
        <template #empty>
          <span>{{
            $t("global.prepositions.select", { object: $t("global.category") })
          }}</span>
          <p class="font-light">{{ $t("purchaseRequest.specifyCategory") }}</p>
        </template>
        <template #header>
          {{
            $t("global.prepositions.select", { object: $t("global.category") })
          }}
        </template>
        <template #details>
          <span class="font-light">
            {{ categoryDetails(dropdownValue, true)?.glAccount }}
          </span>
        </template>
        <template #footer>
          {{ $t("purchaseRequest.selectCategorySubtext") }}
        </template>
      </HivebuyInlineSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { HivebuyInlineSelect } from "design-buy";
import { TagIcon } from "@heroicons/vue/outline";

import {
  COMPANY_MANAGEMENT_MODULE,
  GET_CATEGORIES,
} from "@/store/CompanyManagement/types";
import { HivebuyLoader } from "@/components/shared/index";
export default {
  name: "InlineCategorySelect",
  components: {
    HivebuyInlineSelect,
    HivebuyLoader,
    TagIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "categoryTreeWithChildren",
      "categoriesList",
      "categoryDetails",
    ]),
    dropdownValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.editMode = false;
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    dropdownOptions() {
      return this.categoryTreeWithChildren();
    },
  },
  mounted() {
    if (this.categoriesList.length || this.isApiLoading("categories")) return;

    this.setApiLoading({ key: "categories", value: true });
    this.getCategories()
      .catch(() => {
        this.showErrorMessage();
      })
      .finally(() => {
        this.setApiLoading({ key: "categories", value: false });
      });
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCategories: GET_CATEGORIES,
    }),
  },
};
</script>
<style>
.p-tree {
  @apply border-none !important;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:focus {
  @apply shadow-none transition-all !important;
}
</style>
