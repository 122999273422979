import * as CompanyManagementTypes from "./types.js";
import { removeKeysFromObject, updateOrCreate } from "@/utils/utility_methods";

export default {
  [CompanyManagementTypes.SET_EXCHANGE_RATE]: (state, payload) => {
    payload.forEach((currency) => {
      const key = Object.keys(currency)[0];
      state.exchangeRates[key] = currency[key];
    });
  },
  [CompanyManagementTypes.SET_COMPANY]: (state, payload) => {
    state.company = payload;
  },

  [CompanyManagementTypes.SET_DEPARTMENTS]: (state, payload) => {
    const { results, ...paginationObj } = payload;
    const { currentPage, ...paginationParams } = paginationObj;

    state.departmentPaginationParams = Object.assign(
      {},
      state.departmentPaginationParams,
      paginationParams,
      { page: currentPage }
    );
    if (!results) return;

    results.forEach(
      (dept) =>
        (state.departments = {
          ...state.departments,
          [dept.id]: Object.assign({}, dept),
        })
    );
  },

  [CompanyManagementTypes.SET_DEPARTMENT]: (state, payload) => {
    state.departments[payload.id] = payload;
  },

  [CompanyManagementTypes.REMOVE_DEPARTMENT]: (state, categoryId) => {
    delete state.departments[categoryId];
  },

  [CompanyManagementTypes.SET_PAYMENT_METHOD]: (state, payload) => {
    state.paymentMethods[payload.id] = payload;
  },

  [CompanyManagementTypes.SET_PAYMENT_METHODS]: (state, payload) => {
    if (!payload?.length) return;
    const paymentMethods = {};
    payload.forEach((pm) => (paymentMethods[pm.id] = pm));
    state.paymentMethods = paymentMethods;
  },

  [CompanyManagementTypes.SET_COMPANY_ADDRESSES]: (state, payload) => {
    state.companyAddresses = payload;
  },

  [CompanyManagementTypes.SET_CATEGORIES]: (state, payload) => {
    if (!payload) return;
    payload.forEach((category) => {
      state.categories[category.id] = category;
    });
  },
  [CompanyManagementTypes.DELETE_CATEGORY]: (state, categoryId) => {
    delete state.categories[categoryId];
  },

  [CompanyManagementTypes.SET_CATEGORY]: (state, payload) => {
    state.categories[payload.id] = payload;
  },

  [CompanyManagementTypes.SET_USERS]: (state, payload) => {
    if (!payload) return;
    payload.forEach((user) => (state.users[user.id] = user));
  },
  [CompanyManagementTypes.SET_USER]: (state, payload) => {
    if (!payload) return;
    state.users[payload.id] = payload;
  },

  [CompanyManagementTypes.SET_QUICK_LINKS]: (state, payload) => {
    state.quickLinks = payload;
  },

  [CompanyManagementTypes.SET_PENDING_USERS]: (state, payload) => {
    state.pendingUsers = payload;
  },

  [CompanyManagementTypes.SET_PENDING_USER]: (state, payload) => {
    state.pendingUsers = updateOrCreate(state.pendingUsers, payload);
  },

  [CompanyManagementTypes.SET_COMPANY_INTEGRATED_SUPPLIERS]: (
    state,
    payload
  ) => {
    if (!payload?.length) return;
    const suppliers = {};
    payload.forEach((supplier) => (suppliers[supplier.id] = supplier));
    state.companyIntegratedSuppliers = suppliers;
  },

  [CompanyManagementTypes.SET_COMPANY_SUPPLIERS]: (state, payload) => {
    if (!payload) return;
    if (payload.length && !payload.results) {
      payload.forEach(
        (companySupplier) =>
          (state.companySuppliers = {
            ...state.companySuppliers,
            [companySupplier.id]: Object.assign({}, companySupplier),
          })
      );
      return;
    }
    const { results, ...paginationObj } = payload;
    const { currentPage, ...paginationParams } = paginationObj;
    state.companySuppliersListFilters = Object.assign(
      {},
      state.companySuppliersListFilters,
      paginationParams,
      { page: currentPage }
    );
    if (!results) return;
    results.forEach(
      (companySupplier) =>
        (state.companySuppliers = {
          ...state.companySuppliers,
          [companySupplier.id]: Object.assign({}, companySupplier),
        })
    );
  },

  [CompanyManagementTypes.SET_INTEGRATED_SUPPLIERS]: (state, payload) => {
    if (payload) {
      state.integratedSuppliers = payload;
    }
  },

  [CompanyManagementTypes.SET_COMPANY_SUPPLIER]: (state, payload) => {
    if (payload.supplier) {
      state.companyIntegratedSuppliers[payload.id] = payload;
    } else {
      state.companySuppliers[payload.id] = payload;
    }
  },

  [CompanyManagementTypes.SET_SUPPLIER_CREDENTIALS]: (state, payload) => {
    state.companyIntegratedSuppliers[payload.id] = Object.assign(
      {},
      state.companyIntegratedSuppliers[payload.id],
      removeKeysFromObject(payload, ["supplier"])
    );
  },

  [CompanyManagementTypes.REMOVE_SUPPLIER]: (state, payload) => {
    if (payload.supplier) {
      delete state.companyIntegratedSuppliers[payload.id];
    } else {
      delete state.companySuppliers[payload.id];
    }
  },

  [CompanyManagementTypes.SET_COMPANY_SUPPLIERS_LIST_FILTERS]: (
    state,
    payload
  ) => {
    state.companySuppliersListFilters = Object.assign(
      {},
      state.companySuppliersListFilters,
      payload
    );
  },

  [CompanyManagementTypes.SET_PUR_WORKFLOWS]: (state, payload) => {
    state.purWorkflows = payload;
  },

  [CompanyManagementTypes.SET_INVOICE_WORKFLOWS]: (state, payload) => {
    state.invoiceWorkflows = payload;
  },

  [CompanyManagementTypes.SET_WORKFLOW]: (state, payload) => {
    if (payload) {
      if (payload.flowType == "invoice_purchase_order") {
        state.invoiceWorkflows = updateOrCreate(
          state.invoiceWorkflows,
          payload
        );
      } else {
        state.purWorkflows = updateOrCreate(state.purWorkflows, payload);
      }
    }
  },

  [CompanyManagementTypes.SET_BUDGETS]: (state, payload) => {
    let keyToUpdate = "";
    if (payload.type) {
      if (payload.type === "D") {
        keyToUpdate = "departmentBudgets";
      } else if (payload.type === "C") {
        keyToUpdate = "companyBudgets";
      } else {
        keyToUpdate = "userBudgets";
      }
      state.budgets = {
        ...state.budgets,
        ...{ [keyToUpdate]: payload.payload },
      };
    } else {
      state.budgets = payload;
    }
  },

  [CompanyManagementTypes.SET_COMPANY_ADDRESS]: (state, payload) => {
    const { companyAddresses } = state;
    const companyAddress = companyAddresses.some(({ id }) => id == payload.id);
    if (!companyAddress) state.companyAddresses.push(payload);
  },

  [CompanyManagementTypes.SET_USER_STATUS]: (state, payload) => {
    const { userId } = payload;
    state.users[userId].isActive = payload.status.is_active;
  },

  [CompanyManagementTypes.SET_GLOBAL_POLICIES]: (state, payload) => {
    state.globalPolicies = payload;
  },
  [CompanyManagementTypes.SET_GLOBAL_POLICY]: (state, payload) => {
    const index = state.globalPolicies.findIndex(
      (globalPolicy) => globalPolicy.id == payload.id
    );
    state.globalPolicies[index] = payload;
  },

  [CompanyManagementTypes.NULLIFY_MEMBER_EXPIRED_AVATAR]: (state, { id }) => {
    state.users[id].avatar = null;
  },
  [CompanyManagementTypes.LIVE_UPDATE_DATA]: (
    state,
    { object, activity, storeState }
  ) => {
    const objKeys = Object.keys(object);
    const updatedObj = objKeys.includes("id") ? object : object[objKeys[0]];
    let index = null;
    switch (activity) {
      case "updated":
        if (Array.isArray(state[storeState])) {
          index = state[storeState].findIndex(
            (item) => item.id == updatedObj.id
          );
          state[storeState][index] = updatedObj;
        } else state[storeState][updatedObj.id] = updatedObj;
        break;
      case "created":
        if (Array.isArray(state[storeState])) {
          state[storeState] = [...state[storeState], updatedObj];
        } else {
          state[storeState][updatedObj.id] = updatedObj;
        }
        break;
    }
  },
  [CompanyManagementTypes.UPDATE_COMPANY_SETTING]: (state, payload) => {
    const index = state.company.companySettings.findIndex(
      (setting) => setting.type === payload.object.companySetting.type
    );
    state.company.companySettings[index] = payload.object;
  },

  [CompanyManagementTypes.SET_DATEV_INTEGRATION]: (state, payload) => {
    const index = state.integrations.findIndex(
      (integration) => integration.name === "Datev"
    );

    if (payload.clients) {
      state.integrations[index] = Object.assign(
        {},
        state.integrations[index],
        payload
      );
    } else if (payload.id) {
      const clientIndex = state.integrations[index].clients.findIndex(
        (client) => client.id === payload.id
      );
      if (index >= 0) {
        state.integrations[index].clients[clientIndex] = payload;
      }
    }
  },
  [CompanyManagementTypes.SET_COST_SAVING]: (state, payload) => {
    state.costSaving = payload[payload.length - 1];
    const { costAvoidanceGoal, costSavingGoal, id } = state.costSaving;
    state.costSavingGoals = {
      costAvoidanceGoal,
      costSavingGoal,
      currency: state.company.currency,
      id,
    };
  },

  [CompanyManagementTypes.SET_COST_SAVING_GOALS]: (state, payload) => {
    const { costAvoidanceGoal, costSavingGoal } = payload;
    state.costSavingGoals.costAvoidanceGoal = costAvoidanceGoal;
    state.costSavingGoals.costSavingGoal = costSavingGoal;
    state.costSavingGoals.currency = state.company.currency;
  },

  [CompanyManagementTypes.SET_LEGAL_TENDENCIES]: (state, payload) => {
    const legalTendencies = {};
    payload.forEach(
      (legalTendency) => (legalTendencies[legalTendency.id] = legalTendency)
    );
    state.legalTendencies = legalTendencies;
  },
  [CompanyManagementTypes.SET_LEGAL_TENDENCY]: (state, payload) => {
    state.legalTendencies[payload.id] = payload;
  },
  [CompanyManagementTypes.REMOVE_LEGAL_TENDENCY]: (state, legalTendencyId) => {
    delete state.legalTendencies[legalTendencyId];
  },

  [CompanyManagementTypes.SET_INTEGRATIONS]: (state, payload) => {
    state.integrations = payload;
  },

  [CompanyManagementTypes.SET_INTEGRATIONS_SLUGS]: (state, payload) => {
    state.integrationsSlugs = payload;
  },

  [CompanyManagementTypes.SET_INTEGRATION]: (state, payload) => {
    const index = state.integrations.findIndex(
      (integration) => payload.id === integration.id
    );
    state.integrations[index] = payload;
  },

  [CompanyManagementTypes.SET_API_KEYS]: (state, payload) => {
    payload.forEach((apiKey) => {
      state.apiKeys[apiKey.id] = apiKey;
    });
  },

  [CompanyManagementTypes.SET_CUSTOM_FIELDS]: (state, payload) => {
    state.customFields = payload;
  },
};
