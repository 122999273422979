import { getItemFromLocalStorage } from "@/utils/utility_methods";
const ALL_ROLES = ["BU", "MD", "AC", "O", "AP", "CO", "CR", "NA", "AD"];
const ADMIN_ROLES = ["MD", "O"];
const permissions = {
  "not-allowed": ALL_ROLES,
  home: ALL_ROLES,
  dashboard: ALL_ROLES,
  "user-info": ALL_ROLES,
  "purchase-request": ALL_ROLES,
  profile: ALL_ROLES,
  "edit-profile": ALL_ROLES,
  requests: ALL_ROLES,
  "new-request": ALL_ROLES,
  "company-management": ["BU", "CR", "AD", ...ADMIN_ROLES],
  "company-profile": ["O"],
  users: ["BU", "AD", ...ADMIN_ROLES],
  departments: ["AD", ...ADMIN_ROLES],
  budgets: ["CR", ...ADMIN_ROLES],
  categories: ["BU", "CR", "AD", ...ADMIN_ROLES],
  "company-suppliers": ["BU", "AD", ...ADMIN_ROLES],
  integrations: ["O"],
  webhooks: ["AD", ...ADMIN_ROLES],
  "approval-workflow": ["O", "AD"],
  "approval-workflow-editor": ["O"],
  analytics: ["AP", "AC", "CR", "BU", ...ADMIN_ROLES],
  "analytics-departments": ["AP", "CR", "AC", ...ADMIN_ROLES],
  "analytics-basic-overview": ["AC", "BU", ...ADMIN_ROLES],
  orders: ["AC", "O", "AD"],
  downloads: ["O", "MD", "BU"],
  catalogue: ALL_ROLES,
  proposals: ALL_ROLES,
  suppliers: ALL_ROLES,
  "connected-suppliers": ["BU", "AD", ...ADMIN_ROLES],
  "invoice-approval": ["AC", "O"],
  projects: ["AC", "BU", "CR", "O", "AD"],
  "project-view": ["AC", "BU", "AD", ...ADMIN_ROLES],
  contracts: ["O", "CO", "AD"],
  "legal-tendencies": ["CR", "AD", ...ADMIN_ROLES],
  "something-went-wrong": ALL_ROLES,
  "api-keys": ALL_ROLES,
  "order-list": ["BU", "CR", "AD", ...ADMIN_ROLES],
  news: ["O", "MD", "NA"],
};
const basicRoutes = [
  "notifications",
  "not-allowed",
  "home",
  "dashboard",
  "user-info",
  "purchase-request",
  "profile",
  "edit-profile",
  "requests",
  "new-request",
  "catalogue",
  "proposals",
  "connected-suppliers",
  "shopping-list",
  "premium-feature",
  "self-signup",
  "invoice-decide",
  "request",
  "",
];

export const allowedRoles = (path) => {
  return permissions[path];
};

export const allowedForUser = (path) => {
  if (skipValidation(path)) return true;
  const userRoles = getItemFromLocalStorage("user")
    ? getItemFromLocalStorage("user").roles
    : [];
  const allowedRoles = permissions[path];
  return userRoles?.some((role) => allowedRoles.includes(role)) || false;
};

export const skipValidation = (path) => {
  const pathWithoutQueryParams = path.includes("?") ? path.split("?")[0] : path;
  return basicRoutes.includes(pathWithoutQueryParams);
};
