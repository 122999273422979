<template>
  <Modal
    :size-classes="['sm:max-w-[650px]']"
    :show-modal="showModal"
    :close-modal="onModalClose"
    :show-icon="false"
  >
    <template #header>
      {{ $t("catalogue.catalogueItems.requestedForModal.requestedFor") }}

      <p class="text-sm text-gray-500 mb-4">
        {{ $t("catalogue.catalogueItems.requestedForModal.requestedForText") }}
      </p>
    </template>
    <template #body>
      <SelectRequestedFor
        v-model="requestedFor"
        :is-in-modal="true"
        :has-errors="v$.requestedFor.$error"
        :validation-errors="v$.requestedFor.$errors"
        is-mandatory
      />
    </template>
    <template #footer>
      <Button :on-click-handler="onModalClose" :color="'gray'">
        {{ $t("catalogue.catalogueItems.requestedForModal.cancel") }}
      </Button>
      <Button :on-click-handler="onSave" :disabled="disableSaveBtn">{{
        $t("catalogue.catalogueItems.requestedForModal.continue")
      }}</Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal } from "@/components/shared/index";
import { mapMutations } from "vuex";
import { required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { SelectRequestedFor } from "@/components/shared";
import {
  PURCHASE_REQUEST_MODULE,
  SET_CURRENT_REQUEST_CATALOG_DATA,
} from "@/store/PurchaseRequest/types";
import { requestedForValidation } from "@/utils/helpers/purchaseRequestHelper";

export default {
  components: {
    Modal,
    Button,
    SelectRequestedFor,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    onSaveRequestedFor: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      disableSaveBtn: false,
      requestedFor: null,
    };
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setCatalogueRequest: SET_CURRENT_REQUEST_CATALOG_DATA,
    }),
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disableSaveBtn = true;
        this.onSaveRequestedFor({ requestedFor: this.requestedFor });
        this.onModalClose();
      }
    },
    onModalClose() {
      if (this.disableSaveBtn) {
        this.disableSaveBtn = false;
      }
      this.resetValues();
      this.closeModal();
    },
    resetValues() {
      setTimeout(() => {
        this.requestedFor = null;
        this.department = {};
        this.requestedForOption = "department";
        this.v$.$reset();
      }, 500);
    },
  },
  validations() {
    const validation = { requestedFor: { required } };

    if (this.requestedFor) {
      validation.requestedFor = requestedForValidation(
        validation.requestedFor,
        this.requestedFor
      );
    }
    return validation;
  },
};
</script>
