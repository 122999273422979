export default {
  methods: {
    requestQueryParams() {
      const params = Object.assign({}, this.paginationParams);

      if (this.advancedFilters.customFields.length) {
        const updatedCustomFields = this.advancedFilters.customFields.filter(
          (filter) => !!filter?.value
        );
        params.customFields = updatedCustomFields
          .map((filter) => filter.name)
          .join(",");
        params.customFieldsValues = updatedCustomFields
          .map((filter) => filter.value)
          .join(",");
      }

      if (this.areFiltersUpdated) {
        params.page = 1;
      }

      return params;
    },
    getCatalogueListItems(loadMore, loadingState) {
      if (!loadMore) {
        this.setApiLoading({ key: loadingState, value: true });
      }
      const paginationParams = {
        queryParams: this.requestQueryParams(),
        areFiltersUpdated: this.areFiltersUpdated,
      };

      this.getCatalogueItems(paginationParams)
        .then(() => {
          this.areFiltersUpdated = false;
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          if (loadMore) this.loadMoreBtnLoading = false;
          this.setApiLoading({ key: loadingState, value: false });
        });
    },
    updateCustomFieldFilters(filters) {
      this.updateFilters("customFields", filters, "advancedFilters");
    },
    updateListFilters(filterState, mutation, key, value, parentKey) {
      const payload = this.createFiltersPayload(
        filterState,
        key,
        value,
        parentKey
      );

      mutation(payload);

      if (key != "paginationParams") {
        if (key === "page") {
          this.fetchCatalogueItems(true);
        } else {
          this.areFiltersUpdated = true;
          this.fetchCatalogueItems();
        }
      }
    },
  },
};
