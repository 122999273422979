<template>
  <Condition :condition-text="approvalWorkflowTitle">
    <div class="!mt-4 flex justify-between items-start">
      <p class="text-gray-400 pr-8">
        {{ $t("approvalWorkflow.globalPolicies.noSupplier.description") }}
      </p>
      <SwitchCheckbox
        v-model="isActive"
        :label="$t('approvalWorkflow.globalPolicies.active')"
        class="checkbox"
        :read-only="isUserOnlyAuditor"
        :on-click-handler="onSave"
      />
    </div>
    <div class="flex justify-between">
      <div class="mt-4 w-400">
        <Multiselect
          v-model="role"
          :disabled="loading || isUserOnlyAuditor"
          :mode="'single'"
          :searchable="true"
          :options="supplierSelectorOptions"
          :placeholder="
            $t('approvalWorkflow.globalPolicies.noSupplier.roleFieldError')
          "
          :label="'label'"
          :can-deselect="false"
        />
        <FieldError
          v-if="v$.role.$error"
          :text="
            $t('approvalWorkflow.globalPolicies.noSupplier.roleFieldError')
          "
        />
      </div>
      <div class="flex justify-end mt-4">
        <Button
          :on-click-handler="onSave"
          :loading="loading"
          :disabled="isUserOnlyAuditor"
        >
          {{ $t("approvalWorkflow.globalPolicies.saveBtn") }}
        </Button>
      </div>
    </div>
  </Condition>
</template>

<script>
import { required } from "@vuelidate/validators";
import { WORKFLOW_ROLES_MAPPING } from "@/utils/constants";
import Multiselect from "@vueform/multiselect";
import { globalPolicyMixin } from "@/components/mixins/index.js";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";

export default {
  components: {
    Multiselect,
    SwitchCheckbox,
  },
  mixins: [globalPolicyMixin],
  data() {
    return {
      role: "",
      loading: false,
      isActive: false,
    };
  },
  computed: {
    supplierSelectorOptions() {
      return Object.entries(WORKFLOW_ROLES_MAPPING).map(([key, value]) => {
        return { label: value, value: key };
      });
    },
  },
  created() {
    if (this.approvalWorkflow.roleForResult) {
      this.role = this.approvalWorkflow.roleForResult;
      this.isActive = this.approvalWorkflow.isActive;
    }
  },
  methods: {
    onSave() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = "true";
        this.updateGlobalPolicy({
          conditionId: this.approvalWorkflow.id,
          payload: { roleForResult: this.role, isActive: this.isActive },
        })
          .then(() => {
            this.showNotification(
              this.$t("approvalWorkflow.globalPolicies.updateSuccessText")
            );
          })
          .catch((error) => this.showErrorMessage(error))
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  validations() {
    return { role: { required } };
  },
};
</script>

<style scoped>
.w-400 {
  width: 400px;
}
</style>
