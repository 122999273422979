<template>
  <div class="flex items-center gap-x-1">
    <div v-for="(sourceMapping, index) in sourceMappings" :key="index">
      <Tip :disabled="hideTooltip">
        <template #placeholder>
          <div
            class="flex items-center justify-center rounded-full px-3 whitespace-nowrap py-1"
            :class="sourceMapping?.colorClass"
          >
            <component :is="sourceMapping.icon" class="h-4 w-4" />
          </div>
        </template>
        <template #content>
          <div class="text-sm font-normal">
            <h3 class="text-hivebuy_plum">{{ sourceMapping.text }}</h3>
            <p class="text-gray-500">{{ sourceMapping.details }}</p>
          </div>
        </template>
      </Tip>
    </div>
  </div>
</template>
<script>
import { ShoppingCartIcon } from "@heroicons/vue/outline";

import DeliverSVG from "@/assets/images/DeliverSVG";
import { AUTH_MODULE } from "@/store/Auth/types";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import { mapGetters } from "vuex";

export default {
  components: {
    ShoppingCartIcon,
    DeliverSVG,
  },
  props: {
    list: { type: Array, default: () => [] },
    classes: { type: String, default: "" },
    hideTooltip: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["requestedForType"]),
    sourceMappings() {
      const uniqueItems = [...new Set(this.list)];

      return uniqueItems.map((type) => {
        const sourceType = this.requestedForType(type) || "freeForm";
        return this.typeMapping(sourceType);
      });
    },
  },
  methods: {
    typeMapping(type) {
      return {
        [CATALOGUE_ITEM_SOURCE.SUPPLIER]: {
          icon: DeliverSVG,
          colorClass: "bg-teal-600 text-white",
          text: this.$t("requestCreation.integratedSupplier.text"),
          details: this.$t("requestCreation.integratedSupplier.details"),
        },
        [CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM]: {
          icon: ShoppingCartIcon,
          colorClass: "bg-hivebuy-yellow text-gray-600",
          text: this.$t("requestCreation.catalogue.text"),
          details: this.$t("requestCreation.catalogue.details"),
        },
        freeForm: {
          icon: ShoppingCartIcon,
          colorClass: "bg-primary text-white",
          text: this.$t("requestCreation.freeForm.text"),
          details: this.$t("requestCreation.freeForm.details"),
        },
      }[type];
    },
  },
};
</script>
