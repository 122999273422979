<template>
  <Condition :condition-text="approvalWorkflowTitle">
    <div class="!my-4 flex justify-between items-start">
      <p class="text-gray-400 pr-8">
        {{ $t("approvalWorkflow.globalPolicies.approvalAmount.description") }}
      </p>

      <SwitchCheckbox
        v-model="isActive"
        :label="$t('approvalWorkflow.globalPolicies.active')"
        class="checkbox"
        :read-only="isUserOnlyAuditor"
        :on-click-handler="onSave"
      />
    </div>
    <div class="flex justify-between gap-x-2 sm:gap-x-4 md:gap-x-6">
      <div class="flex sm:space-x-4 flex-col sm:flex-row w-800">
        <div class="global-policy-approver-amount-wrapper flex-1">
          <AddApprover
            v-if="!isUserOnlyAuditor"
            ref="addApprover"
            :approval-workflow="approvalWorkflow"
          />
        </div>
        <div class="flex-1">
          <CurrencyInput
            id="approval-amount-currency-input"
            v-model.lazy="amount"
            :disabled="loading || isUserOnlyAuditor"
            :label="$t('approvalWorkflow.globalPolicies.approvalAmount.amount')"
            :error-text="error(v$.amount)"
            :placeholder="
              $t('approvalWorkflow.globalPolicies.approvalAmount.amount')
            "
            is-mandatory
          />
        </div>
      </div>
      <div class="place-self-center pl-1">
        <div class="flex justify-end mt-4">
          <Button
            :on-click-handler="onSave"
            :loading="loading"
            :disabled="isUserOnlyAuditor"
          >
            {{ $t("approvalWorkflow.globalPolicies.saveBtn") }}
          </Button>
        </div>
      </div>
    </div>
  </Condition>
</template>

<script>
import { required } from "@vuelidate/validators";
import errorMixin from "@/components/mixins/errorMixin";
import { CurrencyInput } from "@/components/shared/index";
import addApproverMixin from "@/components/mixins/addApproverMixin";

export default {
  components: {
    CurrencyInput,
  },
  mixins: [errorMixin, addApproverMixin],
  data() {
    return {
      amount: this.approvalWorkflow.amount,
      loading: false,
    };
  },
  methods: {
    onAmountChange(event) {
      this.amount = event.target.value;
    },
    onSave() {
      this.$refs.addApprover.v$.$validate();
      this.v$.$validate();
      if (!this.$refs?.addApprover?.v$?.$error && !this.v$?.$error) {
        const payload = this.$refs.addApprover.requestPayload();
        (this.loading = true),
          this.updateGlobalPolicy({
            conditionId: this.approvalWorkflow.id,
            payload: this.formattedRequestPayload(payload),
          })
            .then(() => {
              this.showNotification(
                this.$t("approvalWorkflow.globalPolicies.updateSuccessText")
              );
            })
            .catch((error) => this.showErrorMessage(error))
            .finally(() => {
              this.loading = false;
            });
      }
    },
    formattedRequestPayload(payload) {
      const basicPayload = {
        ...this.workflowDefaultValues(),
        amount: this.amount,
      };
      return Object.assign({}, basicPayload, payload);
    },
  },
  validations() {
    return {
      amount: { required },
    };
  },
};
</script>

<style>
.global-policy-approver-amount-wrapper .btn {
  @apply mt-6;
}

.global-policy-approver-amount-wrapper .user-row {
  @apply -translate-y-3 mb-0;
}

.global-policy-approver-amount-wrapper .user-row > div {
  @apply mb-0;
}

.global-policy-approver-amount-wrapper .user-row span {
  @apply font-normal text-gray-500 text-sm;
}

.global-policy-approver-amount-wrapper .user-row .grow-0 {
  @apply -translate-y-1;
}

.global-policy-approver-amount-wrapper .user-row .workflowItem {
  @apply rounded;
}

.w-800 {
  width: 800px;
}
</style>
