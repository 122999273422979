<template>
  <div class="z-0 inline-flex shadow-sm w-full">
    <Dialog
      v-model:visible="visible"
      :modal="true"
      :draggable="false"
      :style="{ width: '90rem' }"
      :pt="{
        root: 'border-none',
        mask: {
          style: 'backdrop-filter: blur(2px) brightness(0.5)',
        },
      }"
    >
      <template #header>
        <div
          class="inline-flex align-items-center justify-content-center gap-2"
        >
          <div class="page-heading">
            <h1 class="text-primary">
              {{ $t("newRequest.heading") }}
            </h1>
            <p>
              {{ $t("newRequest.subHeading") }}
            </p>
          </div>
        </div>
      </template>
      <NewRequest :close-action="() => (visible = false)" />
    </Dialog>
    <ConfirmDialog
      :text="$t('general.newRequestButton.startFreshText')"
      :show-modal="showResetModal"
      :close-modal="toggleReset"
      :title="$t('general.newRequestButton.startFreshTitle')"
      :confirm-modal="onReset"
    />
    <Button
      :full="true"
      class="truncate btn-request"
      :on-click-handler="firstItem.action"
    >
      <span class="button-icon">
        <component :is="firstItem.icon" />
      </span>
      <div class="button-label">
        {{ firstItem.name }}
      </div>
    </Button>
    <Menu
      v-if="requestInProgress && !isCreationRoute"
      as="span"
      class="mt-2 relative block z-50 mr-4 -ml-2"
    >
      <MenuButton
        class="relative items-center py-2 px-1 !rounded-r !bg-white text-primary hover:text-primarydark transition duration-500 ease-in-out dropdown-icon"
      >
        <span class="sr-only">More</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 mt-2 w-40 rounded-md shadow-lg !bg-white focus:outline-none"
        >
          <div class="py-1">
            <MenuItem
              v-for="item in items"
              :key="item.name"
              v-slot="{ active }"
            >
              <span
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm cursor-pointer',
                ]"
                @click="item.action"
              >
                {{ item.name }}
              </span>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
<script>
import { ShoppingCartIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import PurchaseRequestPlusSVG from "@/assets/images/PurchaseRequestPlusSVG.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import newRequestMixin from "@/components/mixins/newRequestMixin.js";
import { Button } from "@/components/shared";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ShoppingCartIcon,
    PurchaseRequestPlusSVG,
    ConfirmDialog,

    Button,
  },
  mixins: [newRequestMixin],
  inject: ["toggleCreateRequest"],

  data() {
    return {
      showResetModal: false,
      isCreationRoute: false,
    };
  },
  computed: {
    firstItem() {
      return this.items[0];
    },
    items() {
      let list = [];
      if (!this.isCreationRoute) {
        const buttonText = this.requestInProgress ? "continue" : "newRequest";
        list = [
          {
            name: this.$t(`general.newRequestButton.${buttonText}`),
            startOver: false,
            icon: "ShoppingCartIcon",
            action: this.onClickHandler,
          },
        ];
      }
      list.push({
        name: this.$t("general.newRequestButton.startFresh"),
        startOver: true,
        icon: "PurchaseRequestPlusSVG",
        action: this.toggleReset,
      });
      return list;
    },
  },
  watch: {
    $route(to) {
      this.isCreationRoute = to.name == "CreatePurchaseRequest";
    },
  },

  methods: {
    onResetClose() {
      this.showResetModal = false;
    },
    toggleReset() {
      this.showResetModal = !this.showResetModal;
    },
    onClickHandler() {
      if (this.requestInProgress) {
        this.$router.push({ name: "CreatePurchaseRequest" });
      } else {
        this.toggleCreateRequest();
      }
    },

    onReset() {
      this.saveRequestAttributes({
        action: "reset",
      });
      this.showResetModal = false;
      this.toggleCreateRequest();
    },
  },
};
</script>

<style scoped>
.btn-primary {
  @apply bg-white hover:text-primarydark text-primary tracking-widest  font-semibold my-2 mx-2;
  height: 44px;
}

.btn-primary-outline {
  @apply bg-white border-teal-500 hover:bg-teal-500 text-teal-500 hover:text-white;
}

.dropdown-icon {
  height: 44px;
  margin-left: -6px;
  border-radius: 0;
}

.button-icon {
  @apply h-5 w-5 mx-3 shrink-0 transition duration-500;
}

.button-label {
  @apply tracking-normal transition duration-500 overflow-hidden truncate;
}

.btn-request {
  @apply mb-4 mx-4 rounded hover:bg-primarydark hover:text-white;
}
</style>
