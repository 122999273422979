import { mapState } from "vuex";
import CatalogueItemImage from "@/assets/images/catalogue-item-image-placeholder.png";
import { CATALOGUE_MODULE } from "@/store/Catalogue/types";

export default {
  computed: {
    ...mapState(CATALOGUE_MODULE, ["catalogueItems", "adminCatalogueItems"]),
    areCatalogueItemsPresent() {
      return this.catalogueItems?.length;
    },
    areAdminCatalogueItemsPresent() {
      return this.adminCatalogueItems?.length;
    },
  },
  methods: {
    itemImage(catalogueItem) {
      if (catalogueItem?.images?.length) return catalogueItem.images[0].image;

      if (catalogueItem.source?.object?.images?.length)
        return catalogueItem.source?.object?.images[0].image;

      return CatalogueItemImage;
    },
    itemImages(catalogueItem) {
      if (catalogueItem?.images?.length) return catalogueItem.images;

      return [{ image: CatalogueItemImage }];
    },
    fileName(file) {
      return file.split("/").pop().split("?").shift();
    },
  },
};
