<template>
  <div
    class="relative"
    :class="[
      'file-input-class',
      ...footerClasses,
      areFilesInList
        ? 'border-1 border-hivebuy-green'
        : 'border-dashed hover:border-primary border-gray-300 group',
    ]"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div
      v-if="loading"
      class="absolute inset-0 bg-gray-500/10 rounded flex items-center justify-center backdrop-blur-sm"
    >
      <div class="w-12 h-12">
        <HivebuyLoader />
      </div>
    </div>
    <input
      :id="`assetsFieldHandle-${type}`"
      :key="type"
      ref="file"
      type="file"
      multiple
      name="fields[assetsFieldHandle][]"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      :accept="acceptFileTypes"
      @change="onChange"
    />

    <label
      :for="`assetsFieldHandle-${type}`"
      class="cursor-pointer flex flex-col text-center items-center text-gray-400 font-light"
    >
      <div
        v-if="!areFilesInList"
        class="text-center items-center flex flex-col"
      >
        <DocumentAddIcon class="h-6 w-6 group-hover:text-primary" />
        <div class="mt-2 text-sm">
          {{ $t("general.dragNDrop") }}
          <p class="underline">{{ $t("general.clickHere") }}</p>
        </div>
      </div>
    </label>
    <div v-if="showPaste && !areFilesInList" class="flex mt-2 relative">
      <textarea
        id="pasteArea"
        ref="pasteArea"
        rows="1"
        :placeholder="$t('general.pasteImage')"
        class="pt-8 text-center border-gray-300 bg-gray-50 text-gray-300 rounded-md resize-none w-full h-full focus:bg-green-50 focus:ring-0 focus:border-hivebuy-green text-sm"
        @paste="pasteFunction"
      />
      <div class="absolute inset-x-0 top-1 flex items-center justify-center">
        <ClipboardCopyIcon class="h-5 w-5" />
      </div>
    </div>

    <ul v-if="areFilesInList" v-cloak class="py-2">
      <div
        class="grid gap-2"
        :class="
          filelist.length > 1 && !small ? 'md:grid-cols-2 lg:grid-cols-3' : ''
        "
      >
        <div
          v-for="(file, index) in filelist"
          :key="index"
          class="text-left flex items-center gap-x-2 border border-gray-400 px-4 py-1 rounded border-dashed hover:bg-gray-100 bg-white group justify-between"
          :class="small ? 'max-w-[220px]' : 'max-w-[350px]'"
        >
          <div class="flex items-center gap-x-2">
            <DocumentTextIcon
              v-if="!checkIfImage(file)"
              class="h-6 w-6 text-gray-300 group-hover:text-gray-400"
            />
            <CameraIcon
              v-else
              class="h-6 w-6 text-gray-300 group-hover:text-gray-400"
            />
            <div>
              <p
                :class="small ? 'max-w-[135px]' : 'max-w-[260px]'"
                class="text-sm font-medium group-hover:text-primary truncate"
              >
                {{ file?.title || file.name }}
              </p>
              <p v-if="!small && file.size" class="text-xs">
                {{ formatBytes(file.size) }}
              </p>
            </div>
          </div>
          <TrashIcon
            class="h-5 w-5 flex-shrink-0 hover:!text-hivebuy-red cursor-pointer text-gray-400 group-hover:text-hivebuy-red/60"
            @click="remove(filelist.indexOf(file))"
          />
        </div>
      </div>
      <div
        v-if="showFileActions"
        class="flex items-center gap-x-4 mt-4 justify-center"
      >
        <Button size-class="sm" color="gray" :on-click-handler="onCancel">
          <template #icon>
            <XIcon />
          </template>
          {{ $t("buttons.cancel") }}
        </Button>
        <Button size-class="sm" :on-click-handler="onUpload">
          <template #icon>
            <UploadIcon />
          </template>
          {{ $t("buttons.upload") }}
        </Button>
      </div>
    </ul>
  </div>
</template>
<script>
import {
  DocumentAddIcon,
  DocumentTextIcon,
  TrashIcon,
  ClipboardCopyIcon,
  XIcon,
  CameraIcon,
} from "@heroicons/vue/outline";
import { Button, HivebuyLoader } from "@/components/shared";
import {
  FILE_ACCEPT_TYPES,
  IMAGE_ACCEPT_TYPES,
  CSV_FILE_TYPES,
} from "@/utils/constants";
import { UploadIcon } from "@heroicons/vue/outline";

export default {
  components: {
    DocumentAddIcon,
    DocumentTextIcon,
    TrashIcon,
    Button,
    ClipboardCopyIcon,
    UploadIcon,
    XIcon,
    HivebuyLoader,
    CameraIcon,
  },

  props: {
    footerClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxFilesCount: {
      type: Number,
      default: 1,
    },
    files: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      default: "file",
    },
    showFileActions: {
      type: Boolean,
      default: false,
    },
    showPaste: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["listUpdate"],
  data() {
    return {
      filelist: [],
    };
  },
  computed: {
    acceptFileTypes() {
      if (this.type === "csv") {
        return CSV_FILE_TYPES;
      }
      return this.type === "file" ? FILE_ACCEPT_TYPES : IMAGE_ACCEPT_TYPES;
    },

    areFilesInList() {
      return this.filelist.length;
    },
  },
  watch: {
    files: {
      handler(newVal) {
        this.filelist = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChange() {
      const uploadedFiles = this.$refs.file.files;
      if (uploadedFiles.length > this.maxFilesCount) {
        this.showNotification(
          `Only ${this.maxFilesCount} file uploads are allowed`,
          "error"
        );
      } else {
        this.filelist.push(...uploadedFiles);
        this.$refs.file.value = "";
        !this.showFileActions && this.$emit("listUpdate", this.filelist);
      }
    },
    checkIfImage(file) {
      const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
      return file && acceptedImageTypes.includes(file["type"]);
    },
    pasteFunction(event) {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        this.addImage(items[i].getAsFile());
      }
    },
    async addImage(file) {
      this.filelist.push(file);
      this.$emit("listUpdate", this.filelist);
    },
    onUpload() {
      this.$emit("listUpdate", this.filelist);
      this.filelist = [];
    },
    onCancel() {
      this.filelist = [];
    },
    remove(index) {
      this.filelist.splice(index, 1);
      !this.showFileActions && this.$emit("listUpdate", this.filelist);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-100")) {
        event.currentTarget.classList.remove("bg-gray-50");
        event.currentTarget.classList.add("!bg-green-100");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-50");
      event.currentTarget.classList.remove("!bg-green-100");
    },
    drop(event) {
      event.preventDefault();
      const totalFilesCount =
        this.filelist.length + event.dataTransfer.files.length;
      if (totalFilesCount > this.maxFilesCount) {
        this.showNotification(
          this.$t("global.errors.maxFileUploads", {
            count: this.maxFilesCount,
          }),
          "error"
        );
        return;
      }
      this.filelist.push(...event.dataTransfer.files);
      this.onChange();
      event.currentTarget.classList.add("bg-gray-50");
      event.currentTarget.classList.remove("!bg-green-100");
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
  },
};
</script>

<style scoped>
.file-input-class {
  @apply flex flex-col px-4 py-3 border-2   rounded-md  items-center justify-center text-center bg-gray-50;
}
</style>
