<template>
  <div>
    <div v-if="!isApiLoading('purDetails')" class="grid grid-cols-12">
      <div class="col-span-12">
        <Summary
          :purchase-request="purchaseRequest"
          :fetch-purchase-request="fetchPurchaseRequest"
          :on-budget-update="onBudgetUpdate"
          :is-supplier-request="isSupplierRequest(purchaseRequest)"
          :on-duplicate="onDuplicate"
          :update-product="updateProduct"
          :editable="editableRequest"
        />
      </div>
      <div class="col-span-8">
        <div
          v-if="showDangerZone(purchaseRequest) && !isUserOnlyAuditor"
          class="col-span-12 py-4"
        >
          <div class="relative">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
              <span class="px-2 bg-gray-100 text-sm text-gray-500">
                {{ $t("purchaseRequest.detail.advancedSettings") }}
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="!isSupplierRequest(purchaseRequest) && !isUserOnlyAuditor"
          class="col-span-12 lg:col-span-8"
        >
          <DuplicateRequestSection
            ref="duplicateRequestRef"
            :purchase-request="purchaseRequest"
            :duplicate-request="duplicateRequest"
          />
        </div>
        <div v-if="false" class="col-span-12 lg:col-span-8">
          <DangerSection
            ref="deleteRequestRef"
            :purchase-request="purchaseRequest"
            :show-delete-section="false"
            :fetch-purchase-request="fetchPurchaseRequest"
          />
        </div>
        <div
          v-if="canUserResendWebhook && !isUserOnlyAuditor"
          class="col-span-12 lg:col-span-8"
        >
          <ResendWebhook
            ref="resendWebhookRef"
            :purchase-request="purchaseRequest"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <div class="w-20 h-20"><HivebuyLoader /></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import {
  GET_PURCHASE_REQUEST,
  PURCHASE_REQUEST_MODULE,
  UPDATE_PURCHASE_REQUEST,
  SET_OPEN_CURRENT_REQUEST,
  ADD_TO_RECENTLY_VIEWED,
} from "@/store/PurchaseRequest/types";
import Summary from "@/components/PurchaseRequest/Summary";
import DangerSection from "@/components/PurchaseRequest/SummarySections/dangerSection.vue";
import useValidate from "@vuelidate/core";
import { AUTH_MODULE } from "@/store/Auth/types";
import DuplicateRequestSection from "@/components/PurchaseRequest/SummarySections/DuplicateRequestSection";
import { HivebuyLoader } from "@/components/shared/index";
import {
  copyPurchaseRequestDataMixin,
  prPermissionMixin,
} from "@/components/mixins/index.js";
import ResendWebhook from "@/components/PurchaseRequest/SummarySections/ResendWebhook";
import { requestStatsMixin, newRequestMixin } from "@/components/mixins";
export default {
  components: {
    ResendWebhook,
    DuplicateRequestSection,
    Summary,
    DangerSection,
    HivebuyLoader,
  },
  mixins: [
    prPermissionMixin,
    copyPurchaseRequestDataMixin,
    requestStatsMixin,
    newRequestMixin,
  ],

  data() {
    return {
      v$: useValidate(),
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapGetters(PURCHASE_REQUEST_MODULE, ["requestDetails"]),
    prId() {
      return this.$route.params.id;
    },
    purchaseRequest() {
      return this.requestDetails(this.prId);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (!val.params.id) return;

        this.setOpenRequest(this.prId);
        this.setRecentlyViewed(this.prId);
        const purchaseRequest = this.requestDetails(this.prId);
        if (this.shouldFetchRequest(purchaseRequest)) {
          this.setApiLoading({ key: "purDetails", value: true });
          this.fetchPurchaseRequest()
            .catch(() => {
              this.$router.push({ name: "NotFound" });
            })
            .finally(() => {
              this.setApiLoading({ key: "purDetails", value: false });
            });
        }
      },
    },
  },
  beforeUnmount() {
    this.setOpenRequest(null);
  },

  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      setOpenRequest: SET_OPEN_CURRENT_REQUEST,
      setRecentlyViewed: ADD_TO_RECENTLY_VIEWED,
    }),
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      getPurchaseRequest: GET_PURCHASE_REQUEST,
      modifyPurchaseRequest: UPDATE_PURCHASE_REQUEST,
    }),
    shouldFetchRequest(purchaseRequest) {
      if (!purchaseRequest) return true;
      const hasApprovals = !!(
        purchaseRequest.approvals?.length ||
        purchaseRequest.items.some((item) => item?.approvals?.length)
      );
      if (!hasApprovals) return true;

      const isPurchaseOrder =
        purchaseRequest.status === "A" && purchaseRequest.purchaseOrder;
      if (isPurchaseOrder) {
        return !Object.keys(purchaseRequest.purchaseOrder).includes(
          "orderStatuses"
        );
      }
      return false;
    },
    updateProduct(details) {
      this.purchaseRequest = details;
    },
    duplicateRequest(isEdit = false) {
      this.saveRequestAttributes({
        action: "reset",
        object: this.duplicatePurchaseRequestPayload(isEdit),
      });

      this.$router.push({ name: "CreatePurchaseRequest" });
    },
    routeParams(freeForm, edit) {
      let params = {};
      if (edit) {
        params = {
          name: "PurchaseRequestEdit",
          params: { id: this.purchaseRequest.id },
        };
      } else {
        if (freeForm) {
          params = { name: "PurchaseRequest" };
        } else {
          params = { name: "Catalogue", query: { cart: true } };
        }
      }
      return params;
    },
    onDuplicate(edit = false) {
      this.$refs.duplicateRequestRef.toggleConfirmModal(edit);
    },
    onResendWebhook() {
      this.$refs.resendWebhookRef.toggleConfirmModal();
    },

    retryRequestFetching(retry = 0) {
      this.getPurchaseRequest(this.prId).then((data) => {
        if (!this.isApprovalAndBudgetPending(data) || retry > 3) return;

        setTimeout(() => {
          this.retryRequestFetching(retry + 1);
        }, 5000);
      });
    },

    fetchPurchaseRequest(showLoader = true) {
      if (showLoader) {
        this.setApiLoading({ key: "purDetails", value: true });
      }
      return this.getPurchaseRequest(this.prId)
        .then((response) => {
          if (this.isApprovalAndBudgetPending(response))
            this.retryRequestFetching();
        })
        .finally(() => {
          this.setApiLoading({ key: "purDetails", value: false });
        });
    },
    onBudgetUpdate(payload) {
      this.purchaseRequestUpdateApi(payload);
    },
    purchaseRequestUpdateApi(payload) {
      this.modifyPurchaseRequest(payload)
        .then((data) => {
          this.purchaseRequest = data;
        })
        .catch((error) => this.showErrorMessage(error));
    },
  },
};
</script>
