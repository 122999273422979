<template>
  <Modal
    :show-modal="showModal"
    :close-modal="closeModal"
    :show-icon="showIcon"
  >
    <template #icon>
      <ThumbUpIcon
        v-if="action == 'Approved'"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <ThumbDownIcon
        v-if="action == 'Rejected'"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
      <ExclamationIcon
        v-if="action == 'Revision'"
        class="h-6 w-6 text-white"
        aria-hidden="true"
      />
    </template>
    <template #header>
      <h3>{{ $t("global.decisions.genericHeader") }}</h3>
    </template>
    <template #body>
      <div>
        <div v-if="action" class="max-w-xl text-sm text-gray-500 py-4">
          <p>
            <i18n-t :keypath="`invoice.confirmation.${action}`">
              <template #order>
                <span
                  class="flex-shrink-0 inline-block px-2 py-0.5 rounded border border-gray-300 text-gray-700 font-medium"
                  >{{ order }}</span
                >
              </template>
              <template #invoice>
                <span
                  class="flex-shrink-0 inline-block px-2 py-0.5 rounded border border-gray-300 text-gray-700 font-medium bg-primary/10"
                  >{{ invoice.invoiceNumber }}</span
                >
              </template>
            </i18n-t>
          </p>
        </div>
        <div
          v-if="action !== 'Approved'"
          class="sm:flex sm:items-center flex flex-col gap-y-4"
        >
          <Multiselect
            v-model="reasonId"
            :options="reasons"
            :searchable="true"
            :can-deselect="false"
            :can-clear="false"
            :placeholder="$t(`invoice.confirmation.placeholder`)"
          >
            <template #singlelabel="{ value }">
              <span class="multiselect-single-label text-sm truncate">
                {{ $t(`invoice.confirmation.rejectReasons.${value.value}`) }}
              </span>
            </template>
            <template #option="{ option }">
              {{ $t(`invoice.confirmation.rejectReasons.${option.value}`) }}
            </template>
          </Multiselect>

          <TextArea
            v-if="reasonId === 'O'"
            name="rejectReason"
            :placeholder="
              $t(`purchaseRequest.summary.statusUpdateModal.decisionComment`)
            "
            :value="rejectReason"
            :rows="3"
            :on-key-up="onAttributeChange"
          />
        </div>
        <div v-if="isAccountant && buttonAction == 'Revision'" class="mt-8">
          <SwitchCheckbox v-model="archive" :label="$t('invoice.archive')" />
        </div>
        <span v-show="isAccountant && action == 'Rejected'" class="w-full mt-2">
          <label>Notify Users</label>
          <MemberSelector
            mode="tags"
            :value="notifyUsers"
            :on-value-change="
              (response) => {
                notifyUsers = response;
              }
            "
            :object="true"
            :members-list="usersList()"
          />
        </span>
      </div>
    </template>
    <template #footer>
      <Button v-focus :on-click-handler="onCloseHandler" :color="'gray'">
        {{ $t("shared.cancelButton") }}
      </Button>
      <Button
        v-if="action == 'Approved'"
        class="btn-success"
        full
        :loading="loading"
        :on-click-handler="() => updateInvoiceStatus('Approved')"
      >
        <template #icon><ThumbUpIcon /></template>
        {{ $t("global.decisions.approve") }}
      </Button>
      <Button
        v-if="action == 'Revision'"
        color="danger"
        full
        :loading="loading"
        :disabled="!isButtonEnabled"
        :on-click-handler="() => updateInvoiceStatus('Revision')"
      >
        <template #icon><ExclamationIcon /></template>
        {{ $t("global.decisions.revision") }}
      </Button>

      <Button
        v-if="action == 'Rejected'"
        color="danger"
        full
        :loading="loading"
        :on-click-handler="() => updateInvoiceStatus('Rejected')"
      >
        <template #icon><ThumbDownIcon /></template>
        {{ $t("global.decisions.reject") }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import { Button, Modal, TextArea } from "@/components/shared";
import { INVOICE_MODULE, UPDATE_INVOICE_RELATION } from "@/store/Invoice/types";
import { mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ExclamationIcon,
} from "@heroicons/vue/outline";
import SwitchCheckbox from "@/components/shared/SwitchCheckbox";
import { MemberSelector } from "@/components/shared/index";
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
export default {
  components: {
    Multiselect,
    Modal,
    Button,
    ThumbUpIcon,
    ThumbDownIcon,
    ExclamationIcon,
    TextArea,
    SwitchCheckbox,
    MemberSelector,
  },
  props: {
    buttonAction: { type: String, required: true },
    showModal: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    resetData: {
      type: Function,
      required: true,
    },
    invoicePoId: { type: String, default: "" },
    showIcon: {
      type: Boolean,
      default: true,
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    order: {
      type: String,
      default: "-",
    },
    isAccountant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      action: "",
      archive: false,
      rejectReason: null,
      loading: false,
      reasonId: null,
      reasons: [0, 1, 2, 3, 4, 5, 6, "O"],
      notifyUsers: null,
    };
  },

  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["usersList"]),
    isInvoiceLevelApprover() {
      return this.invoice.isInvoiceLevelApprover;
    },
    isButtonEnabled() {
      if (this.reasonId === "O") {
        return this.rejectReason;
      }
      return this.reasonId != null;
    },

    combinedRejectReason() {
      if (this.reasonId == null) return null;
      if (this.reasonId === "O") {
        return this.reasonId + ": " + this.rejectReason;
      }
      return (
        this.reasonId +
        ": " +
        this.$t(`invoice.confirmation.rejectReasons.${this.reasonId}`)
      );
    },
  },
  watch: {
    archive: {
      handler(shouldArchive) {
        this.action = this.buttonAction;
        if (shouldArchive) this.action = "Rejected";
      },
    },
    buttonAction: {
      immediate: true,
      handler(value) {
        this.action = value;
      },
    },
  },

  methods: {
    ...mapActions(INVOICE_MODULE, {
      updateRelation: UPDATE_INVOICE_RELATION,
    }),

    onCloseHandler() {
      this.rejectReason = null;
      this.reasonId = null;
      this.closeModal();
    },
    updateInvoiceStatus() {
      this.loading = true;
      const payload = {
        status: this.action,
      };
      if (this.action != "Approved") {
        payload.decisionNote = this.combinedRejectReason;
        if (this.notifyUsers)
          payload.notifyUsers = this.notifyUsers.map((user) => user.id);
      }
      this.updateRelation({
        invoiceRelationId:
          this.isInvoiceLevelApprover || this.action == "Rejected"
            ? this.invoice.id
            : this.invoicePoId,
        isInvoiceLevelApprover: this.isInvoiceLevelApprover,
        payload,
      })
        .then((result) => {
          this.resetData(result);
          this.rejectReason = null;
          this.reasonId = null;

          this.showNotification("Status is Updated for this Invoice");
        })
        .catch((error) => this.showErrorMessage(error))
        .finally(() => {
          this.loading = false;
        });
    },
    onAttributeChange(event) {
      const { name, value } = event.target;
      this[name] = value;
    },
  },
};
</script>
