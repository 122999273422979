<template>
  <template v-if="isEdit">
    <div class="flex items-center my-2">
      <label class="text-sm font-medium text-gray-500 mr-2">{{
        $t("purchaseRequest.detail.paymentMethod")
      }}</label>
      <HivebuyTooltip v-if="addDefault" :open-delay="50">
        <QuestionMarkCircleIcon class="w-4 h-4 text-primary" />
        <template #content>
          <div class="p-2">
            <i18n-t
              keypath="legalTendency.legalTendencyModal.tooltipDefault"
              tag="span"
              class="text-base"
            >
              <template #icon>
                <span class="inline-block">
                  <DefaultIcon class="w-4 h-4 text-primary" />
                </span>
              </template>
              <template #object>
                {{ $t("purchaseRequest.detail.paymentMethod") }}
              </template>
            </i18n-t>
          </div>
        </template>
      </HivebuyTooltip>
    </div>
    <Multiselect
      v-model="selectorValue"
      class="w-full bg-white"
      :mode="mode"
      :label="label"
      :track-by="'title'"
      :value-prop="'id'"
      :object="objectType"
      :options="optionsList"
      :placeholder="$t('purchaseRequest.detail.selectPaymentMethod')"
      :can-clear="canClear"
      :searchable="true"
      :can-deselect="false"
    >
      <template #tag="{ option, handleTagRemove, disabled }">
        <div
          :class="[
            'multiselect-tag tag-section',
            { 'is-disabled': disabled },
            option.isDefault ? 'bg-hivebuy-red' : 'bg-hivebuy-green',
          ]"
        >
          <span v-if="addDefault" class="multiselect-tag-remove">
            <UndefaultIcon
              v-if="option.isDefault"
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
            <DefaultIcon
              v-else
              class="w-4 h-4 text-white flex-none"
              @mousedown.prevent="onMarkAsDefault(option)"
            />
          </span>
          {{ option.title }}
          <span
            v-if="!disabled"
            class="multiselect-tag-remove"
            @mousedown.prevent="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon" />
          </span>
        </div>
      </template>
    </Multiselect>
    <FieldError v-if="isInvalid" :text="errorMessage" />
  </template>
  <template v-else>
    <div class="section">
      <dt class="section-title">
        {{ $tc("global.paymentMethod", list.length) }}
      </dt>
      <dd v-if="!paymentMethodId && !list.length" class="text-gray-500 text-sm">
        <p>
          {{
            $t(
              "purchaseRequest.summary.deliveryPaymentSection.configuredViaSupplier"
            )
          }}
        </p>
      </dd>
      <dd
        v-else-if="paymentMethodId || list.length == 1"
        class="text-gray-500 text-sm"
      >
        <p>
          {{ getPaymentMethodDetails?.title }}
        </p>
      </dd>
      <dd v-else class="flex">
        <p class="mr-1">
          {{ $t("tooltip.squashedText.paymentMethod", { count: list.length }) }}
        </p>
        <Tip help theme="tooltip">
          <template #header>
            <div class="flex items-center truncate">
              {{ $tc("global.paymentMethod", 2) }}
            </div>
          </template>
          <template #content>
            <ul class="list-inside list-decimal">
              <li
                v-for="(paymentMethod, index) in getPaymentMethodDetails"
                :key="index"
                class="tooltip-subtext text-gray-700 font-medium"
              >
                {{
                  paymentMethod?.title ||
                  $t(
                    "purchaseRequest.summary.deliveryPaymentSection.configuredViaSupplier"
                  )
                }}
              </li>
            </ul>
          </template>
        </Tip>
      </dd>
    </div>
  </template>
</template>

<script>
import {
  COMPANY_MANAGEMENT_MODULE,
  GET_PAYMENT_METHOD,
} from "@/store/CompanyManagement/types";
import { mapActions, mapGetters, mapState } from "vuex";
import storeValueGetterMixin from "@/components/mixins/storeValueGetterMixin";
import Multiselect from "@vueform/multiselect";
import {
  BookmarkIcon as UndefaultIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";
import { BookmarkIcon as DefaultIcon } from "@heroicons/vue/outline";
import HivebuyTooltip from "@/components/shared/HivebuyTooltip.vue";
import FieldError from "@/components/shared/FieldError.vue";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";

export default {
  components: {
    QuestionMarkCircleIcon,
    HivebuyTooltip,
    Multiselect,
    DefaultIcon,
    UndefaultIcon,
    FieldError,
  },
  mixins: [storeValueGetterMixin],
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Object, String, Array],
      default: "",
    },
    paymentMethodId: {
      type: String,
      default: "",
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "",
    },
    objectType: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    requestedForId: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "single",
    },
    addDefault: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "title",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["paymentMethods"]),
    ...mapState(PURCHASE_REQUEST_MODULE, ["requestedForValues"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["paymentList"]),
    getPaymentMethodDetails() {
      if (!this.paymentList || (!this.paymentMethodId && !this.list.length))
        return;
      return this.extractPaymentDetails();
    },
    selectorValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
        this.$emit("change", newValue);
      },
    },
    errorMessage() {
      if (this.errorText) return this.errorText;
      return this.$t(
        "catalogue.myCart.purchaseRequestInfoModal.paymentMethodError"
      );
    },
    optionsList() {
      return this.addDefault
        ? this.paymentList.map((item) => {
            return {
              ...item,
              isDefault: false,
            };
          })
        : this.paymentList;
    },
  },
  watch: {
    requestedForValues: {
      deep: true,
      handler(newVal) {
        if (newVal[this.requestedForId]) {
          this.setPaymentMethod(newVal[this.requestedForId].defaultValues);
        }
      },
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      fetchPaymentMethod: GET_PAYMENT_METHOD,
    }),
    extractPaymentDetails() {
      if (this.paymentMethodId) {
        const paymentMethodDetails = this.valueFromStore(this.paymentMethodId);
        !paymentMethodDetails && this.fetchPaymentMethod(this.paymentMethodId);
        return paymentMethodDetails;
      } else if (this.list.length) {
        const lisDetails = this.list.map((item) =>
          this.valueFromStore(item.paymentMethod)
        );
        return lisDetails.length > 1 ? lisDetails : lisDetails[0];
      }
      return null;
    },
    valueFromStore(value) {
      return this.getStoreValue(
        COMPANY_MANAGEMENT_MODULE,
        "paymentMethods",
        value
      );
    },
    setPaymentMethod(data) {
      this.selectorValue = this.objectType
        ? this.paymentMethods[data.paymentMethod]
        : data.paymentMethod;
    },
    onMarkAsDefault(option) {
      this.selectorValue.forEach((item) => {
        if (item.id === option.id) {
          item.isDefault = !item.isDefault;
          const message = item.isDefault
            ? `${item.title} ${this.$t("home.markAsDefault")}`
            : `${item.title} ${this.$t("home.markAsUnDefault")}`;

          this.showNotification(message);
        } else {
          item.isDefault = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.multiselect-tag.tag-section i:before {
  @apply text-white rounded-full;
}
.section-title {
  @apply text-base mb-1;
}
</style>
