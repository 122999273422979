import * as TYPES from "./types.js";
import { purchaseRequestPayload } from "@/utils/helpers/modelHelpers/purchaseRequest/freeForm.js";

import {
  urlData,
  addPurchaseRequest,
  getPurchaseRequestsList,
  getPurchaseRequest,
  deletePurchaseRequest,
  getPurchaseRequests,
  downloadInvoices,
  downloadAccrualReport,
  addComment,
  updateComment,
  deleteComment,
  updatePurchaseRequestStatus,
  updatePurchaseOrderStatus,
  updatePurchaseRequest,
  restorePurchaseOrderStatus,
  updatePurchaseRequestItem,
  restorePurchaseRequestItem,
  updatePurchaseRequestItemStatus,
  changeShoppingListRelation,
  setCostSaving,
  getDefaultValuesForRequestedFor,
  searchItems,
  getExchangeRate,
  validateRequestData,
  createNewRequest,
  markDraft,
  getDraftList,
  getDraftRequest,
} from "@/utils/services";
export default {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [TYPES.GET_LINK_PREVIEW]: ({ commit }, url) => {
    return new Promise((resolve, reject) => {
      urlData(url, resolve, reject);
    });
  },
  [TYPES.VALIDATE_REQUEST_DATA]: ({ state }) => {
    return new Promise((resolve, reject) => {
      validateRequestData(resolve, reject, state.newRequest);
    });
  },

  [TYPES.CREATE_NEW_REQUEST]: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      createNewRequest(resolve, reject, commit, state.newRequest);
    });
  },
  [TYPES.CREATE_DRAFT_REQUEST]: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      markDraft(resolve, reject, commit, state.newRequest);
    });
  },
  [TYPES.GET_DRAFT_REQUEST]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      getDraftRequest(resolve, reject, id, commit);
    });
  },
  [TYPES.GET_DRAFT_REQUESTS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      getDraftList(resolve, reject, params, commit);
    });
  },

  [TYPES.ADD_PURCHASE_REQUEST]: ({ commit, state }, preview = true) => {
    if (!state.newRequest.items.length) return;
    return new Promise((resolve, reject) => {
      let isAddAction = true;
      const isApprovalAndBudgetPending = !(
        state.newRequest?.instantApproval || state.newRequest?.approvals?.length
      );
      if (!isApprovalAndBudgetPending && !preview) isAddAction = false;
      const requestPayload = purchaseRequestPayload(
        state.newRequest,
        !isAddAction
      );
      if (isAddAction) {
        addPurchaseRequest(
          requestPayload,
          preview,
          commit,
          state,
          resolve,
          reject
        );
      } else {
        updatePurchaseRequest(
          state.newRequest.id,
          commit,
          resolve,
          reject,
          requestPayload
        );
      }
    });
  },
  [TYPES.GET_LIST]: ({ commit }, { queryParams, areFiltersUpdated }) => {
    return new Promise((resolve, reject) => {
      getPurchaseRequestsList(
        commit,
        resolve,
        reject,
        queryParams,
        areFiltersUpdated
      );
    });
  },

  [TYPES.SET_COST_SAVING]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      setCostSaving(payload, commit, resolve, reject);
    });
  },
  [TYPES.EDIT_PURCHASE_REQUEST]: (
    { commit },
    { payload, purchaseRequestId }
  ) => {
    return new Promise((resolve, reject) => {
      updatePurchaseRequest(
        purchaseRequestId,
        commit,
        resolve,
        reject,
        payload
      );
    });
  },

  [TYPES.GET_PURCHASE_REQUESTS]: ({ commit }, paginationParams = {}) => {
    return new Promise((resolve, reject) => {
      getPurchaseRequests(commit, resolve, reject, paginationParams);
    });
  },

  [TYPES.DOWNLOAD_ACCRUAL_REPORT]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    params = {}
  ) => {
    return new Promise((resolve, reject) => {
      downloadAccrualReport(resolve, reject, params);
    });
  },

  [TYPES.GET_EXCHANGE_RATE]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    currency = ""
  ) => {
    return new Promise((resolve, reject) => {
      getExchangeRate(resolve, reject, currency);
    });
  },
  [TYPES.DOWNLOAD_PURCHASE_REQUESTS_INVOICES]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    params = {}
  ) => {
    return new Promise((resolve, reject) => {
      downloadInvoices(resolve, reject, params);
    });
  },

  [TYPES.GET_PURCHASE_REQUEST]: ({ commit }, purchaseRequestId) => {
    return new Promise((resolve, reject) => {
      getPurchaseRequest(purchaseRequestId, commit, resolve, reject);
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [TYPES.ADD_COMMENT]: ({ commit }, { prId, commentId, comment, mentions }) => {
    return new Promise((resolve, reject) => {
      addComment(prId, comment, commit, resolve, reject);
    });
  },

  [TYPES.UPDATE_COMMENT]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { prId, commentId, comment, mentions }
  ) => {
    return new Promise((resolve, reject) => {
      updateComment(prId, commentId, { comment, mentions }, resolve, reject);
    });
  },

  [TYPES.DELETE_COMMENT]: ({ commit }, { prId, commentId }) => {
    return new Promise((resolve, reject) => {
      deleteComment(prId, commentId, commit, resolve, reject);
    });
  },

  [TYPES.UPDATE_PURCHASE_REQUEST_STATUS]: (
    { commit },
    { purchaseRequestId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updatePurchaseRequestStatus(
        commit,
        purchaseRequestId,
        resolve,
        reject,
        payload
      );
    });
  },
  [TYPES.CHANGE_SHOPPING_LIST_RELATION]: ({ commit }, { method, payload }) => {
    return new Promise((resolve, reject) => {
      changeShoppingListRelation(method, payload, commit, resolve, reject);
    });
  },

  [TYPES.UPDATE_PURCHASE_REQUEST]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    { purchaseRequestId, payload }
  ) => {
    return new Promise((resolve, reject) => {
      updatePurchaseRequest(purchaseRequestId, resolve, reject, payload);
    });
  },

  [TYPES.DELETE_PURCHASE_REQUEST]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    purchaseRequestId
  ) => {
    return new Promise((resolve, reject) => {
      deletePurchaseRequest(purchaseRequestId, resolve, reject);
    });
  },

  [TYPES.UPDATE_PURCHASE_ORDER_STATUS]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      updatePurchaseOrderStatus(resolve, reject, payload);
    });
  },

  [TYPES.RESTORE_PURCHASE_ORDER]: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      restorePurchaseOrderStatus(resolve, reject, payload);
    });
  },
  [TYPES.UPDATE_PR_ITEM_STATUS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updatePurchaseRequestItemStatus(payload, commit, resolve, reject);
    });
  },
  [TYPES.UPDATE_PR_ITEM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      updatePurchaseRequestItem(payload, commit, resolve, reject);
    });
  },
  [TYPES.RESTORE_PR_ITEM]: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      restorePurchaseRequestItem(id, commit, resolve, reject);
    });
  },

  [TYPES.GET_DEFAULT_VALUES_FOR_REQUESTED_FOR]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      getDefaultValuesForRequestedFor(commit, resolve, reject, payload);
    });
  },
  [TYPES.GET_SEARCH_ITEMS]: (_store, payload) => {
    return new Promise((resolve, reject) => {
      searchItems(resolve, reject, payload);
    });
  },
};
