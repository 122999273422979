<template>
  <div class="mt-4">
    <div v-if="detailsAdded && showFiles">
      <div class="relative mb-3">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
          <span class="bg-white px-2 text-hivebuy-green text-xs"
            >{{ title }}
          </span>
        </div>
      </div>
      <div class="relative mb-6">
        <div
          class="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12 relative"
          >
            <span
              class="absolute top-1 right-1 block h-4 w-4 rounded-full ring-1 ring-white bg-hivebuy-green p-0.5"
              ><CheckIcon class="text-white" />
            </span>
            <component :is="icon" class="h-6 w-6 text-gray-500" />
          </div>
          <div class="flex grow justify-between">
            <div class="ml-4 text-sm text-gray-500 grow">
              <p>
                {{ title }} @
                <span v-if="time" class="text-gray-900">{{
                  formattedDate(time)
                }}</span>
              </p>
              <p v-if="user">
                {{ title }}
                <span class="text-gray-900">
                  {{ user ? user.name : "Hivebuy" }}
                </span>
              </p>
              <span v-if="file">
                <a
                  ref="downloadInvoice"
                  :href="file"
                  download="download"
                  target="_blank"
                />
                <div class="flex items-center">
                  {{ fileName }}

                  <div
                    class="ml-2 py-0.5 px-4 rounded-lg border border-gray-300 cursor-pointer hover:bg-gray-100"
                    @click="() => $refs.downloadInvoice.click()"
                  >
                    <DownloadIcon class="h-5 w-5 text-hivebuy-green" />
                  </div>
                </div>
              </span>
            </div>
            <div v-if="user" class="text-white ml-4">
              <UserWithAvatar :user="user" :show-tooltip="true" />
            </div>
          </div>
        </div>
        <div v-if="note" class="mt-3">
          <div class="text-sm text-gray-500 p-2 border border-gray-200 rounded">
            <span class="text-gray-500">
              {{ note }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-if="!isUserOnlyAuditor"
        :class="[
          'relative',
          {
            'cursor-pointer': !isDisabled,
            'cursor-not-allowed': isDisabled,
          },
        ]"
        v-on="{
          click: isDisabled ? null : onDetailClick,
        }"
      >
        <div
          class="groswe4 flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none"
          :class="!isDisabled ? 'border border-gray-300 hover:bg-gray-50' : ''"
        >
          <div
            class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12 relative"
          >
            <span
              v-if="autoProcessing"
              class="absolute top-1 right-1 block h-4 w-4 rounded-full ring-1 ring-white bg-yellow-500"
            >
              <ClockIcon class="text-white" />
            </span>
            <component :is="icon" class="h-6 w-6 text-gray-500" />
          </div>
          <div class="ml-4 flex justify-between grow">
            <div>
              <p class="text-sm font-medium text-gray-900">
                {{ subtitle }}
              </p>
              <p v-if="autoProcessing" class="text-sm text-gray-400">
                {{
                  $t("purchaseRequest.summary.purchaseOrderStatus.autoOrder")
                }}
              </p>
            </div>
            <div class="mr-4">
              <p class="text-sm font-medium text-gray-900">
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-200"
                  :class="!isDisabled ? 'group-hover:text-hivebuy-green' : ''"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formattedTime } from "@/utils/utility_methods";
import { UserWithAvatar } from "@/components/shared/index";
import OrderSVG from "@/assets/images/OrderSVG";
import DeliverSVG from "@/assets/images/DeliverSVG";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import { DownloadIcon } from "@heroicons/vue/outline";
import { CheckIcon, ClockIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: {
    UserWithAvatar,
    OrderSVG,
    DeliverSVG,
    InvoiceSVG,
    DownloadIcon,
    CheckIcon,
    ClockIcon,
    ChevronRightIcon,
  },
  props: {
    detailsAdded: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    time: {
      type: String,
      required: false,
      default: null,
    },
    note: {
      type: String,
      required: false,
      default: null,
    },
    onDetailClick: {
      type: Function,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: false,
      default: null,
    },
    fileName: {
      type: String,
      required: false,
      default: null,
    },
    autoProcessing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showFiles: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isDisabled() {
      return this.disabled || (this.autoProcessing && this.title === "Order");
    },
  },
  methods: {
    formattedTime,
  },
};
</script>
