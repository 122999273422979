import {
  COMPANY_MANAGEMENT_MODULE,
  GET_USER,
  GET_USERS,
} from "@/store/CompanyManagement/types";
import { AUTH_MODULE } from "@/store/Auth/types";
import { mapActions, mapGetters } from "vuex";

import { objectKeyCount, isObject } from "@/utils/utility_methods";

export default {
  data() {
    return {
      activeMember: {},
    };
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["isUserOnlyAuditor"]),
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getUser: GET_USER,
      getUsers: GET_USERS,
    }),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getUser"]),
    ...mapGetters(AUTH_MODULE, ["requestedForType"]),
    objectKeyCount,
    isObject,
    async getMemberDetails(memberObject) {
      if (!memberObject) return null;
      if (
        this.isObject(memberObject) &&
        this.objectKeyCount(memberObject) > 1
      ) {
        return memberObject;
      } else {
        const id = memberObject?.id || memberObject;
        const member = this.getUser()(id);
        if (member) return member;
        if (this.isApiLoading("users")) return null;
        const response = await this.getUsers({ user_id: id });
        return response[0];
      }
    },
  },
};
