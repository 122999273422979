<template>
  <div
    :class="[
      'col',
      {
        collapse: isCollapsed,
        'my-requests': isMyPurchaseRequests && isAdvancedUser,
        'basic-user': isMyPurchaseRequests && !isAdvancedUser,
      },
    ]"
  >
    <div class="col-header">
      <div class="col-header-left">
        <div :class="`header-icon-holder ${cardIconClass.color}`">
          <component
            :is="cardIconClass.icon"
            :class="[
              `text-white ${cardIconClass.size}`,
              isCollapsed
                ? 'rotate-90 transition-all duration-500'
                : 'transition-all duration-500',
            ]"
          />
        </div>
        <div class="header-title">
          <div class="header-title-text line-clamp-1">{{ title }}</div>
          <span v-if="totalRecordsCount" class="total-records-count">
            {{ totalRecordsCount }}
          </span>
        </div>
      </div>
      <div
        class="hidden sm:flex col-header-right cursor-pointer border p-1 rounded-full hover:bg-gray-100"
        @click="isCollapsed = !isCollapsed"
      >
        <ChevronLeftIcon class="w-4 h-4" />
      </div>
      <div
        class="sm:hidden col-header-right cursor-pointer border p-1 rounded-full hover:bg-gray-100"
        @click="goToList"
      >
        <ChevronRightIcon class="w-4 h-4" />
      </div>
    </div>
    <div class="col-tickets-holder">
      <div v-if="areRecordsLoading" class="loader">
        <div class="loader-holder">
          <HivebuyLoader />
        </div>
      </div>
      <div v-else-if="areRecordsBlank">
        <div
          class="relative w-full rounded-lg border-2 border-dashed border-gray-300 px-4 py-8"
        >
          <div
            v-if="isMyPurchaseRequests"
            class="flex items-center justify-center flex-col"
          >
            <ClipboardIcon class="w-10 h-10 text-gray-300" />
            <div
              class="mt-2 text-sm font-medium text-gray-500 flex items-center"
            >
              <i18n-t keypath="dashboard.emptyColMyRequests" tag="div">
              </i18n-t>
            </div>
            <span
              class="font-bold text-primary flex mx-1 mt-1 items-center hover:text-hivebuy-green cursor-pointer text-base"
              @click="toggleCreateRequest"
            >
              <PlusCircleIcon class="w-4 h-4 mr-1" />
              {{ $t("dashboard.emptyColMyRequestsCreate") }}
            </span>
          </div>
          <div v-else class="flex items-center justify-center flex-col">
            <SunIcon class="w-12 h-12 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-500">{{
              $t("dashboard.emptyCol")
            }}</span>
          </div>
        </div>
      </div>
      <slot v-else name="col-tickets-content" />
    </div>
  </div>
</template>

<script>
import {
  ChevronLeftIcon,
  ClipboardCheckIcon,
  ClipboardIcon,
  ChevronRightIcon,
  PlusCircleIcon,
} from "@heroicons/vue/outline";
import { HivebuyLoader } from "@/components/shared";
import { mapState } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { SunIcon } from "@heroicons/vue/outline";
import PoSVG from "@/assets/images/PoSVG";
import QuestionSVG from "@/assets/images/QuestionSVG";
import InvoiceSVG from "@/assets/images/InvoiceSVG";
import ShoppingCartSVG from "@/assets/images/ShoppingCartSVG";

export default {
  name: "CardSection",
  components: {
    ChevronLeftIcon,
    ClipboardCheckIcon,
    HivebuyLoader,
    SunIcon,
    ClipboardIcon,
    PlusCircleIcon,
    PoSVG,
    QuestionSVG,
    InvoiceSVG,
    ShoppingCartSVG,
    ChevronRightIcon,
  },
  inject: ["toggleCreateRequest"],
  props: {
    records: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    totalRecordsCount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    isMyPurchaseRequests() {
      return this.title === this.$t("dashboard.headerMyPurchaseRequests");
    },
    isAdvancedUser() {
      return !!this.user.roles.length;
    },
    areRecordsLoading() {
      return this.loading;
    },
    areRecordsBlank() {
      return !this?.records?.length;
    },
    cardIconClass() {
      switch (this.title) {
        case this.$t("dashboard.headerMyActions"):
          return {
            color: "!bg-hivebuy-yellow",
            icon: QuestionSVG,
            size: "h-6 w-6",
          };
        case this.$t("dashboard.headerMyPurchaseRequests"):
          return {
            color: "!bg-hivebuy-green",
            icon: ShoppingCartSVG,
            size: "h-5 w-5",
          };
        case this.$t("dashboard.headerMyPurchaseOrders"):
          return {
            color: "!bg-primary",
            icon: PoSVG,
            size: "h-5 w-5",
          };
        case this.$t("dashboard.headerMyInvoices"):
          return {
            color: "!bg-hivebuy-green",
            icon: InvoiceSVG,
            size: "h-5 w-5",
          };
        default:
          return "";
      }
    },
  },
  mounted() {
    const totalCardSections = document.querySelectorAll(".col").length;
    if (totalCardSections > 3) {
      this.isCollapsed = this.isMyPurchaseRequests && this.isAdvancedUser;
    }
  },
  methods: {
    goToList() {
      const result = { component: null, query: null };
      switch (this.title) {
        case this.$t("dashboard.headerMyActions"):
          result.component = "Requests";
          result.query = { actionRequired: true };
          break;
        case this.$t("dashboard.headerMyPurchaseRequests"):
          result.component = "Requests";
          result.query = { requestedBy: this.user.id };
          break;
        case this.$t("dashboard.headerMyPurchaseOrders"):
          result.component = "PurchaseOrders";
          break;
        case this.$t("dashboard.headerMyInvoices"):
          result.component = "InvoiceDashboard";
          break;
        default:
          break;
      }
      if (result) {
        this.$router.push({
          name: result.component,
          query: result.query,
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../../../assets/styles/dashboard.css";
.loader {
  @apply flex justify-center items-center h-full;
}
.loader-holder {
  @apply w-24 h-24;
}
</style>
