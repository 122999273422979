<template>
  <label class="inline-flex items-center" :for="id">
    <input
      :id="id"
      :name="name"
      type="checkbox"
      class="h-5 w-5 text-primary border-gray-300 focus:outline-none focus:border-primary focus:ring-primary rounded-sm"
      :value="value"
      :checked="value"
      @input="onInput"
    />
    <span :class="`ml-2 text-sm ${textColor}`">
      {{ label }}
      <slot name="conent" />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "text-primary",
    },
  },
  emits: ["update:modelValue", "on-input"],
  computed: {
    value() {
      return this.modelValue || this.checked;
    },
  },
  methods: {
    onInput({ target: { checked } }) {
      this.$emit("on-input", checked);
      this.$emit("update:modelValue", checked);
    },
  },
};
</script>
