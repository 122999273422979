<template>
  <div class="w-full sticky top-0">
    <div class="grid grid-cols-2 gap-x-2 gap-y-2">
      <div class="col-span-full flex justify-center">
        <Button
          v-if="allowCreate"
          :on-click-handler="createNewRequest"
          :disabled="!items.length"
          color="success"
          full
        >
          <template #icon>
            <ShoppingCartIcon />
          </template>
          {{ $t("purchaseRequest.summary.totalPrice.sendRequest") }}
        </Button>
        <Button
          v-else
          :on-click-handler="() => toggleTitleModal(false)"
          :disabled="!items.length"
          color="success"
          full
        >
          <template #icon>
            <ShoppingCartIcon />
          </template>
          {{ $t("buttons.checkout") }}
        </Button>
      </div>
      <Button
        :on-click-handler="confirmModalToggle"
        :loading="loading"
        color="cancel-gray"
        size-class="sm"
      >
        <template #icon>
          <XIcon />
        </template>
        {{ $t("buttons.cancel") }}
      </Button>
      <Button
        :on-click-handler="() => toggleTitleModal(true)"
        :loading="loading"
        :disabled="!items.length"
        size-class="sm"
        color="primary-gray"
      >
        <template #icon>
          <SaveIcon />
        </template>
        {{ $t("buttons.save") }}
      </Button>
      <TitleInput
        :suggestion="newRequest.title || items[0]?.name"
        :save-method="handleSave"
        :is-draft="isDraft"
        :save-title-and-stay="markAsDraft"
        :open="showTitleModal"
        :close-function="() => (showTitleModal = false)"
      />
      <ConfirmDialog
        :text="$t('purchaseRequest.newRequest.cancelConfirm')"
        :show-modal="confirmModal"
        :close-modal="confirmModalToggle"
        :title="'NEW REQUEST'"
        :confirm-modal="onCancel"
      />
    </div>
  </div>
</template>
<script>
import { SaveIcon, XIcon, ShoppingCartIcon } from "@heroicons/vue/outline";
import TitleInput from "@/components/PurchaseRequest/SummarySections/TitleInput.vue";
import { Button, ConfirmDialog } from "@/components/shared/index";
import { mapActions } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  VALIDATE_REQUEST_DATA,
  CREATE_DRAFT_REQUEST,
  GET_DRAFT_REQUEST,
  CREATE_NEW_REQUEST,
} from "@/store/PurchaseRequest/types";
import { newRequestMixin } from "@/components/mixins/index.js";
import { scrollToError } from "@/utils/utility_methods";
export default {
  components: {
    Button,
    TitleInput,
    SaveIcon,
    XIcon,
    ShoppingCartIcon,
    ConfirmDialog,
  },
  mixins: [newRequestMixin],

  props: {
    allowCreate: {
      type: Boolean,
      default: true,
    },
    getRefs: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
      confirmModal: false,
      showTitleModal: false,
      loading: false,
      isDraft: false,
    };
  },
  computed: {
    titleChanged() {
      return (
        this.newRequest.title && this.newRequest.title !== this.items[0]?.name
      );
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      validateRequest: VALIDATE_REQUEST_DATA,
      markDraft: CREATE_DRAFT_REQUEST,
      getRequestDetails: GET_DRAFT_REQUEST,
      createRequest: CREATE_NEW_REQUEST,
    }),
    scrollToError,

    createNewRequest() {
      this.loading = true;
      this.createRequest()
        .then(async (response) => {
          await this.$router.push({
            name: "RequestDetail",
            params: { id: response.id },
            query: { validateRedirection: false },
          });
          this.saveRequestAttributes({
            action: "reset",
          });
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    markAsDraft() {
      this.loading = true;
      this.markDraft()
        .then(async () => {
          this.showTitleModal = false;

          await this.$router.push({
            name: "DraftList",
            query: { validateRedirection: false },
          });
          this.saveRequestAttributes({
            action: "reset",
          });
        })
        .catch((error) => {
          this.showErrorMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async toggleTitleModal(draft = false) {
      if (!draft) {
        const validItems = await this.areItemsValid();
        if (!validItems) {
          this.$nextTick(() => this.scrollToError());
          return;
        }
      }
      this.isDraft = draft;
      if (this.titleChanged) {
        this.handleSave();
      } else {
        this.showTitleModal = !this.showTitleModal;
      }
    },
    handleSave(title = null) {
      if (title) this.saveRequestAttributes({ title });
      if (this.isDraft) {
        this.markAsDraft();
        return;
      }

      this.$router.push({
        name: "purchaseRequestSummary",
        query: { validateRedirection: false },
      });
    },
    async areItemsValid() {
      const refs = this.getRefs();
      const validationEvents = [];
      Object.keys(refs).forEach((ref) => {
        if (refs[ref]?.length) {
          validationEvents.push(refs[ref][0].validateRequestData());
        }
      });
      const response = await Promise.all(validationEvents);
      const validationResponse = response.every((res) => res);
      let backendValidation = false;
      if (validationResponse)
        backendValidation = await this.validateRequestData();

      return validationResponse && backendValidation;
    },
    async validateRequestData() {
      // TODO: add sentry event here
      this.loading = true;
      try {
        await this.validateRequest();
        return true;
      } catch (error) {
        this.showErrorMessage(error);
        return false;
      } finally {
        this.loading = false;
      }
    },
    showDialog() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = !this.isOpen;
    },
    confirmModalToggle() {
      this.confirmModal = !this.confirmModal;
    },
    onCancel() {
      this.$router.push({
        name: "Dashboard",
        query: { validateRedirection: false },
      });
      this.saveRequestAttributes({
        action: "reset",
      });
    },
  },
};
</script>
