import {
  COMPANY_MANAGEMENT_MODULE,
  GET_COMPANY_SUPPLIERS,
  SET_COMPANY_SUPPLIERS_LIST_FILTERS,
} from "@/store/CompanyManagement/types";
import { mapActions, mapMutations, mapState } from "vuex";
import debounceMixin from "@/components/mixins/debounceMixin";
import {
  isValueUndefined,
  removeKeysFromObject,
} from "@/utils/utility_methods";

export default {
  mixins: [debounceMixin],
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companySuppliersListFilters"]),
    shouldExecutePaginationApi() {
      return (
        this.companySuppliersListFilters.pageSize <
        this.companySuppliersListFilters.totalDbRecords
      );
    },
    searchDebounceTimeout() {
      return this.shouldExecutePaginationApi ? 1000 : 0;
    },
    isListPage() {
      return ["CompanySuppliers"].includes(this.$route.name);
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      getCompanySuppliers: GET_COMPANY_SUPPLIERS,
    }),
    ...mapMutations(COMPANY_MANAGEMENT_MODULE, {
      setCompanySuppliersListFilters: SET_COMPANY_SUPPLIERS_LIST_FILTERS,
    }),
    getSuppliersByCategory(category) {
      return this.getCompanySuppliers({ category, pageSize: 1000 });
    },
    onSearchUpdate(key, value) {
      const loadingState = ["SupplierSelect", "SupplierSelection"].includes(
        this.$options.name
      )
        ? "companySuppliersSearch"
        : "companySuppliers";
      const paginationParams = removeKeysFromObject(
        this.companySuppliersListFilters,
        ["totalDbRecords"]
      );
      const updatedParams = Object.assign({}, paginationParams, {
        [key]: value,
      });

      if (
        this.shouldExecutePaginationApi &&
        value !== this.companySuppliersListFilters.search
      ) {
        this.setApiLoading({ key: loadingState, value: true });
        this.getCompanySuppliers(updatedParams).finally(() =>
          this.setApiLoading({
            key: loadingState,
            value: false,
          })
        );
      }

      if (this.isListPage || isValueUndefined(this.search)) {
        this.setCompanySuppliersListFilters(updatedParams);
      } else {
        this.search = value;
      }
    },
  },
};
