<template>
  <nav class="nav">
    <ul>
      <template v-for="item in navigationItems" :key="item.name">
        <li v-if="!item.children">
          <router-link
            :to="getRouteTo(item.component)"
            class="group nav__item"
            @click="toggleSidebar"
          >
            <component
              :is="item.icon"
              class="nav__item__icon !text-white"
              aria-hidden="true"
            />

            <span class="nav__item__label flex items-center">
              {{ item.name }}
              <span v-if="item.beta" class="beta"> Beta </span>
              <span v-if="isPremium(item.component)" class="premium-tag">
                Upgrade
              </span>
            </span>

            <span
              v-if="item.count && cartItems"
              :class="[
                activeRoute == item.component ? 'bg-red-400' : 'bg-red-600',
                'text-white ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full',
              ]"
            >
              {{ cartItems.length }}
            </span>
          </router-link>
        </li>
        <Disclosure v-else v-slot="{ open }" :ref="item.name" as="ul">
          <DisclosureButton
            as="li"
            :title="item.name"
            :class="{ 'current-item': activeRoute == item.component }"
            class="nav__item nav__item--dropdown"
          >
            <span class="flex space-x-3 items-center">
              <component
                :is="item.icon"
                :class="[
                  activeRoute == item.component ? 'text-white' : 'text-white ',
                  'nav__item__icon',
                ]"
                class="truncate"
                aria-hidden="true"
              />

              <span class="nav__item__label">
                {{ item.name }}
              </span>
            </span>
            <DropdownSVG :open="open" />
          </DisclosureButton>
          <DisclosurePanel>
            <router-link
              v-for="subItem in item.children"
              :key="subItem.name"
              :title="subItem.name"
              :to="getRouteTo(subItem.component)"
              class="truncate group nav__item nav__item--sub"
              @click="toggleSidebar"
            >
              <component
                :is="subItem.icon"
                class="nav__item__icon"
                aria-hidden="true"
              />

              <span class="flex-1 nav__item__label">
                {{ subItem.name }}
                <span v-if="isPremium(subItem.component)" class="premium-tag">
                  Upgrade
                </span>
              </span>
            </router-link>
          </DisclosurePanel>
        </Disclosure>
      </template>

      <RecentlyViewedPuR v-if="showRecentlyViewed" />
    </ul>
  </nav>
  <img src="@/assets/images/sidebar-design.svg" alt="" class="sidebar-design" />
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { InvoiceDashboard } from "@/components/InvoiceApproval/index.js";
import RecentlyViewedPuR from "@/components/Home/RecentlyViewedPuR.vue";
import { stringToBoolean } from "@/utils/utility_methods";
import { mapState } from "vuex";

import { navigationItemsMixin } from "@/components/mixins/index.js";
import DropdownSVG from "@/assets/images/DropdownSVG.vue";
import { PURCHASE_REQUEST_MODULE } from "@/store/PurchaseRequest/types";
import { getRouteTo, isPremium } from "@/utils/helpers/routeHelper";
import { FEATURE_MODULE } from "@/store/Feature/types";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import NewPRButton from "@/components/shared/NewPRButton.vue";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    RecentlyViewedPuR,
    InvoiceDashboard,
    DropdownSVG,
    NewPRButton,
  },
  mixins: [navigationItemsMixin],
  props: {
    isSidebarPermanentlyOpen: {
      type: Boolean,
      default: false,
    },
    isSidebarTemporaryOpen: {
      type: Boolean,
      default: false,
    },
    toggleSideBarCollapsed: { type: Function, required: true },
  },
  data() {
    return {
      navigationItems: this.sidebarItems(),
    };
  },
  computed: {
    ...mapState(PURCHASE_REQUEST_MODULE, {
      cartItems: (state) => state.currentRequest.items,
    }),
    ...mapState(FEATURE_MODULE, ["featureFlags"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["departments"]),
    countItemsInCart() {
      if (this.cartItems) {
        return this.cartItems.length;
      } else return false;
    },
    activeRoute() {
      return this.$route.name;
    },
    showRecentlyViewed() {
      return this.isSidebarPermanentlyOpen || this.isSidebarTemporaryOpen;
    },
    isSidebarOpen() {
      return this.isSidebarPermanentlyOpen || this.isSidebarTemporaryOpen;
    },
  },
  watch: {
    "$i18n.locale": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.navigationItems = this.sidebarItems();
        }
      },
    },
    featureFlags: {
      deep: true,
      handler() {
        this.navigationItems = this.sidebarItems();
      },
    },
    departments: {
      deep: true,
      handler() {
        this.navigationItems = this.sidebarItems();
      },
    },
  },
  mounted() {
    this.selectSidebarItem();
  },

  methods: {
    openParentItemPanel(name) {
      const parentElement = this.$refs[name][0].$el.children[0];

      if (!stringToBoolean(parentElement.getAttribute("aria-expanded"))) {
        parentElement.click();
      }
    },
    selectSidebarItem() {
      const { name } = this.$route;
      if (name) {
        const parentItem = this.findSidebarSelectedItem(name);
        if (parentItem) {
          this.openParentItemPanel(parentItem.name);
        }
      }
    },
    findSidebarSelectedItem(routeName) {
      for (const item of this.sidebarItems()) {
        if (item.children) {
          const childItem = item.children.find(
            (childItem) => childItem.component === routeName
          );
          if (childItem) return item;
        }
      }
      return null;
    },
    toggleSidebar() {
      if (
        window.matchMedia("(max-width: 767px)").matches &&
        this.isSidebarPermanentlyOpen
      ) {
        this.toggleSideBarCollapsed();
      }
    },
    getRouteTo,
    isPremium,
  },
};
</script>
<style scoped>
.nav {
  @apply flex-1 z-[20] flex flex-col;
}

.nav .nav__item {
  @apply text-white flex items-center cursor-pointer relative space-x-3 py-2.5 px-8 hover:bg-primarydark hover:bg-opacity-60 transition-all duration-100 mb-[1px];
}
.nav ul {
  @apply space-y-1;
}

.nav ul ul div {
  @apply relative;
}

.nav ul ul div:before {
  @apply absolute left-0 top-0 h-full border-l-[35px] border-primary z-50;
}

.nav .nav__item.nav__item--sub {
  @apply hover:border-opacity-50 border-white border-opacity-25 hover:bg-opacity-10 hover:bg-white mb-0 pl-10 duration-100;
}

.nav .nav__item.nav__item--sub .nav__item__icon {
  @apply flex-shrink-0 h-3.5 w-3.5 ml-1;
}

.nav .nav__item.nav__item--sub .nav__item__label {
  @apply text-sm;
}
.nav .nav__item.nav__item--dropdown {
  @apply justify-between;
}

/* .nav .nav__item.nav__item--dropdown[aria-expanded="true"] {
  @apply bg-primarydark bg-opacity-20 hover:bg-primarydark hover:bg-opacity-60;
} */

.nav .nav__item .nav__item__icon {
  @apply flex-shrink-0 h-5 w-5 scale-125  transition-all duration-500;
}

.sidebar:hover .nav .nav__item .nav__item__icon {
  @apply scale-100;
}
.sidebar.open .nav .nav__item .nav__item__icon {
  @apply scale-100;
}

.nav .nav__item .nav__item__label {
  @apply shrink-0 opacity-0 transition-all duration-500 translate-x-[10px];
  font-size: 14px;
}

.sidebar:hover .nav .nav__item .nav__item__label {
  opacity: 1;
  transform: translateX(0);
}
.sidebar.open .nav .nav__item .nav__item__label {
  opacity: 1;
  transform: translateX(0);
}

.sidebar.perm-open .nav .nav__item .nav__item__icon {
  @apply scale-100;
}

.sidebar.perm-open .nav .nav__item .nav__item__label {
  opacity: 1;
  transform: translateX(0);
}

.sidebar .nav .nav__item--sub {
  @apply pl-8;
}

.sidebar.open .nav .nav__item--sub {
  @apply pl-14 transition-all;
}

.sidebar:hover .nav .nav__item--sub {
  @apply pl-10;
}

.premium-tag {
  @apply ml-2 text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none;
  @apply focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-xs px-2 py-0.5 text-center;
}

.nav .nav-item,
.nav .nav-sub-item {
  @apply text-white mb-2 flex items-center p-2 cursor-pointer relative;
}

.nav .nav__item.current-item,
.nav .nav__item.router-link-active,
.nav .nav-sub-item.current-item,
.nav .nav-sub-item.router-link-active {
  @apply bg-primarydark/[.6];
}
.nav .nav__item--sub.current-item,
.nav .nav__item--sub.router-link-active,
.nav .nav-sub-item--sub.current-item,
.nav .nav-sub-item--sub.router-link-active {
  @apply bg-white/[.1] border-opacity-50;
}

.nav .nav-item:hover,
.nav .nav-sub-item:hover {
  @apply bg-white/[.15] text-white;
}

#new-request-btn-container {
  z-index: 25;
}

.menu-icon {
  width: 26px;
  height: 26px;
}

.sidebar-design {
  @apply absolute bottom-0 min-w-[300px] w-[300px] left-0 shrink-0;
  z-index: -1;
}

.beta {
  @apply ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-normal bg-hivebuy-yellow text-gray-800;
}
</style>
