<template>
  <div>
    <div class="relative">
      <div class="text-right">
        <Button
          v-if="showFiltersBtn"
          class="filter-btn"
          :on-click-handler="toggleAdvancedFilters"
          :color="filterButtonColor"
        >
          <AdjustmentsIcon
            class="text-primary hover:text-primarydark w-6 h-6"
          />

          <div v-if="appliedFiltersCount" class="counter-badge">
            {{ appliedFiltersCount }}
          </div>
        </Button>
      </div>
    </div>
    <CollapseTransition class="absolute right-0">
      <div v-if="showFilters" class="filter-holder z-50 w-[800px]">
        <div class="flex-grow z-50">
          <div class="col-span-12 lg:col-span-2 mt-6">
            <CustomFields
              v-model="filters"
              :source="'catalogue'"
              :filter-mode="true"
            />
          </div>

          <div class="flex justify-end ml-2 mt-4">
            <Button :on-click-handler="onApplyFilters" class="mr-2">
              {{ $t("button.apply") }}
            </Button>
            <Button :on-click-handler="onResetAllFilters" :color="'gray'">
              {{ $t("button.resetAll") }}
            </Button>
          </div>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import { AdjustmentsIcon } from "@heroicons/vue/outline";
import { Button } from "@/components/shared";
import CustomFields from "@/components/Catalogue/Admin/CustomFields.vue";
import { removeKeysInObjWithNoValue } from "@/utils/utility_methods";

export default {
  name: "CustomFieldFilters",
  components: {
    CollapseTransition,
    AdjustmentsIcon,
    Button,
    CustomFields,
  },
  props: {
    updateFilters: {
      type: Function,
      required: true,
    },
    advancedFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filters: [],
      showFilters: false,
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["getCustomFieldsBySource"]),
    filterButtonColor() {
      return this.showFilters ? "danger" : "primary";
    },
    appliedFiltersCount() {
      const updatedFilters = this.filters.map((filter) => {
        return removeKeysInObjWithNoValue(filter);
      });
      return updatedFilters.filter((filter) => !!filter.value).length;
    },
    showFiltersBtn() {
      return this.getCustomFieldsBySource("CATALOGUE").length;
    },
  },
  watch: {
    "advancedFilters.customFields": {
      handler(val) {
        this.filters = [...val];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleAdvancedFilters() {
      this.showFilters = !this.showFilters;
    },
    showAdvancedFilters() {
      this.showFilters = true;
    },
    hideAdvancedFilters() {
      this.showFilters = false;
    },
    onApplyFilters() {
      this.updateFilters(this.filters);
      this.showFilters = false;
    },
    onResetAllFilters() {
      this.filters = [];
      this.updateFilters(this.filters);
      this.showFilters = false;
    },
  },
};
</script>

<style scoped>
.filter-holder {
  @apply flex items-end justify-end bg-white p-6 rounded border border-gray-200 shadow-2xl;
}

.filter-btn {
  border: none;
  box-shadow: none;
  background: rgb(249 250 251) !important;
}
</style>
