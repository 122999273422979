import { required, helpers } from "@vuelidate/validators";
import store from "@/store";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import { isObjectEmpty } from "@/utils/utility_methods";
import { AUTH_MODULE } from "@/store/Auth/types";
import { PURCHASE_ORDER_STATUS_MAPPING } from "@/utils/constants";

export const error = (validationObject, attribute) => {
  if (!validationObject?.details?.[attribute]) return "";

  return validationObject.details[attribute].$error
    ? validationObject.$errors.find((error) => error.$property === attribute)
        .$message
    : "";
};
export const greaterThanZero = (value) => value > 0;

export const lessThanQuadrillion = (value, siblings, vm) => {
  const quantity = vm.details.quantity || 1;
  return value * quantity < 999999999999999;
};

export const requestedForValidation = (validation, requestedFor) => {
  if (isObjectEmpty(requestedFor)) return validation;

  const isDepartment = requestedFor?.type === "department";
  const isProject = requestedFor?.type === "project";

  let updatedValidation = Object.assign({}, validation);

  if (isDepartment) {
    updatedValidation = { ...updatedValidation, department: { required } };
  } else if (isProject) {
    updatedValidation = { ...updatedValidation, project: { required } };
  } else {
    const userGetter = store.getters[`${COMPANY_MANAGEMENT_MODULE}/getUser`];
    const user = userGetter(requestedFor?.id);

    if (user?.departments?.length) {
      updatedValidation = { ...updatedValidation, department: { required } };
    }
  }

  updatedValidation = { ...updatedValidation, id: { required } };

  return updatedValidation;
};

export const newRequestedForValidation = (item, field) => {
  const requestedForTypeGetter =
    store.getters[`${AUTH_MODULE}/requestedForType`];
  const type = requestedForTypeGetter(item?.requestedForType);

  const userGetter = store.getters[`${COMPANY_MANAGEMENT_MODULE}/getUser`];
  const user = userGetter(item?.requestedForId);

  switch (type) {
    case "department":
      return field === "department";
    case "project":
      return field === "project";
    case "user":
    case "myself":
      return user?.departments?.length && field === "department";
    default:
      return false;
  }
};

export const validationHash = (productCount) => {
  const hash = {
    name: {
      required,
    },
    quantity: {
      required,
      minValue: helpers.withMessage(
        "Quantity can't be less than one",
        greaterThanZero
      ),
    },
    unit: {
      required,
    },
    grossAmount: {
      minValue: greaterThanZero,
      maxValue: lessThanQuadrillion,
    },
    requestedFor: {
      required,
    },
  };

  if (!productCount) {
    hash.paymentMethod = {
      required,
    };
    hash.companyAddress = {
      required,
    };
  }

  return { details: hash };
};

const floatFields = ["grossAmount", "netAmount", "tax"];
const childFieldsForParent = [
  "category",
  "supplier",
  "companySupplier",
  "unlistedSupplier",
  "unlistedSupplierReason",
  "requestedForId",
  "requestedForType",
  "department",
  "paymentMethod",
  "companyAddress",
  "invoiceAddress",
];

export const mapNewRequest = (params) => {
  const data = { ...params, ...{ draftPurchaseRequest: params.id } };
  const deleteKeys = ["status", "requestStatus"];
  const requestedForTypeGetter =
    store.getters[`${AUTH_MODULE}/requestedForType`];

  const items = params.items.map((item, index) => {
    const newItem = { ...item, ...item.requestedFor, position: index + 1 };

    floatFields.forEach((field) => {
      if (newItem[field] !== undefined) {
        newItem[field] = parseFloat(newItem[field]).toFixed(4);
      }
    });

    deleteKeys.forEach((key) => {
      delete newItem[key];
    });

    newItem.requestedForType = requestedForTypeGetter(newItem.requestedForType);

    return newItem;
  });

  const parentData = childFieldsForParent.reduce((acc, field) => {
    if (items[0][field] !== undefined) {
      acc[field] = items[0][field];
    }
    return acc;
  }, {});

  deleteKeys.forEach((key) => {
    delete data[key];
  });

  return {
    ...data,
    ...parentData,
    items,
  };
};

const getObject = (payload) => {
  return Object.keys(payload).includes("storeState") ? payload.object : payload;
};
const isPoUpdate = (object) => {
  return object.id && object.purchaseRequest;
};
export const formatLiveUpdate = (payload, dataArray, key) => {
  const object = getObject(payload);
  const orderId = object?.purchaseOrder?.id;
  let array = [...dataArray];
  if (!orderId && isPoUpdate(object)) {
    let purchaseOrderIndex = array.findIndex((order) => order.id == object.id);
    const updatedObject = Object.assign({}, array[purchaseOrderIndex], object);
    if (purchaseOrderIndex > -1) {
      array[purchaseOrderIndex] = updatedObject;
    } else {
      array = [updatedObject, ...array];
      purchaseOrderIndex = 0;
    }
    if (
      key == "actionRequiredPurchaseOrders" &&
      array[purchaseOrderIndex].orderStatuses.some(
        (orderStatus) =>
          orderStatus.status == PURCHASE_ORDER_STATUS_MAPPING.COMPLETE
      )
    ) {
      array.splice(purchaseOrderIndex, 1);
    }
  } else {
    delete object.purchaseOrder;
    const purchaseOrder = array.find((order) => order.id == orderId);
    if (purchaseOrder) purchaseOrder.purchaseRequest = object;
  }
  return array;
};

export const listPageFiltersInitialValue = () => {
  return {
    status: "",
    requestedBy: "",
    category: "",
    companySupplier: "",
    requestedAt: { start: "", end: "" },
    actionRequired: false,
    legalEntities: [],
  };
};
