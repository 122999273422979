<template>
  <Form />
</template>

<script>
import { Form } from "@/components/RequestCreation";
import { newRequestMixin } from "@/components/mixins/index.js";
export default {
  name: "RequestCreation",
  components: {
    Form,
  },
  mixins: [newRequestMixin],

  mounted() {
    window.addEventListener("beforeunload", this.handleUnmount);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.handleUnmount);
  },
  methods: {
    handleUnmount(event) {
      event.preventDefault();
      return false;
    },
  },
};
</script>
