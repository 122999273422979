<template>
  <HivebuyAutoComplete
    v-model="value"
    :search="fetchItems"
    :allow-suggestions="true"
    :list="list"
    :label="label"
    option-label="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :loading="loading"
  >
    <template #group="slotProps">
      <div class="text-primary font-medium text-base flex items-center gap-x-3">
        <ShoppingCartIcon class="h-5 w-5" />
        {{ slotProps.option.label }}
      </div>
    </template>
    <template #singleOption="slotProps">
      <div class="flex items-center gap-x-4">
        <img
          :src="imageFromItem(slotProps.option)"
          alt=""
          class="h-12 w-12 rounded border-gray-400 border object-scale-down bg-gray-50 px-1"
        />
        <div class="flex flex-col gap-0.5">
          <span class="text-sm text-gray-800 font-medium">{{
            slotProps.option.name
          }}</span>
          <div class="flex items-center gap-x-2">
            <span class="text-sm text-gray-600">
              <i18n-n
                :value="parseFloat(netPrice(slotProps.option))"
                format="currency"
                :locale="currencyToLocale(currency(slotProps.option))"
              />
              ({{ $t("global.pricing.net") }})
            </span>
            <span class="text-sm text-gray-600"
              ><i18n-n
                :value="parseFloat(grossPrice(slotProps.option))"
                format="currency"
                :locale="currencyToLocale(currency(slotProps.option))"
              />
              ({{ $t("global.pricing.gross") }})
            </span>
          </div>
        </div>
      </div>
    </template>
  </HivebuyAutoComplete>
  <FieldError v-if="error" :text="error" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  PURCHASE_REQUEST_MODULE,
  GET_SEARCH_ITEMS,
} from "@/store/PurchaseRequest/types";
import { HivebuyAutoComplete } from "design-buy";
import { debounceMixin, newRequestMixin } from "@/components/mixins/index.js";
import { REQUEST_SEARCH_NON_ALLOWED_FIELDS } from "@/utils/constants.js";
import { ShoppingCartIcon } from "@heroicons/vue/outline";
import FieldError from "@/components/shared/FieldError.vue";
import { currencyToLocale } from "@/utils/utility_methods";
import { CATALOGUE_ITEM_SOURCE } from "@/utils/constants";
import { AUTH_MODULE } from "@/store/Auth/types";
export default {
  name: "AutoCompleteItem",
  components: {
    HivebuyAutoComplete,
    FieldError,
    ShoppingCartIcon,
  },
  mixins: [debounceMixin, newRequestMixin],
  inject: ["index"],
  props: {
    modelValue: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suggestions: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(AUTH_MODULE, ["modelId"]),
    list() {
      if (!this.suggestions) return [];
      const purItems = this.removeExtraKeys(this.suggestions.items);
      const catalogueItems = this.removeExtraKeys(
        this.suggestions.catalogueItems
      ).map((item) => {
        item.isCatalogueItem = true;
        return item;
      });
      return [...purItems, ...catalogueItems];
    },
    isLastValueCatalogueItem() {
      return !!this.modelValue.itemVariants?.length;
    },
    value: {
      get() {
        return this.modelValue.name;
      },
      set(value) {
        this.saveRequestAttributes({
          item: this.mapParams(value),
          index: this.index,
          action: this.isLastValueCatalogueItem ? "overwrite" : "update",
        });
      },
    },
  },

  methods: {
    ...mapActions(PURCHASE_REQUEST_MODULE, {
      searchItems: GET_SEARCH_ITEMS,
    }),
    currencyToLocale,
    copyCurrentRequestAttributes(value) {
      const currentStoreItem = this.newRequestItem(this.index);
      const currentItemCopyFields = [
        "department",
        "project",
        "requestedForId",
        "requestedForType",
      ];
      const valObj = Object.assign({}, value);
      currentItemCopyFields.forEach((field) => {
        valObj[field] = currentStoreItem[field];
      });
      return valObj;
    },
    copyCurrentValues(valObj) {
      const currentItem = this.newRequestItem(this.index);
      Object.keys(currentItem).forEach((key) => {
        valObj[key] = valObj[key] || currentItem[key];
      });
    },
    mapParams(value) {
      const { grossAmount, netAmount, isCatalogueItem, minimalQuantity } =
        value;

      let valObj = {};
      valObj.netGross = "net";
      valObj.tax = grossAmount - netAmount;
      valObj.quantity = 1;
      if (isCatalogueItem) {
        valObj.quantity = minimalQuantity;
        valObj.sourceType = this.modelId(CATALOGUE_ITEM_SOURCE.CATALOGUE_ITEM);
      }
      valObj = this.copyCurrentRequestAttributes(valObj);
      const requiredFields = [
        "category",
        "companySupplier",
        "description",
        "images",
        "minimalQuantity",
        "name",
        "quantityStep",
        "unlistedSupplier",
        "itemVariants",
        "isCatalogueItem",
        "netAmount",
        "netAmountCurrency",
        "grossAmount",
        "vat",
      ];

      requiredFields.forEach((field) => {
        valObj[field] = value[field];
      });
      if (!isCatalogueItem && !value[netAmount]) {
        this.copyCurrentValues(valObj);
      }
      return valObj;
    },
    removeExtraKeys(suggestions) {
      return suggestions.map((suggestion) => {
        const mappedSuggestion = { ...suggestion };
        REQUEST_SEARCH_NON_ALLOWED_FIELDS.forEach((field) => {
          delete mappedSuggestion[field];
        });
        return mappedSuggestion;
      });
    },
    fetchItems(search) {
      this.loading = true;
      this.searchItems(search)
        .then((response) => {
          this.suggestions = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // ENHANCE TO BOTH CATALOGUE AND ITEMS
    netPrice(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
      }
      return priceItem.netAmount || 0;
    },

    currency(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
        return item.itemVariants[0].grossAmountCurrency;
      }
      return priceItem.grossAmountCurrency || "EUR";
    },
    grossPrice(item) {
      let priceItem = item;
      if ("itemVariants" in item && item.itemVariants.length) {
        priceItem = item.itemVariants[0];
        return item.itemVariants[0].grossAmount;
      }
      return priceItem.grossAmount || "EUR";
    },
  },
};
</script>
