<template>
  <div
    v-if="isLoading"
    class="card-loading-section flex justify-center items-center h-full"
  >
    <div class="h-24 w-24">
      <HivebuyLoader />
    </div>
  </div>
  <main v-else class="proposals">
    <div v-if="!isLoading && showNotFoundError">
      <PageNotFound />
    </div>
    <div v-else>
      <div class="container max-w-none">
        <div class="proposal-header">
          <div class="flex items-center place-content-center content-center">
            <h1 class="page-title">
              {{ proposal.title }}
            </h1>
          </div>
          <button
            v-if="!isBoardExpired && !isUserOnlyAuditor"
            type="button"
            class="flex text-primary font-semibold"
            @click="() => (showAddSuggestionModal = true)"
          >
            <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
            {{ $t("proposals.suggestions.suggestionForm.addSuggestion") }}
          </button>
        </div>
      </div>

      <Members
        :proposal="proposal"
        :update-proposal-parent="updateCurrentProposal"
        :fetch-proposal="fetchProposal"
      />
      <div class="mt-4 mx-auto">
        <Suggestions
          :proposal="proposal"
          :fetch-proposal="fetchProposal"
          :update-votes="updateVotesOfSuggestion"
        />
      </div>

      <AddSuggestionModal
        v-if="!isUserOnlyAuditor"
        :show-modal="showAddSuggestionModal"
        :close-modal="onAddSuggestionModalClose"
        :proposal="proposal"
        :fetch-proposal="fetchProposal"
      />
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import { GET_PROPOSAL, PROPOSAL_MODULE } from "@/store/Proposal/types";
import Members from "@/components/Proposals/ProposalBoard/Members";
import Suggestions from "@/components/Proposals/ProposalBoard/Suggestions";
import AddSuggestionModal from "@/components/Proposals/ProposalBoard/AddSuggestionModal";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import { proposalMixin } from "@/components/mixins/index.js";
import { HivebuyLoader, PageNotFound } from "@/components/shared/index";

export default {
  components: {
    PageNotFound,
    AddSuggestionModal,
    Suggestions,
    Members,
    PlusCircleIcon,
    HivebuyLoader,
  },
  mixins: [proposalMixin],
  data() {
    return {
      isLoading: false,
      proposal: {},
      showAddSuggestionModal: false,
      showNotFoundError: false,
    };
  },
  created() {
    this.fetchProposal();
  },
  methods: {
    ...mapActions(PROPOSAL_MODULE, {
      getProposal: GET_PROPOSAL,
    }),
    fetchProposal(withoutLoading = false) {
      if (!withoutLoading) {
        this.isLoading = true;
      }
      const proposalId = this.$route.params.id;

      this.getProposal(proposalId)
        .then((response) => {
          this.proposal = response;
        })
        .catch(() => {
          this.showNotFoundError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    statusMapping(status) {
      return {
        OP: { label: "Open", className: "bg-hivebuy-yellow" },
        DR: { label: "Draft", className: "bg-gray-300" },
        CO: { label: "Completed", className: "bg-hivebuy-green" },
        CL: { label: "Closed", className: "bg-hivebuy-red" },
      }[status];
    },
    updateProposal(key, payload) {
      this.pointer[key] = payload;
    },
    onAddSuggestionModalClose() {
      this.showAddSuggestionModal = false;
    },
    updateVotesOfSuggestion(suggestion, payload) {
      const foundSuggestion = this.proposal.suggestions.findIndex((el) => {
        return el.id === suggestion;
      });
      this.proposal.suggestions[foundSuggestion].liked = payload.liked;
      this.proposal.suggestions[foundSuggestion].disliked = payload.disliked;
    },
    updateCurrentProposal(proposal) {
      if (proposal.status) {
        this.proposal.status = proposal.status;
      }
      if (proposal.members) {
        this.proposal.members = proposal.members;
      }
      if (proposal.description) {
        this.proposal.description = proposal.description;
      }
    },
  },
};
</script>

<style scoped>
.card-loading-section {
  height: calc(100vh - 180px);
}

.proposal-header {
  @apply flex justify-between;
}

.header-left {
  @apply flex items-center;
}

.status-label {
  @apply px-2 py-1 inline-flex text-sm font-semibold rounded-full text-white ml-4 -mt-2;
}
</style>
