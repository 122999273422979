<template>
  <div class="relative">
    <div class="text-right">
      <Button
        style="background: white; border: none; box-shadow: none"
        class="bg-white"
        :on-click-handler="toggleAdvancedFilters"
        :color="filterButtonColor"
        :disabled="disabled"
      >
        <AdjustmentsIcon class="text-primary hover:text-primarydark w-6 h-6" />

        <div v-if="appliedFiltersCount" class="counter-badge">
          {{ appliedFiltersCount }}
        </div>
      </Button>
    </div>
    <CollapseTransition class="absolute right-0">
      <div v-if="showFilters" class="filter-holder z-50 w-[800px]">
        <div class="flex-grow z-50">
          <div class="flex items-center z-50">
            <RadioGroup
              v-model="filters.status"
              class="mb-6 inline-grid grid-cols-4 gap-x-2"
            >
              <RadioGroupLabel
                class="text-sm font-medium text-gray-700 mb-2 col-span-4"
                >{{ $t("purchaseRequest.filtersStatus") }}</RadioGroupLabel
              >
              <RadioGroupOption
                v-for="(label, value) in requestStatuses"
                v-slot="{ checked }"
                :key="label"
                as="div"
                class="text-gray-600"
                :value="value"
              >
                <div
                  :class="[
                    'items-center text-center border border-gray-300 rounded-md px-4 py-2 text-sm cursor-pointer min-w-full transition duration-50 ease-in-out',
                    { [selectedStatusClass]: checked },
                  ]"
                >
                  {{ $t(`statuses.request.${value}`) }}
                </div>
              </RadioGroupOption>
            </RadioGroup>
            <span v-if="filters.status" class="mx-4">
              <XCircleIcon
                class="w-6 h-6 text-hivebuy-red cursor-pointer"
                @click="clearStatusFilter"
              />
            </span>
          </div>

          <div class="grid lg:grid-cols-6 gap-4">
            <div class="col-span-12 lg:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("purchaseRequest.list.table.requester") }}
              </label>
              <div class="mt-1">
                <MemberSelector
                  :value="filters.requestedBy"
                  :on-value-change="
                    (value) => onFilterChange('requestedBy', value)
                  "
                  :members-list="usersList()"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("purchaseRequest.list.table.category") }}
              </label>
              <div class="mt-1">
                <Multiselect
                  v-model="filters.category"
                  :mode="'single'"
                  :searchable="true"
                  :options="categoriesList"
                  :placeholder="$t('purchaseRequest.list.table.category')"
                  :value-prop="'id'"
                  :label="'name'"
                  :track-by="'name'"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-2">
              <label class="block text-sm font-medium text-gray-700">
                {{ $t("purchaseRequest.list.table.supplier") }}
              </label>
              <div class="mt-1">
                <SupplierSelect
                  v-model="filters.companySupplier"
                  :label="'name'"
                  :placeholder="$t('purchaseRequest.list.table.supplier')"
                />
              </div>
            </div>

            <div class="col-span-12 lg:col-span-4">
              <DateRangeInput
                v-model="filters.requestedAt"
                :width-class="'w-full'"
              />
            </div>

            <LegalTendencySelect
              v-if="isFeatureAllowed('legalEntity')"
              v-model="filters.legalEntities"
              class="col-span-12 lg:col-span-2"
              mode="tags"
            />
          </div>

          <div class="col-span-12 lg:col-span-2 mt-6">
            <SwitchCheckbox
              v-model="filters.actionRequired"
              :label="$t('purchaseRequest.list.table.actionRequired')"
            />
          </div>
          <div class="col-span-12 lg:col-span-2 mt-6">
            <SwitchCheckbox
              v-model="filters.buyer"
              :label="$t('purchaseRequest.list.table.iAmBuyer')"
            />
          </div>

          <div class="flex justify-end ml-2">
            <Button :on-click-handler="onApplyFilters" class="mr-2">
              {{ $t("button.apply") }}
            </Button>
            <Button :on-click-handler="onResetAllFilters" :color="'gray'">
              {{ $t("button.resetAll") }}
            </Button>
          </div>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import {
  getKeyByValue,
  isValueUndefined,
  removeKeysInObjWithNoValue,
  stringToBoolean,
} from "@/utils/utility_methods";
import { XCircleIcon } from "@heroicons/vue/solid";
import { PURCHASE_REQUEST_STATUS_MAPPING } from "@/utils/constants";
import { AdjustmentsIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import {
  LegalTendencySelect,
  SupplierSelect,
  DateRangeInput,
  Button,
  MemberSelector,
  SwitchCheckbox,
} from "@/components/shared";
import Multiselect from "@vueform/multiselect";
import { listPageFiltersInitialValue } from "@/utils/helpers/purchaseRequestHelper";

export default {
  components: {
    Button,
    Multiselect,
    MemberSelector,
    SwitchCheckbox,
    DateRangeInput,
    RadioGroup,
    RadioGroupOption,
    RadioGroupLabel,
    XCircleIcon,
    CollapseTransition,
    AdjustmentsIcon,
    SupplierSelect,
    LegalTendencySelect,
  },
  props: {
    updateFilters: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    advancedFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showFilters: false,
      filters: { ...listPageFiltersInitialValue(), ...this.advancedFilters },
      appliedFiltersCount: this.getAppliedFiltersCount(this.advancedFilters),
    };
  },
  computed: {
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, ["categoriesList", "usersList"]),
    filterButtonColor() {
      return this.showFilters ? "danger" : "primary";
    },
    selectedStatusClass() {
      let statusClass = "bg-hivebuy-yellow border-hivebuy-yellow";
      const { status } = this.filters;

      if (status == "A") {
        statusClass = "bg-hivebuy-green border-hivebuy-green";
      } else if (status == "R") {
        statusClass = "bg-hivebuy-red border-hivebuy-red";
      } else if (status == "C") {
        statusClass = "bg-hivebuy-grey border-hivebuy-grey";
      }

      return `text-white border ${statusClass}`;
    },
    requestStatuses() {
      return Object.fromEntries(
        Object.entries(PURCHASE_REQUEST_STATUS_MAPPING).filter(
          ([key]) => !["D", "CA", "RE", "DR"].includes(key)
        )
      );
    },
  },
  created() {
    this.PURCHASE_REQUEST_STATUS_MAPPING = PURCHASE_REQUEST_STATUS_MAPPING;
  },
  mounted() {
    const { status, actionRequired, requestedBy } = this.$route.query;
    if (status) {
      this.filters.status = getKeyByValue(
        this.PURCHASE_REQUEST_STATUS_MAPPING,
        status
      );
    } else if (!isValueUndefined(actionRequired)) {
      this.filters.actionRequired = stringToBoolean(actionRequired);
    }
    if (requestedBy) {
      this.filters.requestedBy = requestedBy;
    }

    (status || !isValueUndefined(actionRequired)) && this.onApplyFilters();
  },
  methods: {
    getAppliedFiltersCount(filters) {
      const validFilters = removeKeysInObjWithNoValue(filters);

      if (!filters.requestedAt?.start) delete validFilters.requestedAt;
      if (!filters.legalEntities?.length) delete validFilters.legalEntities;

      return Object.keys(validFilters).length;
    },
    toggleAdvancedFilters() {
      this.showFilters = !this.showFilters;
    },
    onFilterChange(key, value) {
      this.filters[key] = value || "";
    },
    onApplyFilters() {
      this.setAdvancedFilters();
      this.toggleAdvancedFilters();
    },
    onResetAllFilters() {
      this.filters = listPageFiltersInitialValue();
      this.setAdvancedFilters();
      this.toggleAdvancedFilters();
    },
    clearStatusFilter() {
      this.filters.status = "";
    },
    setAdvancedFilters() {
      const mappedFilters = removeKeysInObjWithNoValue(this.filters);

      this.appliedFiltersCount = this.getAppliedFiltersCount(mappedFilters);

      this.updateFilters("advancedFilters", mappedFilters);
    },
  },
};
</script>

<style scoped>
.filter-holder {
  @apply flex items-end justify-end bg-white p-6 rounded border border-gray-200 shadow-2xl;
}
</style>
