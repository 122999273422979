<template>
  <Filters
    :on-search-input="onSearchInput"
    :update-filters="updateFilters"
    :filters="adminCatalogueItemsListFilters"
    :show-actions="true"
    :update-custom-field-filters="updateCustomFieldFilters"
  />
  <div class="admin">
    <div class="flex flex-col">
      <div class="flex justify-end relative items-center mb-3">
        <div class="relative flex items-center">
          <button
            type="button"
            class="btn flex text-primary font-semibold"
            @click="() => onCatalogueModelOpen()"
          >
            <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
            {{ $t("catalogue.admin.addCatalogueItem") }}
          </button>
          <div>
            <CSVUpload type="catalogueitem" @success="onCSVUploaded" />
          </div>
        </div>
      </div>
      <template v-if="!adminCatalogueItemsLoaded">
        <div class="flex items-center">
          <HivebuyLoader class="w-20 h-20" />
        </div>
      </template>
      <template v-if="adminCatalogueItemsLoaded">
        <div v-if="areAdminCatalogueItemsPresent" class="-mx-5">
          <table
            class="table custom-table table-fixed border-t border-gray-200"
          >
            <thead class="table-header">
              <tr>
                <th
                  v-for="(columnName, index) in tableColumns()"
                  :key="index"
                  scope="col"
                >
                  <div class="flex items-center">{{ columnName }}</div>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="catalogueItem in adminCatalogueItems"
                :key="catalogueItem.id"
                class="group"
              >
                <td class="w-8">
                  <div class="image-holder">
                    <img
                      :src="itemImage(catalogueItem.itemVariants[0])"
                      alt="Catalogue Image"
                    />
                  </div>
                </td>
                <td :title="catalogueItem.name">
                  <div
                    class="title text-left group-hover:text-primary truncate flex"
                  >
                    <TruncateText :text="catalogueItem.name" />
                    <ViewGridIcon
                      v-if="catalogueItem.itemVariants.length > 1"
                      class="h-5 w-5 ml-2 text-primary flex-shrink-0"
                    />
                  </div>
                </td>
                <td>
                  <TimeDisplay
                    v-if="catalogueItem.updatedAt"
                    :time="catalogueItem.updatedAt"
                    :show-time-ago="false"
                  />
                </td>
                <td>
                  <div class="text-left truncate">
                    <Category :category="catalogueItem.category" />
                    <div
                      v-if="catalogueItem.companySupplier"
                      class="text-gray-500 text-xs 2xl:text-sm flex items-center"
                    >
                      <span class="mr-1"
                        >{{ $t("global.prepositions.from") }}:</span
                      >

                      <SupplierName :supplier="catalogueItem.companySupplier" />
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-primary">
                    <i18n-n
                      :value="
                        parseFloat(catalogueItem.itemVariants[0].netAmount)
                      "
                      format="currency"
                      :locale="
                        currencyToLocale(
                          catalogueItem.itemVariants[0].netAmountCurrency
                        )
                      "
                    />
                  </p>
                </td>
                <td>
                  <p class="text-primary">
                    <i18n-n
                      :value="
                        parseFloat(catalogueItem.itemVariants[0].grossAmount)
                      "
                      format="currency"
                      :locale="
                        currencyToLocale(
                          catalogueItem.itemVariants[0].grossAmountCurrency
                        )
                      "
                    />
                  </p>
                </td>

                <td class="w-8">
                  <span class="flex col-span-1 text-gray-200 space-x-1">
                    <ListButton
                      :on-click-handler="
                        () => onCatalogueModelOpen(catalogueItem)
                      "
                      hover-edit
                    >
                      <template #icon>
                        <PencilAltIcon class="h-5 w-5" />
                      </template>
                    </ListButton>
                    <ListButton
                      :on-click-handler="
                        () => confirmDeleteCatalogueItem(catalogueItem)
                      "
                      hover-danger
                    >
                      <template #icon>
                        <TrashIcon class="h-5 w-5" />
                      </template>
                    </ListButton>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="text-center flex flex-col items-center">
          <EmptyState
            :heading-text="$t('catalogue.catalogueItems.emptyState.heading')"
            :description-text="
              $t('catalogue.catalogueItems.emptyState.description')
            "
          />
          <button
            type="button"
            class="btn flex text-primary font-semibold -translate-y-10"
            @click="() => onCatalogueModelOpen()"
          >
            <PlusCircleIcon class="mr-2 h-5 w-5 mt-0.5" aria-hidden="true" />
            {{ $t("catalogue.admin.addCatalogueItem") }}
          </button>
        </div>
        <div
          v-if="paginationParams.page < paginationParams.totalPages"
          id="load-more-section"
        >
          <LoadMoreSection
            :load-more="loadMore"
            :load-more-btn-loading="loadMoreBtnLoading"
          />
        </div>
      </template>
    </div>
  </div>
  <CatalogueItemForm
    ref="createCatalogueItemForm"
    :show-modal="showCatalogueItemModal"
    :close-modal="onCatalogueItemModalClose"
    :catalogue-item-to-edit="catalogueItemToEdit"
  />
  <ConfirmDialog
    v-if="catalogueItemToDelete"
    :text="$t('catalogue.admin.deleteConfirmation')"
    :show-modal="showDeleteConfirmModal"
    :close-modal="onDeleteModalClose"
    title="Delete Catalogue Item"
    :item-to-confirm="catalogueItemToDelete.name"
    :confirm-modal="onDeleteCatalogueItem"
  />
</template>

<script>
import {
  PencilAltIcon,
  TrashIcon,
  PlusCircleIcon,
  ViewGridIcon,
} from "@heroicons/vue/solid";
import {
  catalogueMixin,
  loadMoreFiltersMixin,
  catalogueItemsListMixin,
} from "@/components/mixins/index.js";
import { mapActions, mapState, mapMutations } from "vuex";
import {
  DELETE_CATALOGUE_ITEM,
  CATALOGUE_MODULE,
  SET_ADMIN_CATALOGUE_ITEMS_LIST_FILTERS,
  GET_ADMIN_CATALOGUE_ITEMS,
} from "@/store/Catalogue/types";
import {
  ConfirmDialog,
  EmptyState,
  ListButton,
  LoadMoreSection,
  CSVUpload,
  Category,
  HivebuyLoader,
} from "@/components/shared";
import SupplierName from "@/components/shared/SupplierName";
import { currencyToLocale } from "@/utils/utility_methods.js";
import CatalogueItemForm from "@/components/Catalogue/Admin/CatalogueItemForm";
import { TimeDisplay } from "@/components/shared/index";
import Filters from "@/components/Catalogue/Filters";

export default {
  components: {
    SupplierName,
    LoadMoreSection,
    PencilAltIcon,
    TrashIcon,
    EmptyState,
    ConfirmDialog,
    PlusCircleIcon,
    CatalogueItemForm,
    CSVUpload,
    ListButton,
    Category,
    HivebuyLoader,
    ViewGridIcon,
    TimeDisplay,
    Filters,
  },
  mixins: [catalogueMixin, loadMoreFiltersMixin, catalogueItemsListMixin],
  data() {
    return {
      showDeleteConfirmModal: false,
      catalogueItemToDelete: {},
      catalogueItemToEdit: {},
      showCatalogueItemModal: false,
    };
  },
  computed: {
    ...mapState(CATALOGUE_MODULE, {
      adminCatalogueItems: (state) => state.adminCatalogueItems,
      adminCatalogueItemsListFilters: (state) =>
        state.adminCatalogueItemsListFilters,
      paginationParams: (state) =>
        state.adminCatalogueItemsListFilters.paginationParams,
      advancedFilters: (state) =>
        state.adminCatalogueItemsListFilters.advancedFilters,
    }),
    adminCatalogueItemsLoaded() {
      return (
        !this.isApiLoading("adminCatalogueItemsLoading") &&
        this.adminCatalogueItems
      );
    },
  },
  created() {
    !this.adminCatalogueItems?.length && this.fetchCatalogueItems();
  },
  methods: {
    currencyToLocale,
    ...mapActions(CATALOGUE_MODULE, {
      getCatalogueItems: GET_ADMIN_CATALOGUE_ITEMS,
      deleteCatalogueItem: DELETE_CATALOGUE_ITEM,
    }),
    ...mapMutations(CATALOGUE_MODULE, {
      setAdminCatalogueItemsListFilters: SET_ADMIN_CATALOGUE_ITEMS_LIST_FILTERS,
    }),
    tableColumns() {
      return [
        this.$t("global.image"),
        this.$t("global.name"),
        this.$t("global.dateUpdated"),
        this.$t("global.category"),
        this.$t("global.pricing.net"),
        this.$t("global.pricing.gross"),
      ];
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
    },
    confirmDeleteCatalogueItem(catalogueItem) {
      this.catalogueItemToDelete = catalogueItem;
      this.showDeleteConfirmModal = true;
    },
    onDeleteCatalogueItem() {
      this.showDeleteConfirmModal = false;
      this.deleteCatalogueItem(this.catalogueItemToDelete.id)
        .then(() => {
          this.showNotification(
            this.$t("catalogue.admin.catalogueItemDeleted")
          );
        })
        .catch((error) => this.showErrorMessage(error));
    },
    onCatalogueModelOpen(catalogueItem = {}) {
      this.catalogueItemToEdit = catalogueItem;
      this.showCatalogueItemModal = true;
    },
    onCatalogueItemModalClose() {
      this.catalogueItemToEdit = {};
      this.showCatalogueItemModal = false;
    },
    onCSVUploaded() {
      this.fetchCatalogueItems();
    },
    fetchCatalogueItems(loadMore = false) {
      this.getCatalogueListItems(loadMore, "adminCatalogueItemsLoading");
    },
    updateFilters(key, value, parentKey = null) {
      this.updateListFilters(
        this.adminCatalogueItemsListFilters,
        this.setAdminCatalogueItemsListFilters,
        key,
        value,
        parentKey
      );
    },
  },
};
</script>

<style scoped>
.image-holder {
  @apply flex items-center justify-center h-12 w-12 bg-white border rounded p-1;
}

.image-holder img {
  @apply h-full w-full object-cover;
}

.custom-table tr th:nth-child(1) {
  width: 80px;
}

.custom-table tr th:nth-child(3),
.custom-table tr th:nth-child(5),
.custom-table tr th:nth-child(6) {
  width: 110px;
}

.custom-table tr th:nth-child(7) {
  width: 100px;
}
</style>
