<template>
  <div class="custom-card overflow-auto">
    <div class="-m-5">
      <table class="table">
        <thead class="table-header">
          <tr>
            <th
              v-for="(columnName, index) in tableColumns()"
              :key="index"
              scope="col"
            >
              <div class="flex items-center">
                <span class="pr-2">
                  {{ columnName }}
                </span>
              </div>
            </th>
            <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr>
            <td colspan="6" align="center" class="search-holder">
              <div class="search-filter pr-4 sticky-search">
                <Input
                  id="search"
                  :value="companySuppliersListFilters.search"
                  name="search"
                  :placeholder="$t('general.search')"
                  show-icon
                  type="search"
                  :on-key-up="
                    (event) =>
                      executeDebounceSearch(
                        onSearchUpdate,
                        { key: 'search', value: event.target.value },
                        searchDebounceTimeout
                      )
                  "
                  :classes="'border-0 focus:border-0 focus:ring-0'"
                  @keyup.enter="
                    (event) =>
                      executeDebounceSearch(
                        onSearchUpdate,
                        { key: 'search', value: event.target.value },
                        0
                      )
                  "
                />
              </div>
            </td>
          </tr>

          <TableRowsLoader
            v-if="!areAllSupplierLoaded"
            :columns="5"
            :rows="4"
          />

          <tr
            v-else-if="
              !isApiLoading('companySuppliers') && !filteredList.length
            "
          >
            <td colspan="5" align="center">
              <p class="bg-white px-6 py-4 w-full font-medium text-gray-700">
                No Records Found.
              </p>
            </td>
          </tr>

          <tr
            v-for="companySupplier in filteredList"
            v-else
            :key="companySupplier.id"
            class="group"
          >
            <td>
              <div class="flex items-center">
                <div class="flex-shrink-0 h-6 w-6 text-primary">
                  <LightningBoltIcon v-if="companySupplier?.supplier" />
                  <GlobeAltIcon v-else />
                </div>
                <div class="ml-4">
                  <div
                    class="title group-hover:text-primary"
                    :class="{
                      'line-through':
                        !companySupplier?.active && companySupplier.supplier,
                    }"
                  >
                    <div class="flex">
                      {{ companySupplier.name }}
                      <PreferredIcon
                        v-if="companySupplier.isPreferred"
                        class="h-6 w-6 text-gray-500 ml-2"
                      />
                    </div>
                    <p class="text-xs text-gray-500 font-normal">
                      {{
                        companySupplier.displayName ||
                        companySupplier?.supplier?.name ||
                        "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <p class="text-left">
                {{ companySupplier.supplierNumber }}
              </p>
            </td>
            <td>
              <div
                v-if="
                  supplierCategories(companySupplier).length &&
                  !companySupplier.supplier
                "
                class="text-left truncate text-sm"
              >
                <Category
                  :categories="supplierCategories(companySupplier)"
                  :squashed="true"
                />
              </div>
            </td>
            <td>
              <div
                v-if="
                  !companySupplier.supplier && companySupplier.responsibleUser
                "
                class="text-left"
              >
                <UserWithAvatar
                  :user="companySupplier.responsibleUser"
                  show-name
                  :avatar-dimension="8"
                />
              </div>
            </td>
            <td class="w-8">
              <div
                v-if="!isUserOnlyAuditor"
                class="flex gap-x-2 items-center text-gray-100 group-hover:text-gray-300"
              >
                <ListButton
                  :on-click-handler="() => onEdit(companySupplier)"
                  hover-edit
                >
                  <template #icon>
                    <PencilAltIcon class="h-5 w-5" />
                  </template>
                </ListButton>
                <ListButton
                  v-if="!companySupplier?.supplier"
                  :on-click-handler="
                    () => confirmDeleteCompanySupplier(companySupplier)
                  "
                  hover-danger
                >
                  <template #icon>
                    <TrashIcon class="h-5 w-5" />
                  </template>
                </ListButton>
                <ListButton
                  :on-click-handler="() => showHistory(companySupplier)"
                  class="hover:bg-hivebuy-lilac text-gray-300 hover:text-hivebuy_darkplum"
                >
                  <template #icon>
                    <HistorySVG class="h-5 w-5" />
                  </template>
                </ListButton>
              </div>
            </td>
          </tr>
          <tr v-if="areAllSupplierLoaded">
            <td colspan="5">
              <div class="flex items-center justify-center gap-x-4 text-sm">
                <span>
                  {{ $t("global.showing") }}
                  {{ companySuppliersList()?.length }}
                </span>
                <span>
                  {{ $t("global.total") }}
                  {{ companySuppliersListFilters.totalDbRecords }}
                </span>
                <span
                  v-if="shouldExecutePaginationApi"
                  class="text-sm font-semibold text-primary cursor-pointer"
                  @click="fetchAllCompanySuppliers"
                >
                  ({{ $t("global.showAll") }})
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ConfirmDialog
      text="Are you sure you want to delete the following company supplier?"
      :show-modal="showDeleteConfirmModal"
      :close-modal="onDeleteModalClose"
      title="Delete Company Supplier"
      :item-to-confirm="companySupplierToDelete.name"
      :confirm-modal="onDeleteCompanySupplier"
    />
    <HistoryModal
      :object="historyObject"
      type="companysupplier"
      :show-modal="showHistoryModal"
      :on-close-modal="closeHistory"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  UserWithAvatar,
  ConfirmDialog,
  TableRowsLoader,
  ListButton,
  Category,
} from "@/components/shared/index";
import {
  COMPANY_MANAGEMENT_MODULE,
  DELETE_COMPANY_SUPPLIER,
  GET_COMPANY_INTEGRATED_SUPPLIERS,
} from "@/store/CompanyManagement/types";

import { GlobeAltIcon, LightningBoltIcon } from "@heroicons/vue/outline/index";
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/solid";
import { companySuppliers } from "@/components/mixins/index.js";
import { Input } from "@/components/shared/index";
import suppliersMixin from "@/components/mixins/suppliersMixin";
import PreferredIcon from "@/assets/images/preferred.vue";
import HistorySVG from "@/assets/images/HistorySVG";
import HistoryModal from "@/components/shared/HistoryModal.vue";

export default {
  components: {
    TableRowsLoader,
    GlobeAltIcon,
    PencilAltIcon,
    TrashIcon,
    ConfirmDialog,
    UserWithAvatar,
    ListButton,
    Input,
    Category,
    LightningBoltIcon,
    PreferredIcon,
    HistorySVG,
    HistoryModal,
  },
  mixins: [companySuppliers, suppliersMixin],
  props: {
    onEdit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showDeleteConfirmModal: false,
      companySupplierToDelete: {},
      showHistoryModal: false,
      historyObject: {},
    };
  },
  computed: {
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["companySuppliersListFilters"]),
    ...mapGetters(COMPANY_MANAGEMENT_MODULE, [
      "usersList",
      "companySuppliersList",
    ]),
    areAllSupplierLoaded() {
      return (
        !this.isApiLoading("companySuppliers") &&
        !this.isApiLoading("companyIntegratedSuppliers") &&
        this.companySuppliersList
      );
    },
    filteredList() {
      const search = this.companySuppliersListFilters.search;

      return this.companySuppliersList().filter((companySupplier) => {
        const name = companySupplier.name;
        if (name?.toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
        return !!companySupplier.supplierNumber
          ?.toLowerCase()
          .includes(search.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions(COMPANY_MANAGEMENT_MODULE, {
      deleteCompanySupplier: DELETE_COMPANY_SUPPLIER,
      fetchConnectedSuppliers: GET_COMPANY_INTEGRATED_SUPPLIERS,
    }),
    supplierCategories(companySupplier) {
      return companySupplier?.categories?.map((obj) => obj.id || obj) || [];
    },
    fetchAllCompanySuppliers() {
      const params = Object.assign({}, this.companySuppliersListFilters, {
        pageSize: 5000,
      });
      this.fetchCompanySuppliers(params);
    },
    tableColumns() {
      return [
        this.$t(
          "companyManagement.companySupplier.tableHeadingCompanySupplier"
        ),
        this.$t("companyManagement.companySupplier.tableHeadingSupplierNumber"),
        this.$t("companyManagement.companySupplier.tableHeadingCategories"),
        this.$t("companyManagement.companySupplier.addModal.responsibleUser"),
      ];
    },
    confirmDeleteCompanySupplier(companySupplier) {
      this.companySupplierToDelete = companySupplier;
      this.showDeleteConfirmModal = true;
    },
    onDeleteModalClose() {
      this.showDeleteConfirmModal = false;
      this.companySupplierToDelete = {};
    },
    onDeleteCompanySupplier() {
      this.showDeleteConfirmModal = false;
      this.deleteCompanySupplier(this.companySupplierToDelete)
        .then(() => {
          this.companySupplierToDelete = {};
          this.showNotification("CompanySupplier has been deleted");
        })
        .catch((error) => this.showErrorMessage(error));
    },
    showHistory(supplier) {
      this.historyObject = {
        id: supplier.id,
        name: supplier.name,
        createdAt: supplier.createdAt,
      };
      this.showHistoryModal = true;
    },

    closeHistory() {
      this.showHistoryModal = false;
      setTimeout(() => {
        this.historyObject = {};
      }, 300);
    },
  },
};
</script>
